import { Carousel } from "antd";
import React from "react";

class CarouselComponent extends React.Component {
  render() {
    return (
      <Carousel autoplay={true} effect="fade">
        <div>
          <a href="productview/silver">
            <img
              style={{ height: "500px", width: "100%" }}
              className="about-home"
              src={require("../../assets/images/banner/GOLD INVESTMENTS.png")}
              alt="Gold Investment"
            />
          </a>
        </div>
        <div>
          <a href="/productview/silver">
            <img
              style={{ height: "500px", width: "100%" }}
              className="about-home"
              src={require("../../assets/images/banner/ONLY THE BEST.png")}
              alt="Only the best"
            />
          </a>
        </div>
        <div>
          <a href="/productview/gold">
            <img
              style={{ height: "500px", width: "100%" }}
              className="about-home"
              src={require("../../assets/images/banner/Small.png")}
              alt="Small"
            />
          </a>
        </div>
        <div>
          <a href="/productview/gold">
            <img
              className="about-home"
              style={{ height: "500px", width: "100%" }}
              src={require("../../assets/images/banner/slide-1.png")}
              alt="slide-1"
            />
          </a>
        </div>
      </Carousel>
    );
  }
}

export default CarouselComponent;

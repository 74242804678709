import React from 'react'
import { Typography, Row, Col, Skeleton, Empty, Collapse, Button } from 'antd'
import { connect } from 'react-redux'
import axios from 'axios'
import './Orders.css'
import './style/account.css'
import moment from 'moment'
const { Title } = Typography
const { Panel } = Collapse
class Orders extends React.Component {
  // constructor() {
  //     super();
  //     this.state = {

  //     }
  // }
  state = {
    orders: [],
  }
  componentDidMount() {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/order/${this.props.currentUser.email}`
      )
      .then((res) => {
        let x = res.data.data
        let orders = x.reverse()
        this.setState({ orders: orders })
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  render() {
    return (
      <React.Fragment>
        <Row style={{ margin: '8%' }}>
          <Col span={2}></Col>
          <Col style={{ textAlign: 'center' }} span={4}>
            <a href="/account">
              <Title className="profile-cards" level={5}>
                Account Dashboard
              </Title>
            </a>
            <br />
            <a href="/orders">
              <Title className="profile-cards" level={5}>
                My Orders
              </Title>
            </a>
            <br />
            <a href="/cart">
              <Title className="profile-cards" level={5}>
                My Cart
              </Title>
            </a>
            <br />
            <a href="/wishlist">
              <Title className="profile-cards" level={5}>
                My Wishlist
              </Title>
            </a>
          </Col>
          <Col span={2}></Col>
          <Col span={16}>
            {this.state.orders.length > 0 ? (
              this.state.orders.map((order) => (
                <>
                  <Row>
                    <Col
                      style={{
                        backgroundColor: '#e7e7e7',
                        padding: '10px 25px',
                        border: '1px solid #c4c4c4',
                      }}
                      span={24}
                    >
                      <Row>
                        <Col span={8}>
                          <h3 style={{ color: '#000' }}>
                            <b>Order Placed</b>
                            <br /> {moment.utc(order.createdAt).format('LLL')}
                          </h3>
                        </Col>
                        <Col span={8}>
                          <h3 style={{ color: '#000' }}>
                            <b>Total</b>
                            <br />€ {order.totalprice}
                          </h3>
                        </Col>
                        <Col span={8}>
                          <h3 style={{ color: '#000' }}>
                            <b>Order Id</b>
                            <br /> {order._id}
                          </h3>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <div className="order-main-card">
                        <Row>
                          <Col span={12}>
                            {order.products.length > 0 ? (
                              <img
                                style={{ width: '100px' }}
                                src={order.products[0].product.imageURL1}
                              />
                            ) : null}
                          </Col>
                          <Col span={12}>
                            <ul>
                              <Row>
                                <Col span={12}>
                                  <h1>Cart</h1>
                                </Col>
                                <Col span={12}>
                                  {!order.orderStatus == 'pending' ? (
                                    <Button
                                      style={{ fontWeight: '800' }}
                                      type="danger"
                                    >
                                      Order Pending
                                    </Button>
                                  ) : (
                                    <a
                                      style={{ marginTop: '5px' }}
                                      href={`invoice/${order.orderId}`}
                                    >
                                      Print Invoice
                                    </a>
                                  )}
                                </Col>
                              </Row>
                              {order.products.map((product) => (
                                <>
                                  <li>
                                    <b>{product.product.title}</b>
                                  </li>
                                  <h4>Quantity: {product.quantity} </h4>
                                </>
                              ))}
                            </ul>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </>
              ))
            ) : (
              <div style={{ textAlign: 'center', paddingTop: '10%' }}>
                <Empty
                  description={
                    'You have not placed any order yet. Your order details will appear here once you do.'
                  }
                />
              </div>
            )}
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
  isLoggedIn: user.isLoggedIn,
})

export default connect(mapStateToProps)(Orders)

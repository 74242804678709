import React, { Component } from 'react'
import { Col, Row } from "antd"
import './style/Footer.css'
import 'antd/dist/antd.css';
import Footer from "./Footer"

class TermsConditions extends Component {
    render() {
        return (
            <>
                <Row>
                    <Col span={2} className="counter-section">
                        <img style={{ height: 400 }} src={require('../Component/Home/directgold_poster.jpg')} alt="offer-poster" />
                    </Col>
                    <Col span={20}>
                        <div className="about-section">
                            <h2>Below are Directgold’s Terms and Conditions. This page explains all of our operating procedures in detail. For additional assistance please email Scarpino5@mac.com and one of our customer services team will get back to you shortly.</h2>
                            <ul>
                                <li>
                                    You can make an offer to SELL your metal to us (being a precious metal-based product you own, even if it was purchased by us in the past).

                                </li>
                                <li>
                                    Before order or offer is accepted you must meet certain preconditions, including making payment and providing ID if requested. Once ownership of the item(s) passes to you they are referred to as your goods by these applicable terms and conditions, i.e. It is your item that is being  processed for shipping and delivery.
                                </li>
                                <li>
                                    You are committed to your order or offer at the time it is placed, which is when a contract is formed and you are then liable for the cost of any goods or services you have requested.
                                </li>
                                <li>
                                    The goods we supply are dependent on fluctuations in financial markets. The timer on the website would ensure that your order will be confirmed as per the initial amount being set. After the time ends, the prices can change according to the market conditions.
                                </li>
                                <li>
                                    You are advised not to accept or sign for any delivery where the package has been damaged, resealed or tampered with and instruct the courier to return the package to us. We cannot accept any liability for loss once a package has been accepted by any person at the delivery address.
                                </li>
                                <li>
                                    If you choose to return the purchased items, the return policy applies for fourteen calendar days after the order is being delivered. ‘Scarpino’ will settle the refund amount within thirty working days. The costs of returning the goods will be at the customer’s expense. The initial step will be reporting to scarpino5@mac.com. The goods will be returned and refunded at the current value of the item.
                                </li>
                                <li>
                                    After the acceptance of the transaction by Direct Gold, the customer can only cancel after paying compensation of 10% of the transaction, possibly increased by the price disadvantage of the product that ‘Direct Gold’ experiences as a result of fluctuations in the prices of metals in the financial markets.
                                </li>
                                <li>
                                    We are unable to accept any liability for packages that have been delivered to houses of multiple occupancies, work addresses, PO boxes, mailboxes or where a postal redirection is in place once signed for or confirmed by our couriers as delivered to that address.
                                </li>
                            </ul>
                            <h2>PARTIES INVOLVED</h2>
                            <p>
                                The customer under the contract purchasing metal is you. The supplier and delivery are made by ‘Scarpino Bv’. We are directgold.eu affiliate of ‘Scarpino bv’, a Belgian company situated in the Antwerp region with number BE0458.439.618.<br />
                                Each order that you make, constitutes an offer to buy from us the goods and/or services stated in the order, subject to these terms and conditions. Each offer constitutes an offer by you to sell your metal to us, subject to these terms and conditions. Information published on our website, and correspondence from us by phone, email or telephone should be followed by the buyer.

                            </p>
                            <h2>CONFIRMATION OF ORDER</h2>
                            <p>
                                He contract is formed between you and us at the moment you place your order or make your offer via our website, our helpline subject to these terms and conditions. The preconditions for the same are as follows:
                            </p>
                            <ol>
                                <li>We request your ID which will be verifiable by us.
</li>
                                <li>
                                    We confirm your order via mail within the limited time and received the payment.
</li>
                                <li>
                                    In case your are selling metal, we will receive full details with receipt of your payment account and the metal.
</li>
                                <li>
                                    No default or problem should take place.
</li>
                            </ol>
                            <h2>
                                PRICES

                            </h2>
                            <p>
                                The prices are offered in euros £, including VAT if applicable. The price for the metal which we propose to you at the time when you confirm and pay for your order. Costs or charges which you incur due to a default or problem are also payable as part of the price of your order or offer. The cost of packaging, transport and shipping will be mentioned in the invoice.<br />
                                The prices of gold and silver metals fluctuate regularly. We complete an order and you subsequently request services concerning item(s) under the previous order, your request will constitute a new order for services which will be subject to our then-current prices. The prices that applied when we accepted the previous order will never apply to the new order. The prices on the website (https://www.directgold.store/) are purely indicative and do not bind Direct Gold in any way.
                            </p>
                            <h2>
                                OUR SERVICES
                            </h2>
                            <p>
                                The services we provide under the purchase done by you are the transaction; described in our confirmation of the order. The items are displayed on our website as being available for order. We deliver in Belgium, Netherlands and Luxembourg. If outside the BenNeLux, the customer should take the responsibility of the handover of the goods at a specific location and time frame (to be agreed mutually) in Belgium.

                            </p>
                            <h2>
                                INVESTMENT ADVICE

                            </h2>
                            <p>
                                We are not authorised by the Financial Conduct Authority and cannot give advice comparing precious metals with regulated investments. We also cannot provide advice on any potential tax implications. You must rely on your own financial, tax and/ or accounting advisers.<br />
                                Statements we make about the tax status of the goods we supply are based on our understanding of German tax. We cannot be held liable if tax rules change in the future. It is a condition that if you need tax advice you seek advice from a tax and/ or accounting advisers.<br />

                            </p>
                            <ol>
                                <li>Our policies are subject to change. The latest version is always available on our website. The contract between you and us applies to the policies as they stand at the date and time when you use the facilities.
</li>
                                <li>Special fees and charges- If there is a default or problem we may charge you fees and charges in accordance with our taxes.
</li>
                                <li>
                                    Additional information- You must provide additional information if we ask for it and our request is reasonable. It will always be reasonable for us to ask for information relating to the preconditions.
</li>

                            </ol>

                            <p>
                                *Suspension, refusal, blocking- Our rights are that we may:
</p>
                            <ul>
                                <li>suspend the processing of all or any part of your orders, offers, or transactions; and/or</li>
                                <li>
                                    suspend and/or stop you using all or any of the facilities which are available to you by you being an account user; and/or
</li>
                                <li>
                                    suspend and/or stop the provision of all or any services; and/or
</li>
                                <li>
                                    refuse to receive orders or offers or payments from you, and/or to provide or operate user accounts and/or associated direct debits for you; and/or
</li>
                                <li>cancel your user accounts and/or your status as an account user, and/or associated direct debits; and/or
    </li>
                                <li>block your access to our website and/or helpline facilities.
</li>

                            </ul>
                            <h2>GENERAL
</h2>
                            <p>
                                *Entire agreement- The order or offer as accepted by us, and these terms and conditions, and documents referred to in these terms and conditions, constitute the entire agreement between you and us concerning the contract. You acknowledge that you have not relied on any statement, promise, representation, assurance or warranty made or given by or on behalf of us which is not set out in the agreement. You acknowledge that we have not provided you with any financial, investment or tax advice.<br />

                                *Uncontrollable factors- We will not be liable to you as the result of any delay or failure in the performance of our obligations under or in connection with the contract which results from an event or occurrence which is beyond our reasonable control. In such circumstances, we may choose to terminate the contract or the affected part(s) of it at any time by giving you written notice.<br />
                                We are not obliged to buy metal from you. This is the case even if you bought the metals from us.<br /><br />

                                *Username and password security- It is your responsibility to ensure the confidentiality of your account username and password, and to inform us immediately if you have any reason to believe that your password has become known to anyone else. We cannot accept any responsibility for unauthorised use of your account where this is not the case.

</p>
                            <h2>
                                LANGUAGE
</h2>
                            <p>
                                We communicate in the English language. Our website, other communications with you, and all our dealings with you will be conducted in English, and these terms and conditions are available only in English.
</p>
                            <h2>
                                DISPUTES</h2>
                            <p>If you wish to dispute any matter relating to the order of offer, you may raise the matter in writing addressed to our registered office address or reach us out through the mail. We will respond as early as possible.
</p>
                            <h2>
                                YOUR OBLIGATIONS
</h2>
                            <p>
                                When you are the legal owner of the item(s) your obligations include paying all relevant taxes, duties and similar concerning the item(s). Your risks include the risks of loss, theft or damage of the item(s), and the item(s) causing loss or damage to you or us, or any other person.
</p>
                            <h2>
                                OUR OBLIGATION
    </h2>
                            <p>
                                To our rights and remedies under these terms and conditions, we will sell the item(s) stated in the order to you.
        </p>
                            <h2>
                                COMPLETION
        </h2>
                            <p>
                                Our obligations to sell the item(s) are completed when you become the legal owner of the item(s), or when we have been released from our obligations under our delivery services as per these terms and conditions.
        </p>
                            <h2>
                                DELIVERY TO YOU (OR TO US, FOR SELLING OF METAL)
            </h2>
                            <p>
                                *Place and time of delivery-  The goods will be delivered by our courier to the delivery address as stated in the order. The liability for any loss is yours where delivery is subject to a postal redirection or a PO Box or mailbox address, or a house of multiple occupancies.<br />
                                We cannot be held responsible for delays in delivery or collection as a result of stock shortages, due to adverse weather, traffic delays, mechanical breakdowns or other circumstances beyond our control. Any dates quoted for delivery are approximate only.<br />
                                All orders will be considered delivered when signed for by any person at the delivery address or confirmed as delivered by our couriers. You must satisfy yourself before requesting any delivery that this will be secure as any loss incurred after the delivery is signed for at the address or confirmed as delivered by our couriers will be your liability.<br />
                                You are advised not to accept or sign for any delivery where the package has been damaged, resealed or tampered with and instruct the courier to return the item to us. We cannot accept any liability for loss once a package has been accepted by any person at the delivery address.<br /><br />

                                *Fraud- If you are found to have acted fraudulently concerning non-delivery of the item, we will have no obligation to attempt fresh delivery of replacement item(s). This will apply if you are found to have acted fraudulently by any court or tribunal finding or decision, or reasonable grounds for a suspicion established by any investigation by us or any law enforcer.<br /><br />

                                *Your obligation to accept the delivery- If no one accepts delivery at the delivery address, the item(s) will be returned to us if not collected from the local sorting office. Normally we will try to contact you and attempt to re-deliver them. We do not normally treat this as a default or problem unless it happens repeatedly.<br />
                                Ultimately, if we are unable to arrange re-delivery, or if re-delivery fails, it will be a default or problem, and at our discretion, we may sell the item(s) and reimburse you for the sale price of the item(s) after deducting our fees, charges and costs.<br />

                                *Exclusion of our liability - If you delay or fail to take delivery We will not be liable to you for any delay in delivery of goods to you or any non-delivery if it is caused by (1) you being unavailable, (2) your instructions (or lack of instructions) relevant for delivery, or (3) by a person or factor that is outside the control of us or our carrier (e.g. traffic or emergency) or that is within your control.<br />

                                *Completion -Our delivery service is complete when we have completed our delivery obligations under these terms and conditions, or when we have been released from those obligations under these terms and conditions.<br />

                            </p>
                            <h2>
                                SELLING US METAL

                </h2>
                            <p>
                                The prices of these precious metals and coins are subject to fluctuations. <br />
                                When you sell metal, the payment you receive could be less than the original you paid for.<br /><br />

                                *Ordering process -To sell metal to us, the offer must specify which item(s) you wish to sell. Before we will buy the metal from you, the preconditions must be met. <br />
                                *Price-  The price of your offer will usually be based on a percentage of the then-current global market spot price for the quantity and type of metal you wish to sell as stated on the website or confirmed by us on the telephone or in-store.<br /><br />

                                *Your warranty when we buy- You assure us that you have met our metal-selling standards. A warranty is a statement of truth. If it proves to be untrue, you will be liable to us for all loss and damage that we suffer as a result.

*Packaging and delivery- You are responsible for packaging metal and evidence of your ownership of it as well as other items required to meet the preconditions, and for delivering them to us or arranging a carrier to deliver it to us for you. You are responsible for any costs of packaging, travel and your chosen courier charges. <br /><br />

                                *Payment - We will only make the payment on a working day after we will be able to sell to our initial supplier.<br /><br />

                                * Completion- Our obligations to buy the metal are completed when we become the legal owner of the metal.<br /><br />

                            </p>
                            <h2>
                                GLOSSARY
                </h2>
                            <ol>
                                <li>
                                    Metal: your goods, or other gold, silver or other metals which you own.
                                </li>
                                <li>
                                    Metal-selling standards: you must be the sole and absolute owner of the metal; your description of the metal must be true, complete, accurate and not misleading and is subject to being verified by us when we receive the metal from you; you must deliver the metal safely to us.
                                </li>
                                <li>
                                    Account user: You, after we have verified your ID, and there is a direct debit in our favour on your payment account, and we have issued you with an account card, and you have a username and password.
                                </li>
                                <li>
                                    Charges: Our fees and charges for providing our services, and for packaging and delivery. The circumstances when the fees and charges apply, and the amount of the fees and charges (or how they are calculated), is set out in FAQs or on our website.
                                </li>
                                <li>
                                    User account: The account we provide for you when you register on our website or in person or on the phone.
                                </li>
                                <li>
                                    Order- You request for metals and confirm your item from our website.

                                </li>
                                <li>
                                    Offer- You request to sell any metals to us.
                                </li>




                            </ol>
                            <p>

                            </p>
                        </div>
                    </Col>
                </Row>
                <Footer />
            </>
        )
    }
}
export default TermsConditions
import React, { Component } from "react";
import './style/Footer.css'
import 'antd/dist/antd.css';
import { Row, Col } from 'antd';

import Footer from "./Footer";





class GeneralCondition extends Component {
    render() {
        return (
            <>
                <Row>
                    <Col span={2} className="counter-section">
                        <img style={{ height: 400 }} src={require('../Component/Home/directgold_poster.jpg')} alt="offer-poster" />
                    </Col>
                    <Col span={20}>
                        <div className="about-section">
                            <h1> General Information and conditions – SCARPINO BV</h1>
                            <h2>Each order needs to be finally approved by Direct Gold. We will make every effort to provide correct information and propose correct prices on our Webshop. It is to be informed that normally our prices are “as indicated” on our Website, Direct Gold frequently handles substantial market changes happen within a short span of time. So we cannot rule out all errors. Direct Gold has therefore the right to exceptionally correct price changes, even if an order has already been executed (by putting the products in the purchase basket and after having pushed the Checkout button). The purchase can only be “approved” the moment that you receive the “Pro Forma INVOICE”. At that moment, you still have the right to choose whether to accept your purchase (against the possibly adjusted price conditions) or to cancel the order. If you decide to cancel, you will be directed back to the Webshop without further consequences and you can start again. If you confirm the purchase, you will be directed to proceed with the payment and place your order.
                            </h2>
                            <h2>
                                The whole process from “Checkout to valid payment” must be executed within a limited timeframe of 15 minutes. (This to avoid important market changes during the purchase process).
                            </h2>

                            <h2>
                                The transaction (and your purchase) is only finalized completely (definitive) once we have received a notification of the Payment interface module. Also, the customer will receive a confirmation mail on the success of the payment.
                            </h2>

                            <h2>
                                We deliver in Belgium, Holland and Luxembourg. Since goods are clearly defined as investment products (precious metals, mints, etc..) and having a pre-defined Quality, Certification or Qualification, weight, Purity, etc…, your purchase can be seen as a price/time-dependent product comparable to buy shares in the Stock Market. So during the purchasing process, the price can only be frozen for a very limited period. The difference between the two is that our product will be physically delivered to you, but the latter will always remain a financial product.
                            </h2>

                            <h2>
                                You have the right of withdrawal for the goods that you purchased. So you could decide, within fourteen calendar days after the day of delivery, to renounce your previous purchase of the product(s) and return these at your expense, after which Direct Gold will send you within thirty days as a refund. The costs of returning the goods are at the customer’s expense. We request that you first report the use of the right of withdrawal to “scarpino5@mac.com”. The goods will be returned and refunded at the rate of the current value of the good at present (Rate at which Direct Gold can resell it to its provider). Value of Gold and Silver and other precious metals fluctuate continuously. Also, there exists a “spread” between Purchase and Selling Price. This Spread will be applicable when returning the goods.

                            </h2>
                            <h2>
                                The Customer must abide by the return policies and ensure the safest return the goods in terms of quality with its original packaging. The company demands clear signed documents which ensure the ‘resale of the item in a perfect condition affixed with a ‘delivery note’.

                            </h2>
                            <h2>
                                All expressions of Direct Gold on its website or its catalogues are only an invitation to make an offer without on that moment explicitly binding Direct Gold. Only after receipt of an invoice, customer’s payment with payment receipt within a limited time frame, we can speak about a binding purchase.
                            </h2>
                            <h1>
                                Dissolution and suspension
                            </h1>
                            <h2>
                                The customer undertakes the responsibility to provide his/her data correctly with every transaction with Direct Gold via the web-shop (filling in a predefined form), so that Direct Gold can meet the legal identification obligation of the customer. Unreasonably filled information will not be accepted and Direct Gold has the right to temporarily suspend the delivery or payment of a transaction until it has the correct and undeniably personal data. This possible suspension of delivery or payment cannot give rise to compensation from Direct Gold for an ultimately later delivery or payment.
                            </h2>
                            <h2>
                                In case of non-payment, the customer who signs the order is severally liable, company or organization for which he is acting.
                            </h2>
                            <h2>
                                The transaction is only confirmed after a written acceptance of this transaction by Direct Gold (an initial delivery will be made for an invoice). After accepting this transaction by  Direct Gold the customer can only cancel a transaction if he pays compensation of 10% of the transaction amount, possibly increased by the price disadvantage of the product that Direct Gold experiences as a result of fluctuations in the prices of metals in the markets. The customer can agree to a transaction by written or electronic means or through the payment of the invoice within a pre-defined period. The data files of Direct Gold will serve as proof here. Information, images, offers, notices, colour indications and the main features of the products are displayed or provided as similar as possible. Deviations from reality cannot lead to compensation and/or dissolution. Direct Gold is not liable for obvious errors. No rights can be derived from this information unless it is included in an agreement concluded by the parties.
                                </h2>
                                <h2>
                                By agreeing with Direct Gold, the customer explicitly declares that he/she, both for products being sold and purchased from us, he/she acts in full accordance with the applicable tax rules. This applies to Belgian residents as well as to non-residents. In case of doubt, the customer should consult a tax specialist.
                                </h2>
                                <h2>
                            The prices for the products and services offered are in euros (€), including VAT (if applicable), unless stated otherwise. The costs for packaging, transport and shipping will be mentioned in the invoice.
                                </h2>
                                <h2>
                        The prices for precious metals always depend on the prices determined in the international markets and any applicable taxes or excise duties and at suppliers, producers “making” prices. Direct Gold is not bound by any inaccurate prices that may arise from a faulty data connection with the financial markets.
                                </h2>
                                <h2>
                    The prices on the Direct Gold website (www.directgold.eu) are purely indicative and do not bind Direct Gold in any way. We are not bound by faulty price statements in advertisements, in offers, quotations, publications, order confirmations, or other documents from us.
                                </h2>
                                <h2>
                If after the date of the confirmation of an offer by Direct Gold one or more of the cost price factors undergo any change, even if this occurs due to foreseeable circumstances, Direct Gold is entitled to adjust the agreed price accordingly. Direct Gold reserves the right to withdraw offers if these offers are not confirmed by the customer for approval in due time for any reason. In the case of final approval and payment, but where there should arise a problem in delivery by our side, we will refund the customer’s previous payment.
                                </h2>
                                <h2> 
                Payment of a purchase transaction by the customer is only made by transfer to the bank account of Direct Gold. If the customer pays by bank cheque or cash (within legal limitations), and we accept this – for example, the customer could buy and pay directly when goods are in stock and available at Direct Gold webshop. Direct Gold has the right to postpone the delivery until the final crediting of the payment is ensured with the relevant “bank-cheque executed”.
                                </h2>  
                                <h2> 
                We have the following rights under the contract if there is a default or problem or a material breach by you, or if any insolvency on your part occurs, or if we have reasonable grounds to suspect any of the foregoing. We may choose to exercise these rights in addition to any right of termination we may have, or as an alternative. We will tell you that we are exercising our rights, and we may do this by email, phone or by formal written notice. Our rights are that we may:
                <br /> - suspend the processing of all or any part of your orders, offers, or transactions; and/or
                                <br /> - suspend and/or stop you using all or any of the facilities which are available to you under you being an account user; and/or
                                <br /> - suspend and/or stop the provision of all or any services.
                                </h2>
                                <h2>
                Direct Gold always reserves a right of retention on the goods until the customer has made full payment, including payment for previous deliveries, or has provided sufficient security for the fulfilment of all existing and future payment obligations.
                                </h2>
                                <h2> Direct Gold can only be held liable for damage if there is demonstrably intent and/or gross negligence on our part.  Other than direct damage, including loss of profit, the loss suffered and missed orders, is not eligible for compensation. The amount of any compensation will never exceed the value of the assignment given by the customer. Subject to previous liability, the customer indemnifies Direct Gold against all third-party claims arising or related to the agreement concluded with the customer. Before an agreement has been concluded, Direct Gold will never be obliged to pay compensation.
            
                                    </h2>
                                <h2>
                Direct Gold is. not liable for delays or improper delivery of orders and communications as a result of the use of the Internet or any other means of communication in the traffic between the customer and us.
                
                                    </h2>
                                <h2>
                Direct Gold has other reasons to believe that the customer will not fulfil any obligation, gives us the right to dissolve any agreement with the customer with immediate effect, without prejudice to the right to claim full compensation. In the event of termination of an agreement, all claims on the customer will be due and payable.
                </h2>
                                <h2>
                The obtained customer data that is included in the order is utilised to process that order and to provide information to the customer. This data is not provided or sold to third parties. All agreements signed with Direct Gold are governed by Belgian law. In case of dispute, only the courts of the judicial district of Antwerp have jurisdiction.
                </h2>
                                <br />
            <br />
            <br />
            <h1 id="help&contact">Help & Contact</h1>
            <h2>
                                    We are DirectGold affiliate of “Scarpino bv”, a Belgian company situated in the Antwerp region.
                                    <br />
                                    Our company number: “BE0458.439.618”
                                    <br />
                                    Our telephone number: +32(0)486 68 70 68
                                    <br />
                                    Our e - mail address: Scarpino5@mac.com
                                    <br />

                                    Reach us out in case of any query, complaints, and information etc.
                                    <br />
                                    Also, you can “Live” chat with us via our website.
        < br /> 
        Read (subscribe) for free to our monthly newsletter and informative blogs.
You can read our FAQ’s (Frequent Asked Questions) on our website. (We will update FAQs section).

                                     <br />
            <br />
            <br />
            <br />
                                </h2 > 
 
                        </div>
                    </Col> 
                </Row> 
                    < Footer />
            </> 
                );
            }
    }

        


export default GeneralCondition

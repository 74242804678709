import React, { Component } from "react";
import './style/Footer.css'
import 'antd/dist/antd.css';
import { Row, Col } from 'antd';

import Footer from "./Footer";





class FAQ extends Component {
    render() {
        return (
            <>
                <Row>
                    <Col span={2} className="counter-section">
                        <img style={{ height: 400 }} src={require('../Component/Home/directgold_poster.jpg')} alt="offer-poster" />
                    </Col>
                    <Col span={20}>
                        <div className="about-section">
                            <h1>FAQ's</h1>
                            <br />
                            <br />
                            <h2>How are my items sent?
</h2>
                            <p>
                                Once payment is made by the customer, there follows a confirmation mail. The mail will keep the customer updated about the current status of the item being delivery with shipment and expected delivery time.
</p>
                            <h2>What is the time of Delivery?</h2>
                            <p>DirectGold will set a delivery day and time. In the mail, all the details regarding the approximate day and time of delivery will be mentioned. If the client finds the date and time unsuitable, we will mutually agree on a new timing (Day, Time and Place).
</p>
                            <h2>
                                What is the price for the Delivery?
</h2>
                            <p>Each purchase above 7.500€ (this can be a combination of several products), is applicable for free delivery within the BeNeLux (Belgium, Netherlands, Luxembourg). If the total purchase price is below that amount, the minimum cost of 38€  will be charged within a circle of 100km from Antwerp. If the distance exceeds 100km, a supplement of 25€ will be added.
</p>
                            <h2> What happens if I am not at home to take delivery?
</h2>
                            <p>
                                A card will be left by courier so that you can rearrange delivery for another day or collect from my nearest spot taking ID with you. The extra will be invoiced at your expense as mentioned in the table above. Meanwhile, your parcel will remain fully insured up until it is signed for.

</p>
                            <h2>
                                What about delivery outside of Belgium?
</h2>
                            <p>
                                We deliver the goods within the BeNeLux (Belgium, Netherlands, Luxembourg). If outside the BenNeLux, the customer should take the responsibility of the handover of the goods at a specific location and time frame (to be agreed mutually) in Belgium.

</p>
                            <h2>What are the payment options available?
</h2>
                            <p>
                                The payment will be made online using the “Mollie payment gateway” (integrated in the Webshop).

</p>
                            <p>Supported payment methods for buying gold, silver and diamond products are (Direct Payment via bank transfer) :</p>
                            <ul>
                                <li>

                                    1) SEPA BANK Transfer (SEPA) (Europe)

    </li>
                                <li>2) BAN CONTACT (for Belgium)

</li>
                                <li>3) IDEAL  (For The Netherlands)</li>
                            </ul>
                            <h2>
                                Do I have to pay with Euros?

                            </h2>
                            <p>
                                Yes, our bank account is Euro denominated and we accept payments in Euros only.
                            <br />
                                Do I have to provide identification to purchase metals from you?
                                Yes. Personal ID is mandatory and it should be verifiable by us in the first place. If asked, you will be required to provide us with additional information.
                            </p>
                            <h2>
                                Why is the price different when I place my order?
                            </h2>
                            <p>
                                The webshop displays the lowest available price depending on the quantity that you are purchasing. Normally there should not be a difference in price with the one in your cart+ calculation of delivery cost. If the timer (15 minutes) has exceeded before the purchase is made, the process will be automatically cancelled and you will be redirected to the webshop to eventually renew your purchase and restart the process. At that moment, it is possible that price will be different (due to market price fluctuations).
                            </p>
                            <h2>
                                Is there any limited time period to place your order?
                            </h2>
                            <p>
                                Yes. In order to avoid fluctuations, you will be given 30 minutes to freeze the price of the item you have added to cart. After that, the prices will be subjected to market fluctuations.
                            </p>
                            <h2>How are my items sent?</h2>
                            <p>Once payment is made by the customer, there follows a confirmation mail. The mail will keep the customer updated about the current status of the item being delivery with shipment and expected delivery time.
                            </p>
                            <h2>What is the time of Delivery?</h2>
                            <p>
                                DirectGold will set a delivery day and time. In the mail, all the details regarding the approximate day and time of delivery will be mentioned. If the client finds the date and time unsuitable, we will mutually agree on a new timing (Day, Time and Place).
                            </p>
                            <h2>What is the price for the Delivery?</h2>
                            <p>
                                Each purchase above 7.500€ (this can be a combination of several products), is applicable for free delivery within the BeNeLux (Belgium, Netherlands, Luxembourg). If the total purchase price is below that amount, the minimum cost of 38€  will be charged within a circle of 100km from Antwerp. If the distance exceeds 100km, a supplement of 25€ will be added.
                            </p>
                            <h2> What happens if I am not at home to take delivery?</h2>
                            <p>
                                A card will be left by courier so that you can rearrange delivery for another day or collect from my nearest spot taking ID with you. Your parcel will remain fully insured up until it is signed for.
                            </p>
                            <h2>What about delivery outside of Belgium?</h2>
                            <p>
                                We deliver the goods within the BeNeLux (Belgium, Netherlands, Luxembourg). If outside the BenNeLux, the customer should take the responsibility of the handover of the goods at a specific location and time frame (to be agreed mutually) in Belgium.
                            </p>
                            <h2>
                                What are the payment options available?
                            </h2>
                            <p>
                                The payment will be made online using Mollie payment gateway.
                            </p>
                            <h2>
                                Do I have to pay with Euros?
                            </h2>
                            <p>
                                Yes, our bank account is Euro denominated and we accept payments in Euros only.
                            </p>
                            <h2>
                                Do I have to provide identification to purchase metals from you?
                            </h2>
                            <p>
                                Yes. Personal ID is mandatory and it should be verifiable by us in the first place. If asked, you will be required to provide us with additional information.
                            </p>
                            <h2>
                                Why is the price different when I place my order?
                            </h2>
                            <p>
                                The webshop displays the lowest available price depending on the quantity that you are purchasing. In order to see the various prices listed by the quantity, please click on the item itself to take you to the next screen.
                            </p>
                            <h2>
                                Can I resell my gold and silver?
                            </h2>
                            <p>
                                Gold is generally accepted in most countries all across the world at specialized Precious Metals resellers.
    Yes, we always take back the Gold and Silver even if purchased from us (refer to our terms and conditions). It will be bought back on the market conditions of that moment + we will count a margin (comparable with margins used by our suppliers). The costs for delivery to “Scarpino – Direct Gold”, is to be born by the reseller. The amount will be refunded back into your account after we will be able to sell back the product in the market or to our initial supplier.
                            </p>
                            <h2>
                                What about TAXES?
                            </h2>
                            <p>
                                On the purchase of GOLD products, there are no Taxes due in Belgium.
    On the purchase of SILVER bars and plates, there is a TAX due in Belgium of 21%. Silver is considered as an Industrial Product. Taxes on Silver can be avoided if you buy Silver Coins. Silver Coins (relative more expensive than bars) but are considered as a payment or a collectors product. Therefore there are no Taxes due on Silver Coins in Belgium. There even exists Silver coins of 1kg and even 10kg. They keep being considered as coins.
                            </p>
                            <h2>
                                How to recognise real Gold and Silver?
                            </h2>
                            <p>
                                There are several methods to recognise real Gold and Silver. The most obvious one is:
    Gold and silver have a certain specific mass in a certain volume. For gold, the specific mass is 19.32 g / cm3. This means that 1 cm3 of pure gold has a mass of 19.32 grams. The specific mass for silver is 10.49 g / cm3. This means that 1 cm3 of pure silver has a mass of 10.49 grams. This volume can be determined by immersing the Gold or Silver in a very accurate measuring cup with water. Moreover, there are several chemical control methods to determine the quality of Gold and Silver.
                            </p>

                        </div>
                    </Col>

                </Row>


                <Footer />
            </>
        );
    }
}



export default FAQ

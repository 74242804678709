import React, { Component } from "react";
import "./style/Footer.css";
import "antd/dist/antd.css";
import { Row, Col } from "antd";

import Footer from "./Footer";

class Privacy extends Component {
  render() {
    return (
      <>
        <Row>
          <Col span={2} className="counter-section">
            <img
              style={{ height: 400 }}
              src={require("../Component/Home/directgold_poster.jpg")}
              alt="offer-poster"
            />
          </Col>
          <Col span={20}>
            <div className="about-section">
              <h1> Privacy Policy</h1>
              <h2>
                The obtained customer data that is included in the order is used to process that order and to provide information to the customer. This data is not provided or sold to third parties.
              </h2>
              <h2>Processing of personal data</h2>
              <h2>
                Direct Gold can process personal data about you because you use the services of Direct Gold, and/or because you provide them yourself to Direct Gold when filling in a contact form on our website. Direct Gold can process the following personal data :
              <br /> <br />
              </h2>
              <p>
                First and last name
              <br />
                Residential address
              <br />
                Telephone number
              <br />
                E-mail address
              <br />
                IP-address
              <br />
                VAT number
              <br />
                National Personal number
              <br />
                Copy ID-Card
              <br />
              </p>
              <h2>Why does Direct Gold need these data?</h2>
              <h2>
                Direct Gold uses your data in the context of agreeing with you, usually consisting of online sales of our products. Also, Direct Gold can process your data to be able to contact you by telephone if you request it, and/or to be able to contact you in writing (by e-mail and/or by post) if you are not reached by telephone, to send Newsletters (that you can unsubscribe at any time) and another kind of useful information related to your purchase.

              </h2>
              <h2>How long will “Scarpino bv” store these data ?</h2>
              <h2>
                “Scarpino bv” does not store your personal data longer than is
                strictly necessary to achieve the goals for which your data is
                collected. The Tax Authorities require us to keep our
                administration with your invoice, payment and order data for 7
                years. After that, we only use anonymous data for internal
                reports. If you have registered for the newsletter or have given
                permission to receive personalized messages, we will keep that
                permission until you unsubscribe. Even if at some point you
                decide that you no longer wish to receive the newsletter or
                personalized messages, we will keep the withdrawal of your
                request. After that period, we only use your data anonymously
                for internal reports.
              </h2>
              <br />

              <h2>How long will Direct Gold store the data?</h2>
              <h2>
                Direct Gold does not store or track your data as long as it becomes important to achieve the goals for which your data is collected. The Tax Authorities require us to keep our administration with your invoice, payment and order data for 7 years. After that, we only use anonymous data for internal reports. If you have registered for the newsletter or have given permission to receive personalized messages, we will abide by that permission until you ‘unsubscribe’.
              </h2>

              <br />
              <h2>Google Analytics?</h2>
              <h2>
                The standard period for which data originating from cookies or advertisements is stored is 26 months. Do you want your data to be deleted immediately after you have visited “DirectGold.eu”?
  Contact us at “scarpino5@mac.com”. After processing the data, it takes 24 hours before your data gets deleted.
              </h2>

              <br />
              <h2>Website visit</h2>
              <h2>
                General visitor data is kept on the website of Direct Gold including the IP address of your computer and the time of retrieval and data that your browser sends. This data is used for the analysis of visitor and click behaviour on the website. Direct Gold uses this information to improve the functioning of the website. This data is anonymised as much as possible.
              </h2>
              <br />
              <h2>Legal</h2>
              <h2>
                Finally, there is the possibility that Direct Gold provides your data in case of any legal obligation, for a summon or a lawsuit or if this is legally required in some other way, or if we believe it is necessary to defend our rights. The common usage is to prevent or combat illegal activities or violations of our policies.
              </h2>
              <h2>Securitisation</h2>
              <h2>
                Direct Gold takes the protection and privacy of your data seriously. We take appropriate measures to prevent misuse, loss, unauthorized access, unwanted disclosure and unauthorized changes.
              </h2>
            </div>
          </Col>
        </Row>
        <Footer />
      </>
    );
  }
}

export default Privacy;

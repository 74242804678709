import React, { Component } from "react";
import "./style/Footer.css";
import "antd/dist/antd.css";
import { Row, Col } from "antd";

import Footer from "./Footer";

class PartnerWithUs extends Component {
    render() {
        return (
            <>
                <Row>
                    <Col span={2} className="counter-section">
                        <img
                            style={{ height: 400 }}
                            src={require("../Component/Home/directgold_poster.jpg")}
                            alt="offer-poster"
                        />
                    </Col>
                    <Col span={20}>
                        <div className="about-section">
                            <h1>Partner With Us</h1>
                            <h2>
                                If you want to initiate any business partnership with Direct Gold, we would be pleased to hear from you.
    <br/>E-mail : info@directgold.store
    <br/>Contact: 0032 (0)486 68 70 68
    
    
                            </h2>
                        </div>
                    </Col>
                </Row>
                <Footer />
            </>
        );
    }
}

export default PartnerWithUs

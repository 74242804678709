import React from "react";
import axios from "axios";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  Select,
  message,
  Tooltip,
  Radio,
  Typography,
  Empty,
} from "antd";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import Invoice from "./Invoice";
import "./style/Checkout.css";
import { setCurrentUser } from "../../redux/user/user.actions";

const { Title } = Typography;

const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not validate email!",
    number: "${label} is not a validate number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
  city: {
    range: "${label} must be between ${min} and ${max}",
  },
};
const { Option } = Select;

class Checkout extends React.Component {
  constructor() {
    super();
    this.state = {
      country: "",
      addaddressName: "",
      phone: "",
      address: "",
      user: null,
      redirectToInvoice: false,
      minutes: 15,
      seconds: 0,
      city: null,
      pinCode: "",
      userWillAddAddr: false,
      userWillPrevAddr: false,
      selectedAddress: null,
    };
  }

  componentDidMount() {
    this.myInterval = setInterval(() => {
      const { seconds, minutes } = this.state;

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(this.myInterval);
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59,
          }));
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  onFinishWithExistingData = () => {
    if (this.state.selectedAddress != null) {
      this.setState({
        user: this.props.currentUser,
        phone: this.props.currentUser.phone,
        address:
          this.state.selectedAddress.address +
          ", " +
          this.state.city +
          ", " +
          this.state.country,
        pinCode: this.state.selectedAddress.postCode,
        city: this.state.selectedAddress.city,
        redirectToInvoice: true,
      });
    } else {
      message.error("Add Address");
    }
  };

  onFinish = (event) => {
    if (this.state.city && this.state.pinCode) {
      if (this.state.addaddressName !== "") {
        this.setState({
          user: this.props.currentUser,
          phone: event.user.phone,
          address:
            event.user.address +
            ", " +
            this.state.city +
            ", " +
            this.state.country,
          pinCode: this.state.pinCode,
          city: this.state.city,
          redirectToInvoice: true,
        });

        axios
          .post(`${process.env.REACT_APP_API_URL}/api/auth/adddddddaddress`, {
            email: this.props.currentUser.email,
            user: this.props.currentUser,
            phone: event.user.phone,
            address:
              event.user.address +
              ", " +
              this.state.city +
              ", " +
              this.state.country,
            postCode: this.state.pinCode,
            city: this.state.city,
            addressName: this.state.addaddressName,
          })
          .then((res) => {
            const user = res.data;
            const isLoggedIn = true;
            setCurrentUser(user.payload, isLoggedIn);
            localStorage.setItem("token", JSON.stringify(user.payload));
            // Cookies.set('user', user.payload, { expires: 7 }, { path: '/*' });
            this.setState({ user });
            if (res) {
              message.success("Address Added");
            }
            this.setState({ setIsModalVisible: false });
          })
          .catch(function (error) {
            console.log("Eorror", error);
            message.warning(
              "Request Failed, Try again using regiestered email to recover password !!"
            );
          });
      } else {
        message.error("Add Address Name");
      }
    } else {
      if (this.state.city) {
        message.warning("Add Postal Code");
      } else {
        message.warning("Select Province");
      }
    }
  };
  handleCityOptions = (country) => {
    if (country === "") {
      return null;
    } else if (country === "België") {
      return (
        <>
          <Option value="Antwerpen">
            <Tooltip placement="left" title="Antwerpen">
              <span>Antwerpen</span>
            </Tooltip>
          </Option>
          <Option value="Vlaams-Brabant">
            <Tooltip placement="left" title="Vlaams-Brabant">
              <span>Vlaams-Brabant</span>
            </Tooltip>
          </Option>
          <Option value="Oost-Vlaanderen">
            <Tooltip placement="left" title="Oost-Vlaanderen">
              <span>Oost-Vlaanderen</span>
            </Tooltip>
          </Option>
          <Option value="Limburg_B">
            <Tooltip placement="left" title="Limburg_B">
              <span>Limburg_B</span>
            </Tooltip>
          </Option>
          <Option value="West-Vlaanderen">
            <Tooltip placement="left" title="West-Vlaanderen">
              <span>West-Vlaanderen</span>
            </Tooltip>
          </Option>
          <Option value="Henegouwen">
            <Tooltip placement="left" title="Henegouwen">
              <span>Henegouwen</span>
            </Tooltip>
          </Option>
          <Option value="Waals-Brabant">
            <Tooltip placement="left" title="Waals-Brabant">
              <span>Waals-Brabant</span>
            </Tooltip>
          </Option>
          <Option value="Namen">
            <Tooltip placement="left" title="Namen">
              <span>Namen</span>
            </Tooltip>
          </Option>
          <Option value="Luik">
            <Tooltip placement="left" title="Luik">
              <span>Luik</span>
            </Tooltip>
          </Option>
          <Option value="Luxemburg_B">
            <Tooltip placement="left" title="Luxemburg_B">
              <span>Luxemburg_B</span>
            </Tooltip>
          </Option>
        </>
      );
    } else if (country === "Luxembourg") {
      return (
        <>
          <Option value="Luxembourg_Lux">
            <Tooltip placement="left" title="Luxembourg_Lux">
              <span>Luxembourg_Lux</span>
            </Tooltip>
          </Option>
        </>
      );
    } else if (country === "Nederland") {
      return (
        <>
          <Option value="Limburg_NL">
            <Tooltip placement="left" title="Limburg_NL">
              <span>Limburg_NL</span>
            </Tooltip>
          </Option>

          <Option value="Zeeland">
            <Tooltip placement="left" title="Zeeland">
              <span>Zeeland</span>
            </Tooltip>
          </Option>

          <Option value="Noord-Brabant">
            <Tooltip placement="left" title="Noord-Brabant">
              <span>Noord-Brabant</span>
            </Tooltip>
          </Option>

          <Option value="Zuid-Holland">
            <Tooltip placement="left" title="Zuid-Holland">
              <span>Zuid-Holland</span>
            </Tooltip>
          </Option>

          <Option value="Utrecht">
            <Tooltip placement="left" title="Utrecht">
              <span>Utrecht</span>
            </Tooltip>
          </Option>
          <Option value="Noord-Holland">
            <Tooltip placement="left" title="Noord-Holland">
              <span>Noord-Holland</span>
            </Tooltip>
          </Option>
          <Option value="FlevoLand">
            <Tooltip placement="left" title="FlevoLand">
              <span>FlevoLand</span>
            </Tooltip>
          </Option>
          <Option value="Overijssel">
            <Tooltip placement="left" title="Overijssel">
              <span>Overijssel</span>
            </Tooltip>
          </Option>

          <Option value="Friesland">
            <Tooltip placement="left" title="Friesland">
              <span>Friesland</span>
            </Tooltip>
          </Option>
          <Option value="Drenthe">
            <Tooltip placement="left" title="Drenthe">
              <span>Drenthe</span>
            </Tooltip>
          </Option>
          <Option value="Groningen">
            <Tooltip placement="left" title="Groningen">
              <span>Groningen</span>
            </Tooltip>
          </Option>
        </>
      );
    }
  };
  handleCity = (value) => {
    this.setState({
      city: value,
    });
  };
  handleCountry = (value) => {
    this.setState({
      country: value,
    });
  };
  handleAddressName = (value) => {
    this.setState({
      addaddressName: value,
    });
  };

  handleAddingAddress = () => {
    this.setState({
      userWillPrevAddr: false,
      userWillAddAddr: true,
    });
  };

  handleExistingAddress = () => {
    this.setState({
      userWillPrevAddr: true,
      userWillAddAddr: false,
    });
  };

  onChange = (e) => {
    this.setState({ selectedAddress: e.target.value });
  };

  render() {
    const { minutes, seconds } = this.state;
    if (this.state.redirectToInvoice) {
      return (
        <Row>
          <Col span={24}>
            <div className="countdown-style">
              {minutes === 0 && seconds === 0 ? (
                <Redirect to="/cart" />
              ) : (
                <h1>
                  {(minutes % 5 === 0 && seconds === 0) ||
                  (minutes === 1 && seconds === 0)
                    ? notification.info({
                        message: `${minutes} minutes remaining`,
                      })
                    : null}
                  Time Remaining: {minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </h1>
              )}
            </div>
          </Col>
          <Col span={24}>
            {this.state.redirectToInvoice ? (
              <Invoice user={this.props.currentUser} userData={this.state} />
            ) : null}
          </Col>
        </Row>
      );
    }
    return (
      <>
        {this.props.currentUser ? (
          <Row>
            {this.props.currentUser.addresses[0] ? (
              <>
                <Col span={24}>
                  <div className="countdown-style">
                    {minutes === 0 && seconds === 0 ? (
                      <Redirect to="/cart" />
                    ) : (
                      <h1>
                        {(minutes % 5 === 0 && seconds === 0) ||
                        (minutes === 1 && seconds === 0)
                          ? notification.info({
                              message: `${minutes} minutes remaining`,
                            })
                          : null}
                        Time Remaining: {minutes}:
                        {seconds < 10 ? `0${seconds}` : seconds}
                      </h1>
                    )}
                  </div>
                </Col>
                <Col span={24} className="invoice-form">
                  <div style={{ textAlign: "center" }}>
                    <Typography.Title level={2}>Saved Address</Typography.Title>
                    <Row>
                      <Col span={12}>
                        <Typography.Title level={4}>
                          Name: {this.props.currentUser.nameFirst}{" "}
                          {this.props.currentUser.nameLast}
                        </Typography.Title>
                      </Col>
                      <Col span={12}>
                        <Typography.Title level={4}>
                          Email: {this.props.currentUser.email}
                        </Typography.Title>
                      </Col>
                      <Col span={12}>
                        <Typography.Title level={4}>
                          Phone: {this.props.currentUser.phone}
                        </Typography.Title>
                      </Col>
                      <Col span={12}></Col>
                      <Radio.Group onChange={this.onChange}>
                        <Row>
                          {this.props.currentUser.addresses ? (
                            this.props.currentUser.addresses.map((address) => (
                              <Col span={12}>
                                <div
                                  style={{ textAlign: "left" }}
                                  className="address-box"
                                >
                                  <Radio value={address}>
                                    <Title level={3}>
                                      {address.addressName}
                                    </Title>
                                  </Radio>
                                  <Title level={5}>
                                    Address: {address.address}
                                  </Title>
                                  <Title level={5}>
                                    Province: {address.city}
                                  </Title>
                                  <Title level={5}>
                                    Postal Code: {address.postCode}
                                  </Title>
                                </div>
                              </Col>
                            ))
                          ) : (
                            <Empty />
                          )}
                        </Row>
                      </Radio.Group>

                      <Col
                        style={{ textAlign: "center", marginTop: "100px" }}
                        span={24}
                      >
                        <Button
                          type="primary"
                          className="form-submit"
                          onClick={this.onFinishWithExistingData}
                        >
                          Submit
                        </Button>
                        <br />
                        <Button
                          type="primary"
                          style={{ margin: "5% 0" }}
                          className="form-submit"
                          onClick={this.handleAddingAddress}
                        >
                          Add Diffrent Address
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Col>

                {this.state.userWillAddAddr ? (
                  <>
                    <Col className="invoice-form" span={24}>
                      <Form
                        name="nest-messages"
                        onFinish={this.onFinish}
                        validateMessages={validateMessages}
                        layout="vertical"
                        style={{
                          margin: "3% 20% 5%",
                          border: "1.5px solid #000",
                          borderRadius: "10px",
                          padding: "100px",
                          boxShadow: "0px 0px 20px 0px #00000073",
                        }}
                      >
                        <Form.Item
                          style={{ textAlign: "center" }}
                          label="Address Name :"
                        >
                          <Select
                            defaultValue="-select-"
                            style={{ width: 250, textAlign: "center" }}
                            onChange={this.handleAddressName}
                          >
                            <Option value="Home">Home</Option>
                            <Option value="Office">Office</Option>
                            <Option value="Other">Other</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          style={{ textAlign: "center" }}
                          name={["user", "name"]}
                          label="Name"
                        >
                          <Title level={5}>
                            {" "}
                            {this.props.currentUser.nameFirst}{" "}
                            {this.props.currentUser.nameLast}
                          </Title>
                        </Form.Item>
                        <Form.Item
                          style={{ textAlign: "center" }}
                          name={["user", "email"]}
                          label="Email"
                        >
                          <Title level={5}>
                            {this.props.currentUser.email}
                          </Title>
                        </Form.Item>
                        <Form.Item
                          name={["user", "phone"]}
                          label="Phone :"
                          rules={[{ required: true }]}
                          style={{ marginRight: "2%" }}
                        >
                          <Input
                            value={this.state.phone}
                            onChange={this.updatePhone}
                          />
                        </Form.Item>
                        <Form.Item
                          name={["user", "address"]}
                          label="Flat, House No., Building, Company, Apartment :"
                          rules={[{ required: true }]}
                          style={{ marginRight: "2%" }}
                        >
                          <Input.TextArea
                            value={this.state.address}
                            onChange={this.updateAddress}
                          />
                        </Form.Item>

                        <Row style={{ textAlign: "center" }}>
                          <Col span={12}>
                            <Form.Item
                              label="Country"
                              value={this.state.country}
                              rules={[{ city: true }]}
                            >
                              <Select
                                defaultValue="-select-"
                                style={{ width: 120 }}
                                onChange={this.handleCountry}
                              >
                                <Option value="België">
                                  <Tooltip placement="left" title="België">
                                    <span>België</span>
                                  </Tooltip>
                                </Option>
                                <Option value="Nederland">
                                  <Tooltip placement="left" title="Nederland">
                                    <span>Nederland</span>
                                  </Tooltip>
                                </Option>
                                <Option value="Luxembourg">
                                  <Tooltip placement="left" title="Luxembourg">
                                    <span>Luxembourg</span>
                                  </Tooltip>
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Province :"
                              value={this.state.city}
                              rules={[{ city: true }]}
                            >
                              <Select
                                defaultValue="-select-"
                                style={{ width: 200 }}
                                onChange={this.handleCity}
                              >
                                {this.handleCityOptions(this.state.country)}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col style={{ textAlign: "center" }} span={24}>
                            <Form.Item label="Postal Code :">
                              <Input
                                type="number"
                                value={this.state.pinCode}
                                style={{ width: "150px" }}
                                onChange={(e) =>
                                  this.setState({ pinCode: e.target.value })
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item style={{ textAlign: "center" }}>
                          <Button
                            className="form-submit"
                            type="primary"
                            htmlType="submit"
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </Form>
                    </Col>
                  </>
                ) : null}
              </>
            ) : (
              <Col className="invoice-form" span={24}>
                <Form
                  name="nest-messages"
                  onFinish={this.onFinish}
                  validateMessages={validateMessages}
                  layout="vertical"
                  style={{
                    margin: "3% 20% 5%",
                    border: "1.5px solid #000",
                    borderRadius: "10px",
                    padding: "100px",
                    boxShadow: "0px 0px 20px 0px #00000073",
                  }}
                >
                  <Form.Item
                    style={{ textAlign: "center" }}
                    label="Address Name :"
                  >
                    <Select
                      defaultValue="-select-"
                      style={{ width: 250, textAlign: "center" }}
                      onChange={this.handleAddressName}
                    >
                      <Option value="Home">Home</Option>
                      <Option value="Office">Office</Option>
                      <Option value="Other">Other</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    style={{ textAlign: "center" }}
                    name={["user", "name"]}
                    label="Name"
                  >
                    <Title level={5}>
                      {" "}
                      {this.props.currentUser.nameFirst}{" "}
                      {this.props.currentUser.nameLast}
                    </Title>
                  </Form.Item>
                  <Form.Item
                    style={{ textAlign: "center" }}
                    name={["user", "email"]}
                    label="Email"
                  >
                    <Title level={5}>{this.props.currentUser.email}</Title>
                  </Form.Item>
                  <Form.Item
                    name={["user", "phone"]}
                    label="Phone :"
                    rules={[{ required: true }]}
                    style={{ marginRight: "2%" }}
                  >
                    <Input
                      value={this.state.phone}
                      onChange={this.updatePhone}
                    />
                  </Form.Item>
                  <Form.Item
                    name={["user", "address"]}
                    label="Flat, House No., Building, Company, Apartment :"
                    rules={[{ required: true }]}
                    style={{ marginRight: "2%" }}
                  >
                    <Input.TextArea
                      value={this.state.address}
                      onChange={this.updateAddress}
                    />
                  </Form.Item>

                  <Row style={{ textAlign: "center" }}>
                    <Col span={12}>
                      <Form.Item
                        label="Country"
                        value={this.state.country}
                        rules={[{ city: true }]}
                      >
                        <Select
                          defaultValue="-select-"
                          style={{ width: 120 }}
                          onChange={this.handleCountry}
                        >
                          <Option value="België">
                            <Tooltip placement="left" title="België">
                              <span>België</span>
                            </Tooltip>
                          </Option>
                          <Option value="Nederland">
                            <Tooltip placement="left" title="Nederland">
                              <span>Nederland</span>
                            </Tooltip>
                          </Option>
                          <Option value="Luxembourg">
                            <Tooltip placement="left" title="Luxembourg">
                              <span>Luxembourg</span>
                            </Tooltip>
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Province :"
                        value={this.state.city}
                        rules={[{ city: true }]}
                      >
                        <Select
                          defaultValue="-select-"
                          style={{ width: 200 }}
                          onChange={this.handleCity}
                        >
                          {this.handleCityOptions(this.state.country)}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col style={{ textAlign: "center" }} span={24}>
                      <Form.Item label="Postal Code :">
                        <Input
                          type="number"
                          value={this.state.pinCode}
                          style={{ width: "150px" }}
                          onChange={(e) =>
                            this.setState({ pinCode: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item style={{ textAlign: "center" }}>
                    <Button
                      className="form-submit"
                      type="primary"
                      // htmlType="submit"
                      onClick={() =>
                        message.success(
                          "Please Call Us to Order +32 (0) 486 68 70 68"
                        )
                      }
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            )}
          </Row>
        ) : null}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user, isLoggedIn) =>
    dispatch(setCurrentUser(user, isLoggedIn)),
});

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
  isLoggedIn: user.isLoggedIn,
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);

import React, { Component } from 'react'

class PaymentFalied extends Component {
    render() {
        return (
            <div className="text-center">
                <h1>
                    Payment Failed
            </h1>
            </div>
        )
    }
}
export default PaymentFalied

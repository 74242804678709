import React, { Component } from 'react'
import { Row, Col, Breadcrumb, notification } from 'antd'
import './style/Productview.css'
import {
  Card,
  Button,
  Typography,
  Menu,
  Slider,
  Drawer,
  Checkbox,
  Dropdown,
  Skeleton,
} from 'antd'
import axios from 'axios'
import { connect } from 'react-redux'
import { FilterOutlined, SortAscendingOutlined } from '@ant-design/icons'
import Cookies from 'js-cookie'
import Footer from '../../Constant/Footer'
const { Meta } = Card
const { SubMenu } = Menu
const { Title } = Typography

export class Productview extends Component {
  state = {
    rating: 4,
    latestProduct: [],
    goldmakingcharge: [],
    silvermakingcharge: [],
    goldPricecurrent: '',
    silverPricecurrent: '',
    silverAdditionalPrice1: '',
    goldAdditionalPrice1: '',
    singleProduct: [],
    cart: [],
    visible: false,
    placement: 'left',
    highPrice: null,
    lowPrice: null,
    filter: false,
    priceRangeLow: 0,
    priceRangeHigh: 0,
    weights: [],
    wishlists: '',
    sortState: 1, //0 is for ascending and 1 for descending
    loading: true,
    webShopStatus: true,
  }
  componentDidMount() {
    this.setState({
      wishlists: Cookies.get('wishlist'),
    })
    //API for web shop
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/web-shop/status`)
      // .get(`${process.env.REACT_APP_BACKEND_URL}/api/web-shop/status`)
      .then((res) => {
        const data = res.data.data
        this.setState({
          webShopStatus: data,
        })
      })
      .catch(function (error) {
        console.log(error)
      })
    // API for making charges for gold
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/making-charge-gold/all-making-gold`
      )
      .then((response) => {
        var goldmakingchargedata = response.data.data
        goldmakingchargedata.reverse()
        const goldmakingcharge = goldmakingchargedata[0]
        this.setState({ goldmakingchargedata, goldmakingcharge })
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
    // API for making charges for silver
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/making-charge-silver/all-making-silver`
      )
      .then((response) => {
        var silvermakingchargedata = response.data.data
        silvermakingchargedata.reverse()
        const silvermakingcharge = silvermakingchargedata[0]
        this.setState({ silvermakingchargedata, silvermakingcharge })
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })

    //API TO GET GOLD PRICE HISTORY
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/gold/all-gold`)
      .then((response) => {
        const goldPriceHistory = response.data.data.reverse()
        const goldPricecurrent = goldPriceHistory[0].goldprice
        this.setState({
          goldPricecurrent,
        })
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
    //API TO GET GOLD PRICE HISTORY
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/silver/all-silver`)
      .then((response) => {
        const silverPriceHistory = response.data.data.reverse()
        const silverPricecurrent = silverPriceHistory[0].silverprice
        this.setState({
          silverPricecurrent,
        })
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
    //API TO GET GOLD Additional PRICE HISTORY
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/additional-gold/all`)
      .then((response) => {
        const goldAdditionalPriceHistory = response.data.data.reverse()
        const goldAdditionalPrice1 =
          goldAdditionalPriceHistory[0].additionalprice
        const goldAdditionalPrice2 =
          goldAdditionalPriceHistory[1].additionalprice
        const goldAdditionalPrice3 =
          goldAdditionalPriceHistory[2].additionalprice
        const goldAdditionalDate1 = goldAdditionalPriceHistory[0].updatedAt
        this.setState({
          goldAdditionalPriceHistory,
          goldAdditionalPrice1,
          goldAdditionalPrice2,
          goldAdditionalPrice3,
          goldAdditionalDate1,
        })
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
    //API TO GET SILVER Additional PRICE HISTORY
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/additional-silver/all`)
      .then((response) => {
        const silverAdditionalPriceHistory = response.data.data.reverse()
        const silverAdditionalPrice1 =
          silverAdditionalPriceHistory[0].additionalprice
        const silverAdditionalPrice2 =
          silverAdditionalPriceHistory[1].additionalprice
        const silverAdditionalPrice3 =
          silverAdditionalPriceHistory[2].additionalprice
        const silverAdditionalDate1 = silverAdditionalPriceHistory[0].updatedAt
        this.setState({
          silverAdditionalPriceHistory,
          silverAdditionalPrice1,
          silverAdditionalPrice2,
          silverAdditionalPrice3,
          silverAdditionalDate1,
        })
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })

    axios
      .get(`${process.env.REACT_APP_API_URL}/api/product/all-product`)
      .then((res) => {
        const data = res.data
        const products = data.data
        var prices = []
        products.map((product) => {
          if (
            this.props.match.params.category.toUpperCase() ===
            product.category.toUpperCase()
          ) {
            prices.push(this.livePricegold(product))
          }
          return null
        })
        var hPrice = Math.max(...prices)
        var lPrice = Math.min(...prices)
        this.setState({
          highPrice: hPrice,
          lowPrice: lPrice,
          priceRangeHigh: hPrice,
        })
        var latestProduct = products.reverse()
        const singleProduct = latestProduct
        this.setState({ latestProduct, products, singleProduct })
        this.setState({
          loading: false,
        })
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    })
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
  }

  onChange = (e) => {
    this.setState({
      placement: e.target.value,
    })
  }

  handlePriceSlider = (event) => {
    this.setState({
      priceRangeHigh: event[1],
      priceRangeLow: event[0],
    })
  }

  checkboxHandle = (event) => {
    if (this.state.weights.includes(parseInt(event.target.value))) {
      var filteredWeights = this.state.weights.filter(function (value) {
        return value !== parseInt(event.target.value)
      })
      filteredWeights = filteredWeights.map((weight) => parseInt(weight))
      this.setState({
        weights: filteredWeights,
      })
    } else {
      var filteredWeights = this.state.weights
      filteredWeights.push(event.target.value)
      filteredWeights = filteredWeights.map((weight) => parseInt(weight))
      this.setState({
        weights: filteredWeights,
      })
    }
  }

  handleWishlist = (event) => {
    if (this.state.wishlists === undefined) {
      var wishList = []
      wishList.push(event.currentTarget.value)
      Cookies.set('wishlist', JSON.stringify(wishList), { expires: 7 })
      this.setState({
        wishlists: JSON.stringify(wishList),
      })
    } else {
      wishList = JSON.parse(this.state.wishlists)
      wishList.push(event.currentTarget.value)
      Cookies.set('wishlist', JSON.stringify(wishList), { expires: 7 })
      this.setState({
        wishlists: JSON.stringify(wishList),
      })
    }
    const btn = (
      <Button type="link" size="small" onClick={this.handleOpenWishList}>
        Open Wish List
      </Button>
    )

    notification.success({
      message: `Product Added to wishlist`,
      btn,
    })
  }
  handleOpenWishList = () => {
    window.location.href = '/wishlist'
  }
  removeWishlist = (event) => {
    var wishlists = JSON.parse(this.state.wishlists)
    wishlists.splice(wishlists.indexOf(event.currentTarget.value), 1)
    Cookies.set('wishlist', JSON.stringify(wishlists), { expires: 7 })
    this.setState({
      wishlists: JSON.stringify(wishlists),
    })
    notification.error({
      message: `Product removed from wishlist`,
    })
  }

  handleFilter = (event) => {
    event.preventDefault()
    this.setState({
      filter: true,
    })
  }

  handleReset = (event) => {
    event.preventDefault()
    this.setState({
      filter: false,
      priceRangeHigh: this.state.highPrice,
      priceRangeLow: this.state.lowPrice,
      weights: [],
    })
  }
  livePricegold = (product) => {
    if (product.category === 'Gold') {
      switch (product.weight) {
        case 1:
          return Math.round(
            (this.state.goldPricecurrent * 1 +
              this.state.goldmakingcharge.one) *
              this.state.goldAdditionalPrice1
          )
        case 5:
          return Math.round(
            (this.state.goldPricecurrent * 5 +
              this.state.goldmakingcharge.five) *
              this.state.goldAdditionalPrice1
          )
        case 10:
          return Math.round(
            (this.state.goldPricecurrent * 10 +
              this.state.goldmakingcharge.ten) *
              this.state.goldAdditionalPrice1
          )
        case 20:
          return Math.round(
            (this.state.goldPricecurrent * 20 +
              this.state.goldmakingcharge.twenty) *
              this.state.goldAdditionalPrice1
          )
        case 50:
          return Math.round(
            (this.state.goldPricecurrent * 50 +
              this.state.goldmakingcharge.fifty) *
              this.state.goldAdditionalPrice1
          )
        case 100:
          return Math.round(
            (this.state.goldPricecurrent * 100 +
              this.state.goldmakingcharge.onehundred) *
              this.state.goldAdditionalPrice1
          )
        case 250:
          return Math.round(
            (this.state.goldPricecurrent * 250 +
              this.state.goldmakingcharge.twohundredfifty) *
              this.state.goldAdditionalPrice1
          )
        case 500:
          return Math.round(
            (this.state.goldPricecurrent * 500 +
              this.state.goldmakingcharge.fivehundred) *
              this.state.goldAdditionalPrice1
          )
        case 1000:
          return Math.round(
            (this.state.goldPricecurrent * 1000 +
              this.state.goldmakingcharge.onethousand) *
              this.state.goldAdditionalPrice1
          )
        default:
          return null
      }
    } else if (product.category === 'Silver') {
      switch (product.weight) {
        case 1:
          return (
            (Math.round(
              (this.state.silverPricecurrent * 1 +
                this.state.silvermakingcharge.one) *
                this.state.silverAdditionalPrice1
            ) *
              21) /
            100
          )
        case 5:
          return (
            (Math.round(
              (this.state.silverPricecurrent * 5 +
                this.state.silvermakingcharge.five) *
                this.state.silverAdditionalPrice1
            ) *
              21) /
              100 +
            Math.round(
              (this.state.silverPricecurrent * 5 +
                this.state.silvermakingcharge.five) *
                this.state.silverAdditionalPrice1
            )
          )
        case 10:
          return (
            (Math.round(
              (this.state.silverPricecurrent * 10 +
                this.state.silvermakingcharge.ten) *
                this.state.silverAdditionalPrice1
            ) *
              21) /
              100 +
            Math.round(
              (this.state.silverPricecurrent * 10 +
                this.state.silvermakingcharge.ten) *
                this.state.silverAdditionalPrice1
            )
          )
        case 20:
          return (
            (Math.round(
              (this.state.silverPricecurrent * 20 +
                this.state.silvermakingcharge.twenty) *
                this.state.silverAdditionalPrice1
            ) *
              21) /
              100 +
            Math.round(
              (this.state.silverPricecurrent * 20 +
                this.state.silvermakingcharge.twenty) *
                this.state.silverAdditionalPrice1
            )
          )
        case 50:
          return (
            (Math.round(
              (this.state.silverPricecurrent * 50 +
                this.state.silvermakingcharge.fifty) *
                this.state.silverAdditionalPrice1
            ) *
              21) /
              100 +
            Math.round(
              (this.state.silverPricecurrent * 50 +
                this.state.silvermakingcharge.fifty) *
                this.state.silverAdditionalPrice1
            )
          )
        case 100:
          return (
            (Math.round(
              (this.state.silverPricecurrent * 100 +
                this.state.silvermakingcharge.onehundred) *
                this.state.silverAdditionalPrice1
            ) *
              21) /
              100 +
            Math.round(
              (this.state.silverPricecurrent * 100 +
                this.state.silvermakingcharge.onehundred) *
                this.state.silverAdditionalPrice1
            )
          )
        case 250:
          return (
            (Math.round(
              (this.state.silverPricecurrent * 250 +
                this.state.silvermakingcharge.twohundredfifty) *
                this.state.silverAdditionalPrice1
            ) *
              21) /
              100 +
            Math.round(
              (this.state.silverPricecurrent * 250 +
                this.state.silvermakingcharge.twohundredfifty) *
                this.state.silverAdditionalPrice1
            )
          )
        case 500:
          return (
            (Math.round(
              (this.state.silverPricecurrent * 500 +
                this.state.silvermakingcharge.fivehundred) *
                this.state.silverAdditionalPrice1
            ) *
              21) /
              100 +
            Math.round(
              (this.state.silverPricecurrent * 500 +
                this.state.silvermakingcharge.fivehundred) *
                this.state.silverAdditionalPrice1
            )
          )
        case 1000:
          return (
            (Math.round(
              (this.state.silverPricecurrent * 1000 +
                this.state.silvermakingcharge.onethousand) *
                this.state.silverAdditionalPrice1
            ) *
              21) /
              100 +
            Math.round(
              (this.state.silverPricecurrent * 1000 +
                this.state.silvermakingcharge.onethousand) *
                this.state.silverAdditionalPrice1
            )
          )
        default:
          return null
      }
    } else if (product.category === 'Diamond') {
      return product.price
    } else if (product.category === 'others') {
      return product.price
    } else {
      return product.price
    }
  }

  SortAscending = () => {
    var sortedProducts = this.state.latestProduct
    sortedProducts = sortedProducts.sort((a, b) => {
      if (this.livePricegold(a) > this.livePricegold(b)) return 1
      if (this.livePricegold(b) > this.livePricegold(a)) return -1
      return 0
    })
    this.setState({
      latestProduct: sortedProducts,
      sortState: 0,
    })
  }

  SortDescending = () => {
    var sortedProducts = this.state.latestProduct
    sortedProducts = sortedProducts.sort((a, b) => {
      if (this.livePricegold(a) > this.livePricegold(b)) return 1
      if (this.livePricegold(b) > this.livePricegold(a)) return -1
      return 0
    })
    this.setState({
      latestProduct: sortedProducts.reverse(),
      sortState: 1,
    })
  }

  SortNewest = () => {
    var sortedProducts = this.state.latestProduct
    sortedProducts = sortedProducts.sort((a, b) => {
      var dateA = new Date(a.updatedAt)
      var dateB = new Date(b.updatedAt)
      if (dateA.getTime() > dateB.getTime()) return 1
      if (dateA.getTime() < dateB.getTime()) return -1
      return 0
    })
    this.setState({
      latestProduct: sortedProducts,
      sortState: 0,
    })
  }

  addToCart = (event) => {
    if (this.props.currentUser) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/cart/register`, {
          userid: `${this.props.currentUser.id}`,
          productid: `${event.currentTarget.value}`,
          quantity: 1,
        })
        .then((response) => {})
        .catch(function (error) {
          // handle error
          console.log(error)
        })
    } else {
      notification.info({ message: 'Login to add this product to you cart' })
    }
  }

  numberWithCommas = (x) => {
    if (x != null) {
      x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }

  replaceCommaAndDecimal = (stringData) => {
    var str = stringData
    if (stringData != null) {
      str = str.replace(/.|,/g, (v) => {
        if (v === '.') {
          return ','
        } else if (v === ',') {
          return '.'
        } else {
          return v
        }
      })
      return str
    }
  }

  addZeroes = (num) => {
    const dec = num.toString().split('.')[1]
    const len = dec && dec.length > 2 ? dec.length : 2
    return Number(num).toFixed(len)
  }

  render() {
    var productToView = []
    var productViewExact = []
    var wishLists = this.state.wishlists ? JSON.parse(this.state.wishlists) : []
    if (this.state.filter) {
      this.state.latestProduct.map((product) => {
        if (
          this.livePricegold(product) <= this.state.priceRangeHigh &&
          this.livePricegold(product) >= this.state.priceRangeLow
        ) {
          if (this.state.weights.length > 0) {
            if (this.state.weights.includes(Math.floor(product.weight))) {
              productToView.push(product)
            }
          } else {
            productToView.push(product)
          }
        }
        return null
      })
    } else {
      productToView = this.state.latestProduct
    }

    if (this.props.match.params.category.toUpperCase() === 'ALL') {
      productViewExact = productToView
    } else if (this.props.match.params.category.toUpperCase() === 'OTHER') {
      productToView.map((product) => {
        if (
          product.category.toUpperCase() === 'GOLD' ||
          product.category.toUpperCase() === 'SILVER'
        ) {
          return null
        }
        productViewExact.push(product)
        return null
      })
    } else {
      productToView.map((product) => {
        if (
          product.category.toUpperCase() ===
          this.props.match.params.category.toUpperCase()
        ) {
          productViewExact.push(product)
          return null
        }
        return null
      })
    }
    const marksPrice = {
      [this.state.lowPrice]: {
        style: { color: '#ad9b61' },
        label: `€${Math.floor(this.state.lowPrice) + 1}`,
      },
      [this.state.highPrice]: {
        style: {
          color: '#ad9b61',
        },
        label: <strong>€{Math.floor(this.state.highPrice) + 1}</strong>,
      },
    }

    const { placement, visible } = this.state

    const menu = (
      <Menu>
        <Menu.Item>
          <button
            onClick={this.SortDescending}
            style={{ border: 'none', background: 'none' }}
          >
            Price: High to Low
          </button>
        </Menu.Item>
        <Menu.Item>
          <button
            onClick={this.SortAscending}
            style={{ border: 'none', background: 'none' }}
          >
            Price: Low to High
          </button>
        </Menu.Item>
        <Menu.Item>
          <button
            onClick={this.SortNewest}
            style={{ border: 'none', background: 'none' }}
          >
            Most Recent Product
          </button>
        </Menu.Item>
      </Menu>
    )
    return (
      <>
        <div className="container breadcrum-product">
          <Breadcrumb>
            <Breadcrumb.Item>
              <a href="/">HOME</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {this.props.match.params.category.toUpperCase()}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="container">
          <div className="product-view-root" style={{ overflowX: 'hidden' }}>
            <Drawer
              title=""
              placement={placement}
              closable={true}
              onClose={this.onClose}
              visible={visible}
              key={placement}
            >
              <Typography.Title level={2}>Filters</Typography.Title>s
              <Menu
                onClick={this.handleClick}
                style={{ width: '100%' }}
                mode="inline"
              >
                <SubMenu key="sub1" title={<h1>Price </h1>}>
                  <Slider
                    range
                    style={{
                      marginLeft: 30,
                      marginRight: 30,
                    }}
                    marks={marksPrice}
                    onChange={this.handlePriceSlider}
                    defaultValue={[this.state.lowPrice, this.state.highPrice]}
                    max={this.state.highPrice}
                    min={this.state.lowPrice}
                  />
                </SubMenu>
                <SubMenu key="sub2" title={<h1>Weight</h1>}>
                  <Checkbox
                    checked={this.state.weights.includes(1000) ? true : false}
                    value={1000}
                    onClick={this.checkboxHandle}
                    style={{ marginLeft: '10%', marginBottom: '2%' }}
                  >
                    1Kg
                  </Checkbox>
                  <br />
                  <Checkbox
                    checked={this.state.weights.includes(500) ? true : false}
                    value={500}
                    onClick={this.checkboxHandle}
                    style={{ marginLeft: '10%', marginBottom: '2%' }}
                  >
                    500gm
                  </Checkbox>
                  <br />
                  <Checkbox
                    checked={this.state.weights.includes(250) ? true : false}
                    value={250}
                    onClick={this.checkboxHandle}
                    style={{ marginLeft: '10%', marginBottom: '2%' }}
                  >
                    250gm
                  </Checkbox>
                  <br />
                  <Checkbox
                    checked={this.state.weights.includes(100) ? true : false}
                    value={100}
                    onClick={this.checkboxHandle}
                    style={{ marginLeft: '10%', marginBottom: '2%' }}
                  >
                    100gm
                  </Checkbox>
                  <br />
                  <Checkbox
                    checked={this.state.weights.includes(50) ? true : false}
                    value={50}
                    onClick={this.checkboxHandle}
                    style={{ marginLeft: '10%', marginBottom: '2%' }}
                  >
                    50gm
                  </Checkbox>
                  <br />
                  <Checkbox
                    checked={this.state.weights.includes(20) ? true : false}
                    value={20}
                    onClick={this.checkboxHandle}
                    style={{ marginLeft: '10%', marginBottom: '2%' }}
                  >
                    20gm
                  </Checkbox>
                  <br />
                  <Checkbox
                    checked={this.state.weights.includes(10) ? true : false}
                    value={10}
                    onClick={this.checkboxHandle}
                    style={{ marginLeft: '10%', marginBottom: '2%' }}
                  >
                    10gm
                  </Checkbox>
                  <br />
                  <Checkbox
                    checked={this.state.weights.includes(5) ? true : false}
                    value={5}
                    onClick={this.checkboxHandle}
                    style={{ marginLeft: '10%', marginBottom: '2%' }}
                  >
                    5gm
                  </Checkbox>
                  <br />
                  <Checkbox
                    checked={this.state.weights.includes(1) ? true : false}
                    value={1}
                    onClick={this.checkboxHandle}
                    style={{ marginLeft: '10%', marginBottom: '2%' }}
                  >
                    1gm
                  </Checkbox>
                  <br />
                  <Checkbox
                    checked={this.state.weights.includes(28) ? true : false}
                    value={28}
                    onClick={this.checkboxHandle}
                    style={{ marginLeft: '10%', marginBottom: '2%' }}
                  >
                    1oz
                  </Checkbox>
                  <br />
                  <Checkbox
                    checked={this.state.weights.includes(14) ? true : false}
                    value={14}
                    onClick={this.checkboxHandle}
                    style={{ marginLeft: '10%', marginBottom: '2%' }}
                  >
                    0.5oz
                  </Checkbox>
                  <br />
                  <Checkbox
                    checked={this.state.weights.includes(7) ? true : false}
                    value={7}
                    onClick={this.checkboxHandle}
                    style={{ marginLeft: '10%', marginBottom: '2%' }}
                  >
                    0.25oz
                  </Checkbox>
                  <br />
                  <Checkbox
                    checked={this.state.weights.includes(2) ? true : false}
                    value={2}
                    onClick={this.checkboxHandle}
                    style={{ marginLeft: '10%', marginBottom: '2%' }}
                  >
                    0.10oz
                  </Checkbox>
                  <br />
                </SubMenu>
              </Menu>
              <button className="filter-apply-btn" onClick={this.handleFilter}>
                Apply
              </button>
              <button className="reset-apply-btn" onClick={this.handleReset}>
                Reset
              </button>
            </Drawer>
            <div className="second-header">
              <Row gutter={26} align="middle" justify="center">
                <Col className="filter-option" span={6}>
                  <Button
                    onClick={this.showDrawer}
                    style={{ display: 'inline-block' }}
                    type="text"
                    icon={<FilterOutlined />}
                  />

                  <Title style={{ display: 'inline-block' }} level={5}>
                    Filter
                  </Title>
                </Col>
                <Col className="sort-option" span={6}>
                  <Dropdown overlay={menu} placement="bottomCenter">
                    <div style={{ cursor: 'pointer' }}>
                      <div className="sort-box">
                        <Button
                          type="text"
                          style={{ display: 'inline-block' }}
                          icon={<SortAscendingOutlined />}
                        />
                        <Title style={{ display: 'inline-block' }} level={5}>
                          Sort
                        </Title>
                      </div>
                    </div>
                  </Dropdown>
                </Col>
              </Row>
            </div>
            <Row
              gutter={{ xs: 8, sm: 16, md: 24 }}
              className="product-view"
              style={{}}
            >
              <Col
                xs={0}
                sm={0}
                md={0}
                lg={6}
                style={{
                  overflowX: 'hidden',
                  padding: '2%',
                  borderRight: '1px solid #f0f0f0',
                }}
                className="side-filter"
              >
                <div className="text-center filter-text">
                  <h2>Filters</h2>
                </div>

                <Menu
                  onClick={this.handleClick}
                  style={{ width: '100%' }}
                  mode="inline"
                >
                  <SubMenu key="sub1" title={<h2>Price</h2>}>
                    <Slider
                      range
                      style={{ marginLeft: 30, marginRight: 30 }}
                      marks={marksPrice}
                      onChange={this.handlePriceSlider}
                      defaultValue={[this.state.lowPrice, this.state.highPrice]}
                      max={this.state.highPrice}
                      min={this.state.lowPrice}
                    />
                  </SubMenu>
                  <SubMenu key="sub2" title={<h2>Weight</h2>}>
                    <Checkbox
                      checked={this.state.weights.includes(1000) ? true : false}
                      value={1000}
                      onClick={this.checkboxHandle}
                      style={{ marginLeft: '10%', marginBottom: '2%' }}
                    >
                      1Kg
                    </Checkbox>
                    <br />
                    <Checkbox
                      checked={this.state.weights.includes(500) ? true : false}
                      value={500}
                      onClick={this.checkboxHandle}
                      style={{ marginLeft: '10%', marginBottom: '2%' }}
                    >
                      500gm
                    </Checkbox>
                    <br />
                    <Checkbox
                      checked={this.state.weights.includes(250) ? true : false}
                      value={250}
                      onClick={this.checkboxHandle}
                      style={{ marginLeft: '10%', marginBottom: '2%' }}
                    >
                      250gm
                    </Checkbox>
                    <br />
                    <Checkbox
                      checked={this.state.weights.includes(100) ? true : false}
                      value={100}
                      onClick={this.checkboxHandle}
                      style={{ marginLeft: '10%', marginBottom: '2%' }}
                    >
                      100gm
                    </Checkbox>
                    <br />
                    <Checkbox
                      checked={this.state.weights.includes(50) ? true : false}
                      value={50}
                      onClick={this.checkboxHandle}
                      style={{ marginLeft: '10%', marginBottom: '2%' }}
                    >
                      50gm
                    </Checkbox>
                    <br />
                    <Checkbox
                      checked={this.state.weights.includes(20) ? true : false}
                      value={20}
                      onClick={this.checkboxHandle}
                      style={{ marginLeft: '10%', marginBottom: '2%' }}
                    >
                      20gm
                    </Checkbox>
                    <br />
                    <Checkbox
                      checked={this.state.weights.includes(10) ? true : false}
                      value={10}
                      onClick={this.checkboxHandle}
                      style={{ marginLeft: '10%', marginBottom: '2%' }}
                    >
                      10gm
                    </Checkbox>
                    <br />
                    <Checkbox
                      checked={this.state.weights.includes(5) ? true : false}
                      value={5}
                      onClick={this.checkboxHandle}
                      style={{ marginLeft: '10%', marginBottom: '2%' }}
                    >
                      5gm
                    </Checkbox>
                    <br />
                    <Checkbox
                      checked={this.state.weights.includes(1) ? true : false}
                      value={1}
                      onClick={this.checkboxHandle}
                      style={{ marginLeft: '10%', marginBottom: '2%' }}
                    >
                      1gm
                    </Checkbox>
                    <br />
                    <Checkbox
                      checked={this.state.weights.includes(28) ? true : false}
                      value={28}
                      onClick={this.checkboxHandle}
                      style={{ marginLeft: '10%', marginBottom: '2%' }}
                    >
                      1oz
                    </Checkbox>
                    <br />
                    <Checkbox
                      checked={this.state.weights.includes(14) ? true : false}
                      value={14}
                      onClick={this.checkboxHandle}
                      style={{ marginLeft: '10%', marginBottom: '2%' }}
                    >
                      0.5oz
                    </Checkbox>
                    <br />
                    <Checkbox
                      checked={this.state.weights.includes(7) ? true : false}
                      value={7}
                      onClick={this.checkboxHandle}
                      style={{ marginLeft: '10%', marginBottom: '2%' }}
                    >
                      0.25oz
                    </Checkbox>
                    <br />
                    <Checkbox
                      checked={this.state.weights.includes(2) ? true : false}
                      value={2}
                      onClick={this.checkboxHandle}
                      style={{ marginLeft: '10%', marginBottom: '2%' }}
                    >
                      0.10oz
                    </Checkbox>
                    <br />
                  </SubMenu>
                </Menu>
                <div className="filter">
                  <button
                    className="filter-apply-btn"
                    onClick={this.handleFilter}
                    block
                  >
                    Apply
                  </button>
                  <button
                    className="reset-apply-btn"
                    onClick={this.handleReset}
                    block
                  >
                    Reset
                  </button>
                </div>
              </Col>

              <Col
                xs={24}
                sm={24}
                md={24}
                lg={18}
                style={{ margin: '0', padding: '0' }}
              >
                {this.state.loading ? (
                  <Row gutter={16}>
                    <Col
                      style={{ marginTop: '5%' }}
                      xs={24}
                      sm={24}
                      lg={8}
                      md={6}
                    >
                      <Card>
                        <Skeleton active loading />
                      </Card>
                    </Col>

                    <Col
                      style={{ marginTop: '5%' }}
                      xs={24}
                      sm={24}
                      lg={8}
                      md={6}
                    >
                      <Card>
                        <Skeleton active loading />
                      </Card>
                    </Col>

                    <Col
                      style={{ marginTop: '5%' }}
                      xs={24}
                      sm={24}
                      lg={8}
                      md={6}
                    >
                      <Card>
                        <Skeleton active loading />
                      </Card>
                    </Col>

                    <Col
                      style={{ marginTop: '5%' }}
                      xs={24}
                      sm={24}
                      lg={8}
                      md={6}
                    >
                      <Card>
                        <Skeleton active loading />
                      </Card>
                    </Col>

                    <Col
                      style={{ marginTop: '5%' }}
                      xs={24}
                      sm={24}
                      lg={8}
                      md={6}
                    >
                      <Card>
                        <Skeleton active loading />
                      </Card>
                    </Col>

                    <Col
                      style={{ marginTop: '5%' }}
                      xs={24}
                      sm={24}
                      lg={8}
                      md={6}
                    >
                      <Card>
                        <Skeleton active loading />
                      </Card>
                    </Col>
                  </Row>
                ) : (
                  <Row justify="center" className="product-list-card">
                    {productViewExact.map((product) => (
                      <Col
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={8}
                        style={{ textAlign: 'center' }}
                      >
                        <div
                          style={{
                            display: 'inline-flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Card
                            className="product-card"
                            cover={
                              <div className="product-card-anchor">
                                <a
                                  style={{ margin: '4px' }}
                                  href={`/product/${product._id}`}
                                >
                                  <img
                                    alt="example"
                                    src={product.imageURL1}
                                    style={{
                                      maxWidth: '280px',
                                      maxHeight: '280px',
                                    }}
                                    className="product-image-card"
                                  />
                                </a>
                              </div>
                            }
                            actions={[
                              <button
                                value={product._id}
                                className="product-view-wishlist"
                                onClick={
                                  wishLists.includes(product._id)
                                    ? this.removeWishlist
                                    : this.handleWishlist
                                }
                              >
                                {wishLists.includes(product._id)
                                  ? 'Added to Wishlist'
                                  : 'Add to Wishlist'}
                              </button>,
                              <a href={`/product/${product._id}`}>
                                {this.state.webShopStatus ? (
                                  <>
                                    {' '}
                                    <button
                                      value={product._id}
                                      onClick={this.addToCart}
                                      className="product-view-buynow"
                                    >
                                      Buy Now
                                    </button>
                                  </>
                                ) : null}
                                ,
                              </a>,
                            ]}
                          >
                            <Meta
                              className="card-hover"
                              title={`€ ${this.replaceCommaAndDecimal(
                                // this.numberWithCommas(
                                this.addZeroes(this.livePricegold(product))
                                // )
                              )}`}
                              description={product.title}
                            />
                          </Card>
                        </div>
                      </Col>
                    ))}
                  </Row>
                )}
              </Col>
            </Row>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
  isLoggedIn: user.isLoggedIn,
})

export default connect(mapStateToProps)(Productview)

import React, { Component } from 'react'
import blogImg from "../Component/Blog.jpeg"
import Footer from "../Constant/Footer"
import axios from "axios"
import { Spin } from 'antd';

class Blog extends Component {
    state = {
        blogData: null,
        title: null,
        featuredImg: null
    }
    componentDidMount() {
        const url = window.location.href;
        const blog_id = url.split("/");


        axios
            .get(`${process.env.REACT_APP_BACKEND_URL}/api/blog/${blog_id[4]}`)
            .then((res) => {
                const blogData = res.data.data
                const featuredImg = res.data.data.featuredImg
                const title = res.data.data.title
                this.setState({ blogData: blogData, featuredImg: featuredImg, title: title });
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });

    }
    render() {
        return (<>
            {
                this.state.blogData != null
                    ?
                    <>
                        <div>
                            <div style={{ textAlign: 'center', fontSize: '28px', fontWeight: '800' }}>
                                <h2 >{this.state.title}</h2>
                            </div>
                            {this.state.featuredImg == "" ? null : <img
                                style={{ width: '100%', padding: '50px' }}
                                src={this.state.featuredImg}
                                // {this.state.featuredImg}
                                alt="Direct Gold" />}
                            {this.state.blogData != null ? <div style={{ padding: '0 50px', fontSize: '18px', marginBottom: '100px' }} dangerouslySetInnerHTML={{ __html: `${this.state.blogData.data}` }} /> : null}
                        </div>
                        <Footer /></>
                    :
                    <div style={{ textAlign: 'center', margin: '200px' }}>
                        <Spin size="large" />
                    </div>
            }

        </>

        )
    }
}
export default Blog
import React, { useEffect } from "react";
import Header from "./Constant/Header";
import HomePage from "./Component/Home/HomePage";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { setCurrentUser } from "./redux/user/user.actions";
import Productview from "./Component/Product/Productview";
import ProductSearch from "./Component/Product/ProductSearch";
import NotLogged from "./Component/notLogged";
import ProtectedRoute from "./Component/ProtectedRoute";
import Product from "./Component/Product/Product";
import Wishlist from "./Component/wishlist";
import Account from "./Component/account";
import Orders from "./Component/Orders";
import Cart from "./Component/cart";
import About from "./Constant/Aboutus";
import "./App.css";
import Contactus from "./Constant/Contactus";
import FAQ from "./Constant/FAQ";
import NotFoundPage from "./Constant/NotFoundPage";
import GeneralCondition from "./Constant/GeneralCondition";
import Return from "./Constant/Return";
import Privacy from "./Constant/Privacy";
import GiftCard from "./Constant/GiftCard";
import Checkout from "./Component/Product/Checkout";
// import Invoice from "./Component/Product/Invoice";
import GA from "./utils/GoogleAnalytics";
import ReactGa from "react-ga";
import ForgotPassword from "./Constant/ForgotPassword";
import PaymentSuccess from "./Payment/PaymentSuccess";
import PaymentFalied from "./Payment/PaymentFalied";
import PartnerWithUs from "./Constant/PartnerWithUs";
import Blog from "./Component/Blog";
import InvoicePrint from "./Component/InvoicePrint";
import TermsConditions from "./Constant/TermsConditions";

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      user: localStorage.getItem("token")
        ? localStorage.getItem("token")
        : null,
    };
    if (localStorage.getItem("token")) {
      const isLoggedIn = true;
      props.setCurrentUser(
        JSON.parse(localStorage.getItem("token")),
        isLoggedIn
      );
    } else {
      const isLoggedIn = false;
      props.setCurrentUser(null, isLoggedIn);
    }
  }

  useEffect =
    (() => {
      ReactGa.initalize("UA-180919338-1");
      ReactGa.pageview("/");
    },
    []);

  render() {
    return (
      <div className="App" style={{ overflowX: "hidden" }}>
        <Header />
        <Router>
          {GA.init() && <GA.RouteTracker />}
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/payment/success" component={PaymentSuccess} />
            <Route exact path="/payment/failed" component={PaymentFalied} />
            <Route path="/product/:id" component={Product} />
            <Route path="/blog/:id" component={Blog} />
            <Route path="/gift-card" component={GiftCard} />
            <Route path="/productview/:category" component={Productview} />
            <Route
              path="/productsearch/:searchquery"
              component={ProductSearch}
            />
            <Route path="/login" component={NotLogged} />
            <Route path="/wishlist" component={Wishlist} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/terms-conditions" component={TermsConditions} />
            <ProtectedRoute
              path="/account"
              component={Account}
              user={this.state.user}
            />
            {/* <ProtectedRoute
              path="/checkout"
              component={Checkout}
              user={this.state.user}
            /> */}
            {/* <ProtectedRoute
              path="/invoice"
              component={InvoicePrint}
              user={this.state.user}
            /> */}
            <ProtectedRoute
              path="/your-cart"
              user={this.state.user}
              component={Cart}
            />
            <ProtectedRoute
              path="/orders"
              component={Orders}
              user={this.state.user}
            />
            <Route path="/about" component={About} />
            <Route path="/contact" component={Contactus} />
            <Route path="/faq" component={FAQ} />
            <Route path="/general-conditions" component={GeneralCondition} />
            <Route path="/returns-refunds" component={Return} />
            <Route path="/privacy-policy" component={Privacy} />\
            <Route path="/partner" component={PartnerWithUs} />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </Router>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user, isLoggedIn) =>
    dispatch(setCurrentUser(user, isLoggedIn)),
});

export default connect(null, mapDispatchToProps)(App);

import React from "react";
import Cookies from "js-cookie";
import { List, Avatar, Typography, Button, Skeleton, Empty } from "antd";
import { DeleteOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import axios from "axios";
import Footer from '../Constant/Footer'
import "./wishlist.css";

const { Title } = Typography;

class Wishlist extends React.Component {
  constructor() {
    super();
    this.state = {
      wishlists: Cookies.get("wishlist")
        ? JSON.parse(Cookies.get("wishlist"))
        : [],
      products: [],
      loading: true,
    };
  }

  componentDidMount() {
    if (this.state.wishlists.length > 0) {
      this.state.wishlists.map((wishlist) => {
        axios
          .get(`${process.env.REACT_APP_API_URL}/api/product/${wishlist}`)
          .then((res) => {
            this.setState({
              loading: false,
            });
            const data = res.data;
            const product = data.data;
            var prod = this.state.products;
            prod.push(product);
            this.setState({
              products: prod,
            });
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          });
        return null;
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  removeWishlist = (event) => {
    event.preventDefault();
    var wishlists = this.state.wishlists;
    var products = [];
    wishlists.splice(wishlists.indexOf(event.currentTarget.value), 1);
    Cookies.set("wishlist", JSON.stringify(wishlists), { expires: 7 });
    products = this.state.products.filter(function (product) {
      return product._id !== event.currentTarget.value;
    });
    this.setState({
      wishlists: wishlists,
      products: products,
    });
  };

  render() {
    const products = this.state.products;
    return (
      <React.Fragment>
        <div className="container">
          <div style={{ textAlign: "center", marginTop: "25px" }}>
            <Title level={2}>Wishlist</Title>
          </div>
          {this.state.loading ? (
            <div style={{ margin: '13%' }}>
              <Skeleton active />
            </div>
          ) : products.length === 0 ? (
            <div style={{ textAlign: "center", margin: "15%" }}>
              <Empty description={"You haven't added any item to your wishlist"} />
              <a style={{ marginTop: '25px', fontSize: '25px' }} href="/productview/gold">Start Wishing 🙂 </a>
            </div>
          ) : (
                <List style={{
                  maxWidth: '700px',
                  margin: '0 auto',
                  marginBottom: '10%'
                }}>
                  {products.map((product) => {
                    return (
                      <React.Fragment>
                        <a
                          target="_blank"
                          href={`/product/${product._id}`}
                          rel="noopener noreferrer"

                        >
                          <List.Item className="wishlist-card" key={product._id}>
                            <List.Item.Meta
                              avatar={
                                <Avatar
                                  shape="square"
                                  className="avatar-img"
                                  size={64}
                                  src={product.imageURL1}
                                />
                              }
                              title={<Title level={4}>{product.title}</Title>}
                              description={
                                <Title level={5}>{product.subtitle}</Title>
                              }
                            />
                            <div>
                              <Button
                                style={{
                                  fontWeight: "600",
                                  fontSize: "15px",
                                  textAlign: "center",
                                }}
                                shape="round"
                                icon={<DeleteOutlined />}
                                size="middle"
                                type="danger"
                                value={product._id}
                                onClick={this.removeWishlist}
                              >
                                Remove from Wishlist
                          </Button>
                            </div>
                          </List.Item>
                        </a>
                      </React.Fragment>
                    );
                  })}
                </List>
              )}
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Wishlist;

import React, { Component } from "react";
import { Col, Card, Typography } from "antd";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import "./style/TrendingProduct.css";
import Product from "prop-types";

const { Title } = Typography;
const { Meta } = Card;

export default class TrendingProduct extends Component {
  static propTypes = {
    prop: Product,
  };

  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  replaceCommaAndDecimal = (stringData) => {
    var str = stringData;
    str = str.replace(/.|,/g, (v) => {
      if (v === ".") {
        return ",";
      } else if (v === ",") {
        return ".";
      } else {
        return v;
      }
    });

    return str;
  };

  render() {
    const { product } = this.props;
    return (
      <a href={`/product/${this.props.product._id}`}>
        <Col xs={24} sm={24} md={12} lg={6} style={{ textAlign: "center" }}>
          <div
            style={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              className="product-card"
              cover={
                <div className="product-card-anchor">
                  <a
                    style={{ margin: "4px" }}
                    target="_blank"
                    href={`/product/${product._id}`}
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="example"
                      src={product.imageURL1}
                      style={{ maxWidth: "280px", maxHeight: "280px" }}
                      class="product-image-card"
                    />
                  </a>
                </div>
              }
            >
              <Meta
                className="card-hover"
                description={<Title level={4}>{product.title}</Title>}
              />
              <Meta
                className="card-hover"
                description={
                  <Title level={5}>{`€ ${this.replaceCommaAndDecimal(
                    this.numberWithCommas(this.props.price)
                  )}`}</Title>
                }
              />
            </Card>
          </div>
        </Col>
      </a>
    );
  }
}

import React, { Component } from 'react'
import { Col, Row, Input, Form, Button, notification, message } from 'antd'
import Footer from './Footer'
import './style/ForgotPassword.css'
import resetImg from '../assets/images/reset.svg'
import axios from "axios";
class ForgotPassword extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            code: "",
            password: "",
        };

    }
    handleResetPassword = () => {
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/auth/password-reset`, {
                    email: this.state.email,
                    resetcode: this.state.code,
                    password: this.state.password
                }
            )
            .then((res) => {
                this.props.history.push('/')
                notification.open({
                    message: `Password is succefully Reset`,
                    description:
                        'Try Loggin in !',
                });
            })
            .catch(function (error) {
                console.log(error);
                message.warning("Request Failed Check Email and Reset code, Try Again!")
            });
    }
    render() {
        return (
            <>
                <Row justify="center">
                    <Col span={10}>
                        <img src={resetImg} style={{ marginTop: '15%', marginBottom: '30%' }} width={300} alt="Direct Gold" />
                    </Col>

                    <Col span={6}>
                        <div className="reset-form">
                            <Form>
                                <Form.Item>
                                    <Input
                                        onChange={(e) => this.setState({ email: e.target.value })}
                                        value={this.state.email}
                                        className="reset-input"
                                        type="text"
                                        placeholder="Email"
                                    />
                                    <Input
                                        onChange={(e) => this.setState({ password: e.target.value })}
                                        value={this.state.password}
                                        className="reset-input"
                                        type="text"
                                        placeholder="New Password"
                                    />
                                    <Input
                                        onChange={(e) => this.setState({ code: e.target.value })}
                                        value={this.state.code}
                                        style={{ width: '40%' }}
                                        className="reset-input"
                                        type="text"
                                        placeholder="Reset Code"
                                    />

                                </Form.Item>
                                <Form.Item justify="center">
                                    <Button
                                        type="primary"
                                        onClick={this.handleResetPassword}
                                        className="reset-button"
                                    >
                                        Submit
                                    </Button>
                                </Form.Item>

                            </Form>
                        </div>
                    </Col>
                </Row>

                <Footer />
            </>
        )
    }
}
export default ForgotPassword
import React, { Component } from "react";
import { Drawer } from "antd";
import PriceChart from "./Charts";

class PricesDrawer extends Component {
  state = {
    visible: false,
    screenisMobile: window.innerWidth <= 600 ? true : false,
  };

  onClose = () => {
    this.setState({ visible: false });
    this.props.data.drawer = false;
  };

  handleResize = (e) => {
    this.setState({ screenisMobile: window.innerWidth <= 600 ? true : false });
  };

  componentDidUpdate() {
    if (this.state.visible !== this.props.data.drawer)
      this.setState({ visible: this.props.data.drawer });
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.handleResize);
  }

  render() {
    return (
      <>
        <Drawer
          title="Live Prices"
          placement="right"
          width={this.state.screenisMobile ? "100%" : "60%"}
          closable={true}
          onClose={this.onClose}
          className="playfair-font"
          visible={this.state.visible}
        >
          <PriceChart />
        </Drawer>
      </>
    );
  }
}
export default PricesDrawer;

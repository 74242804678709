import { Carousel } from "antd";
import React from "react";

class CarouselComponentMobile extends React.Component {
  render() {
    return (
      <Carousel autoplay={true} effect="fade">
        <div>
          <img
            style={{ height: "400px", width: "100%" }}
            className="about-home"
            src={require("../../assets/images/banner/mob-1.png")}
            alt="Banner-1"
          />
        </div>
        <div>
          <img
            style={{ height: "400px", width: "100%" }}
            className="about-home"
            src={require("../../assets/images/banner/mob-2.png")}
            alt="Banner-2"
          />
        </div>
        <div>
          <img
            style={{ height: "400px", width: "100%" }}
            className="about-home"
            src={require("../../assets/images/banner/mob-3.png")}
            alt="Banner-3"
          />
        </div>
        <div>
          <img
            className="about-home"
            style={{ height: "400px", width: "100%" }}
            src={require("../../assets/images/banner/mob-4.png")}
            alt="Banner-4"
          />
        </div>
      </Carousel>
    );
  }
}

export default CarouselComponentMobile;

import React, { Component } from "react";
import {
  Row,
  Col,
  Tabs,
  Typography,
  notification,
  InputNumber,
  Breadcrumb,
  Button,
  Divider,
  Rate,
  Comment,
  Avatar,
  Form,
  Input,
  message,
} from "antd";

import {
  HeartOutlined,
  HeartFilled,
  ShoppingCartOutlined,
  CodeSandboxSquareFilled,
} from "@ant-design/icons";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { SRLWrapper } from "simple-react-lightbox";
import axios from "axios";
import TrendingProduct from "../Home/TrendingProduct";
import Footer from "../../Constant/Footer";
import { setCurrentUser } from "../../redux/user/user.actions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { UserOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import "./style/Product.css";

const { TabPane } = Tabs;
const { Title } = Typography;

const productImageStyle = {
  maxWidth: "200px",
  marginLeft: "auto",
  marginRight: "auto",
};

export class Product extends Component {
  state = {
    rating: 4,
    product: [],
    goldmakingcharge: [],
    silvermakingcharge: [],
    goldPricecurrent: "",
    silverPricecurrent: "",
    silverAdditionalPrice1: "",
    goldAdditionalPrice1: "",
    urldata: {},
    user: localStorage.getItem("token") ? localStorage.getItem("token") : null,
    goldDocument: [],
    silverDocument: [],
    otherDocument: [],
    diamondDocument: [],
    similarProducts: [],
    wishlists: "",
    apiCallFinished: false,
    quantity: 1,
    webShopStatus: true,
    commentText: "",
  };

  componentDidMount() {
    this.setState({
      wishlists: Cookies.get("wishlist") ? Cookies.get("wishlist") : "",
    });
    setTimeout(() => {
      this.interestedPorduct();
    }, 60000);
    const url = window.location.href;
    const product_id = url.split("/");
    if (this.props.currentUser) {
      this.setState({
        user: this.props.currentUser,
      });
    }
    //API for web shop
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/web-shop/status`)
      // .get(`${process.env.REACT_APP_BACKEND_URL}/api/web-shop/status`)
      .then((res) => {
        const data = res.data.data;
        this.setState({
          webShopStatus: data,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/product/${product_id[4]}`)
      .then((res) => {
        const data = res.data;
        const product = data.data;
        this.setState({ product });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    // API for making charges for gold
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/making-charge-gold/all-making-gold`
      )
      .then((response) => {
        var goldmakingchargedata = response.data.data;
        goldmakingchargedata.reverse();
        const goldmakingcharge = goldmakingchargedata[0];
        this.setState({ goldmakingchargedata, goldmakingcharge });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
    // API for making charges for silver
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/making-charge-silver/all-making-silver`
      )
      .then((response) => {
        var silvermakingchargedata = response.data.data;
        silvermakingchargedata.reverse();
        const silvermakingcharge = silvermakingchargedata[0];
        this.setState({ silvermakingchargedata, silvermakingcharge });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    //API TO GET GOLD PRICE HISTORY
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/gold/all-gold`)
      .then((response) => {
        const goldPriceHistory = response.data.data.reverse();
        const goldPricecurrent = goldPriceHistory[0].goldprice;
        this.setState({
          goldPricecurrent,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
    //API TO GET GOLD PRICE HISTORY
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/silver/all-silver`)
      .then((response) => {
        const silverPriceHistory = response.data.data.reverse();
        const silverPricecurrent = silverPriceHistory[0].silverprice;
        this.setState({
          silverPricecurrent,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
    //API TO GET GOLD Additional PRICE HISTORY
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/additional-gold/all`)
      .then((response) => {
        const goldAdditionalPriceHistory = response.data.data.reverse();
        const goldAdditionalPrice1 =
          goldAdditionalPriceHistory[0].additionalprice;
        const goldAdditionalPrice2 =
          goldAdditionalPriceHistory[1].additionalprice;
        const goldAdditionalPrice3 =
          goldAdditionalPriceHistory[2].additionalprice;
        const goldAdditionalDate1 = goldAdditionalPriceHistory[0].updatedAt;
        this.setState({
          goldAdditionalPriceHistory,
          goldAdditionalPrice1,
          goldAdditionalPrice2,
          goldAdditionalPrice3,
          goldAdditionalDate1,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
    //API TO GET SILVER Additional PRICE HISTORY
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/additional-silver/all`)
      .then((response) => {
        const silverAdditionalPriceHistory = response.data.data.reverse();
        const silverAdditionalPrice1 =
          silverAdditionalPriceHistory[0].additionalprice;
        const silverAdditionalPrice2 =
          silverAdditionalPriceHistory[1].additionalprice;
        const silverAdditionalPrice3 =
          silverAdditionalPriceHistory[2].additionalprice;
        const silverAdditionalDate1 = silverAdditionalPriceHistory[0].updatedAt;
        this.setState({
          silverAdditionalPriceHistory,
          silverAdditionalPrice1,
          silverAdditionalPrice2,
          silverAdditionalPrice3,
          silverAdditionalDate1,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/api/document/all-doc`)
      .then((res) => {
        const data = res.data.data.reverse();
        const document = data;
        const goldDoc = document.filter(
          (document) => document.category === "Gold"
        );
        const goldDocument = goldDoc[0];
        const silverDoc = document.filter(
          (document) => document.category === "Silver"
        );
        const silverDocument = silverDoc[0];
        const diamondDoc = document.filter(
          (document) => document.category === "Diamond"
        );
        const diamondDocument = diamondDoc[0];
        const otherDoc = document.filter(
          (document) => document.category === "Other"
        );
        const otherDocument = otherDoc[0];
        this.setState({
          goldDocument,
          silverDocument,
          diamondDocument,
          otherDocument,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    axios
      .get(`${process.env.REACT_APP_API_URL}/api/product/all-product`)
      .then((res) => {
        const data = res.data;
        var products = data.data;
        var nProducts = [];
        products.map((product) => {
          if (product.category === this.state.product.category) {
            nProducts.push(product);
          }
          return null;
        });
        if (nProducts.length > 4) {
          nProducts = nProducts.slice(0, 4);
        }
        this.setState({
          similarProducts: nProducts,
          apiCallFinished: true,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  intersetedPepopleNumber = () => {
    return Math.ceil(Math.random() * 10) + 100;
  };
  interestedPorduct = () => {
    notification.open({
      message: `${this.state.product.title}`,
      duration: 30,
      description: `Currently ${this.intersetedPepopleNumber()} people are viewing this product.`,
      placement: "bottomLeft",
    });
  };
  commentDelete = (id) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/product/comment/`, {
        id: id,
      })
      .then((res) => {
        console.log("Test");
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  submittingComment = () => {
    const url = window.location.href;
    const id = url.split("/");
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/product/comment/post`, {
        name: this.state.user.nameFirst,
        comment: this.state.commentText,
        id: id[4],
      })
      .then((res) => {
        console.log("Added");
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
    this.setState({ commentText: "" });
  };
  productDocument = (category) => {
    if (category === "Gold") {
      return (
        <SRLWrapper>
          {this.state.goldDocument.docURL1 ? (
            <img
              style={{ width: 200, margin: 8, cursor: "pointer" }}
              src={this.state.goldDocument.docURL1}
              alt={`${this.state.goldDocument.category}-Document`}
            />
          ) : null}
          {this.state.goldDocument.docURL2 ? (
            <img
              style={{ width: 200, margin: 8, cursor: "pointer" }}
              src={this.state.goldDocument.docURL2}
              alt={`${this.state.goldDocument.category}-Document`}
            />
          ) : null}
          {this.state.goldDocument.docURL3 ? (
            <img
              style={{ width: 200, margin: 8, cursor: "pointer" }}
              src={this.state.goldDocument.docURL3}
              alt={`${this.state.goldDocument.category}-Document`}
            />
          ) : null}
          {this.state.goldDocument.docURL4 ? (
            <img
              style={{ width: 200, margin: 8, cursor: "pointer" }}
              src={this.state.goldDocument.docURL4}
              alt={`${this.state.goldDocument.category}-Document`}
            />
          ) : null}
          {this.state.goldDocument.docURL5 ? (
            <img
              style={{ width: 200, margin: 8, cursor: "pointer" }}
              src={this.state.goldDocument.docURL5}
              alt={`${this.state.goldDocument.category}-Document`}
            />
          ) : null}
        </SRLWrapper>
      );
    } else if (category === "Silver") {
      return (
        <SRLWrapper>
          {this.state.silverDocument.docURL1 ? (
            <img
              style={{ width: 200 }}
              src={this.state.silverDocument.docURL1}
              alt={`${this.state.silverDocument.category}-Document`}
            />
          ) : null}
          {this.state.silverDocument.docURL2 ? (
            <img
              style={{ width: 200, margin: 8, cursor: "pointer" }}
              src={this.state.silverDocument.docURL2}
              alt={`${this.state.silverDocument.category}-Document`}
            />
          ) : null}
          {this.state.silverDocument.docURL3 ? (
            <img
              style={{ width: 200, margin: 8, cursor: "pointer" }}
              src={this.state.silverDocument.docURL3}
              alt={`${this.state.silverDocument.category}-Document`}
            />
          ) : null}
          {this.state.silverDocument.docURL4 ? (
            <img
              style={{ width: 200, margin: 8, cursor: "pointer" }}
              src={this.state.silverDocument.docURL4}
              alt={`${this.state.silverDocument.category}-Document`}
            />
          ) : null}
          {this.state.silverDocument.docURL5 ? (
            <img
              style={{ width: 200, margin: 8, cursor: "pointer" }}
              src={this.state.silverDocument.docURL5}
              alt={`${this.state.silverDocument.category}-Document`}
            />
          ) : null}
        </SRLWrapper>
      );
    } else if (category === "Diamond") {
      return (
        <SRLWrapper>
          {this.state.diamondDocument.docURL1 ? (
            <img
              style={{ width: 200 }}
              src={this.state.diamondDocument.docURL1}
              alt={`${this.state.diamondDocument.category}-Document`}
            />
          ) : null}
          {this.state.diamondDocument.docURL2 ? (
            <img
              style={{ width: 200, margin: 8, cursor: "pointer" }}
              src={this.state.diamondDocument.docURL2}
              alt={`${this.state.diamondDocument.category}-Document`}
            />
          ) : null}
          {this.state.diamondDocument.docURL3 ? (
            <img
              style={{ width: 200, margin: 8, cursor: "pointer" }}
              src={this.state.diamondDocument.docURL3}
              alt={`${this.state.diamondDocument.category}-Document`}
            />
          ) : null}
          {this.state.diamondDocument.docURL4 ? (
            <img
              style={{ width: 200, margin: 8, cursor: "pointer" }}
              src={this.state.diamondDocument.docURL4}
              alt={`${this.state.diamondDocument.category}-Document`}
            />
          ) : null}
          {this.state.diamondDocument.docURL5 ? (
            <img
              style={{ width: 200, margin: 8, cursor: "pointer" }}
              src={this.state.diamondDocument.docURL5}
              alt={`${this.state.diamondDocument.category}-Document`}
            />
          ) : null}
        </SRLWrapper>
      );
    } else if (category === "Other") {
      return (
        <SRLWrapper>
          {this.state.otherDocument.docURL1 ? (
            <img
              style={{ width: 200 }}
              src={this.state.otherDocument.docURL1}
              alt={`${this.state.otherDocument.category}-Document`}
            />
          ) : null}
          {this.state.otherDocument.docURL2 ? (
            <img
              style={{ width: 200, margin: 8, cursor: "pointer" }}
              src={this.state.otherDocument.docURL2}
              alt={`${this.state.otherDocument.category}-Document`}
            />
          ) : null}
          {this.state.otherDocument.docURL3 ? (
            <img
              style={{ width: 200, margin: 8, cursor: "pointer" }}
              src={this.state.otherDocument.docURL3}
              alt={`${this.state.otherDocument.category}-Document`}
            />
          ) : null}
          {this.state.otherDocument.docURL4 ? (
            <img
              style={{ width: 200, margin: 8, cursor: "pointer" }}
              src={this.state.otherDocument.docURL4}
              alt={`${this.state.otherDocument.category}-Document`}
            />
          ) : null}
          {this.state.otherDocument.docURL5 ? (
            <img
              style={{ width: 200, margin: 8, cursor: "pointer" }}
              src={this.state.otherDocument.docURL5}
              alt={`${this.state.otherDocument.category}-Document`}
            />
          ) : null}
        </SRLWrapper>
      );
    } else {
      return null;
    }
  };

  buyNow = () => {
    if (this.state.user === null) {
      notification.open({
        message: "Not Logged In, Please Login and continue",
        description:
          "This is the content of the notification. This is the content of the notification. This is the content of the notification.",
        onClick: () => {
          console.log("Notification Clicked!");
        },
      });
    } else {
      notification.open({
        message: "Thanks for Shopping with us",
        description:
          "This is the content of the notification. This is the content of the notification. This is the content of the notification.",
        onClick: () => {
          console.log("Notification Clicked!");
        },
      });
    }
  };
  purity = (product) => {
    if (product.category === "Gold") {
      return `Purity of Gold is ${product.carat} %`;
    } else if (product.category === "Silver") {
      return `Purity of Silver is ${product.carat} %`;
    } else if (product.category === "Diamond") {
      return `Diamond is ${product.carat} kt`;
    } else if (product.category === "Mints") {
      return `Mints purity is ${product.carat} % `;
    }
  };
  livePricegold = (product) => {
    if (product.category === "Gold") {
      switch (product.weight) {
        case 1:
          return Math.round(
            (this.state.goldPricecurrent * 1 +
              this.state.goldmakingcharge.one) *
              this.state.goldAdditionalPrice1
          );
        case 5:
          return Math.round(
            (this.state.goldPricecurrent * 5 +
              this.state.goldmakingcharge.five) *
              this.state.goldAdditionalPrice1
          );
        case 10:
          return Math.round(
            (this.state.goldPricecurrent * 10 +
              this.state.goldmakingcharge.ten) *
              this.state.goldAdditionalPrice1
          );
        case 20:
          return Math.round(
            (this.state.goldPricecurrent * 20 +
              this.state.goldmakingcharge.twenty) *
              this.state.goldAdditionalPrice1
          );
        case 50:
          return Math.round(
            (this.state.goldPricecurrent * 50 +
              this.state.goldmakingcharge.fifty) *
              this.state.goldAdditionalPrice1
          );
        case 100:
          return Math.round(
            (this.state.goldPricecurrent * 100 +
              this.state.goldmakingcharge.onehundred) *
              this.state.goldAdditionalPrice1
          );
        case 250:
          return Math.round(
            (this.state.goldPricecurrent * 250 +
              this.state.goldmakingcharge.twohundredfifty) *
              this.state.goldAdditionalPrice1
          );
        case 500:
          return Math.round(
            (this.state.goldPricecurrent * 500 +
              this.state.goldmakingcharge.fivehundred) *
              this.state.goldAdditionalPrice1
          );
        case 1000:
          return Math.round(
            (this.state.goldPricecurrent * 1000 +
              this.state.goldmakingcharge.onethousand) *
              this.state.goldAdditionalPrice1
          );
        default:
          return null;
      }
    } else if (product.category === "Silver") {
      switch (product.weight) {
        case 1:
          return (
            (Math.round(
              (this.state.silverPricecurrent * 1 +
                this.state.silvermakingcharge.one) *
                this.state.silverAdditionalPrice1
            ) *
              21) /
            100
          );
        case 5:
          return (
            (Math.round(
              (this.state.silverPricecurrent * 5 +
                this.state.silvermakingcharge.five) *
                this.state.silverAdditionalPrice1
            ) *
              21) /
              100 +
            Math.round(
              (this.state.silverPricecurrent * 5 +
                this.state.silvermakingcharge.five) *
                this.state.silverAdditionalPrice1
            )
          );
        case 10:
          return (
            (Math.round(
              (this.state.silverPricecurrent * 10 +
                this.state.silvermakingcharge.ten) *
                this.state.silverAdditionalPrice1
            ) *
              21) /
              100 +
            Math.round(
              (this.state.silverPricecurrent * 10 +
                this.state.silvermakingcharge.ten) *
                this.state.silverAdditionalPrice1
            )
          );
        case 20:
          return (
            (Math.round(
              (this.state.silverPricecurrent * 20 +
                this.state.silvermakingcharge.twenty) *
                this.state.silverAdditionalPrice1
            ) *
              21) /
              100 +
            Math.round(
              (this.state.silverPricecurrent * 20 +
                this.state.silvermakingcharge.twenty) *
                this.state.silverAdditionalPrice1
            )
          );
        case 50:
          return (
            (Math.round(
              (this.state.silverPricecurrent * 50 +
                this.state.silvermakingcharge.fifty) *
                this.state.silverAdditionalPrice1
            ) *
              21) /
              100 +
            Math.round(
              (this.state.silverPricecurrent * 50 +
                this.state.silvermakingcharge.fifty) *
                this.state.silverAdditionalPrice1
            )
          );
        case 100:
          return (
            (Math.round(
              (this.state.silverPricecurrent * 100 +
                this.state.silvermakingcharge.onehundred) *
                this.state.silverAdditionalPrice1
            ) *
              21) /
              100 +
            Math.round(
              (this.state.silverPricecurrent * 100 +
                this.state.silvermakingcharge.onehundred) *
                this.state.silverAdditionalPrice1
            )
          );
        case 250:
          return (
            (Math.round(
              (this.state.silverPricecurrent * 250 +
                this.state.silvermakingcharge.twohundredfifty) *
                this.state.silverAdditionalPrice1
            ) *
              21) /
              100 +
            Math.round(
              (this.state.silverPricecurrent * 250 +
                this.state.silvermakingcharge.twohundredfifty) *
                this.state.silverAdditionalPrice1
            )
          );
        case 500:
          return (
            (Math.round(
              (this.state.silverPricecurrent * 500 +
                this.state.silvermakingcharge.fivehundred) *
                this.state.silverAdditionalPrice1
            ) *
              21) /
              100 +
            Math.round(
              (this.state.silverPricecurrent * 500 +
                this.state.silvermakingcharge.fivehundred) *
                this.state.silverAdditionalPrice1
            )
          );
        case 1000:
          return (
            (Math.round(
              (this.state.silverPricecurrent * 1000 +
                this.state.silvermakingcharge.onethousand) *
                this.state.silverAdditionalPrice1
            ) *
              21) /
              100 +
            Math.round(
              (this.state.silverPricecurrent * 1000 +
                this.state.silvermakingcharge.onethousand) *
                this.state.silverAdditionalPrice1
            )
          );
        default:
          return null;
      }
    } else if (product.category === "Diamond") {
      return product.price;
    } else {
      return product.price;
    }
  };

  handleWishlist = (productId) => {
    if (this.state.wishlists === "") {
      var wishList = [];
      wishList.push(productId);
      Cookies.set("wishlist", JSON.stringify(wishList), { expires: 7 });
      this.setState({
        wishlists: JSON.stringify(wishList),
      });
    } else {
      wishList = JSON.parse(this.state.wishlists);
      wishList.push(productId);
      Cookies.set("wishlist", JSON.stringify(wishList), { expires: 7 });
      this.setState({
        wishlists: JSON.stringify(wishList),
      });
    }
    const btn = (
      <Button type="link" size="small" onClick={this.handleOpenWishList}>
        Open Wishlist
      </Button>
    );
    notification.success({
      message: `Product Added to wishlist`,
      btn,
    });
  };

  handleOpenWishList = () => {
    window.location.href = "/wishlist";
  };

  removeWishlist = (productId) => {
    var wishlists = JSON.parse(this.state.wishlists);
    wishlists.splice(wishlists.indexOf(productId), 1);
    Cookies.set("wishlist", JSON.stringify(wishlists), { expires: 7 });
    this.setState({
      wishlists: JSON.stringify(wishlists),
    });
    notification.error({
      message: `Product removed from wishlist`,
    });
  };

  updateQuanity = (value) => {
    this.setState({ quantity: value });
  };

  addToCart = (event) => {
    if (this.props.currentUser) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/cart/register`, {
          userid: this.props.currentUser.id,
          productid: event.currentTarget.value,
          quantity: this.state.quantity,
        })
        .then((response) => {
          this.props.history.push("/your-cart");
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    } else {
      notification.info({ message: "Login to add this product to you cart" });
    }
  };

  addToCartButton = (event) => {
    if (this.props.currentUser) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/cart/register`, {
          userid: `${this.props.currentUser.id}`,
          productid: `${event.currentTarget.value}`,
          quantity: this.state.quantity,
        })
        .then((response) => {
          const btn = (
            <Button type="link" size="small" onClick={this.handleAddToCart}>
              Open Cart
            </Button>
          );
          notification.success({ message: "Added To Cart", btn });
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    } else {
      notification.info({ message: "Login to add this product to you cart" });
    }
  };

  handleAddToCart = () => {
    window.location.href = "/your-cart";
  };

  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  replaceCommaAndDecimal = (stringData) => {
    var str = stringData;
    str = str.replace(/.|,/g, (v) => {
      if (v === ".") {
        return ",";
      } else if (v === ",") {
        return ".";
      } else {
        return v;
      }
    });

    return str;
  };

  render() {
    var wishLists = this.state.wishlists
      ? JSON.parse(this.state.wishlists)
      : [];
    const { product } = this.state;
    if (product.length === 0 && this.state.apiCallFinished === true) {
      return (
        <div style={{ textAlign: "center", marginTop: "4%" }}>
          <ShoppingCartOutlined style={{ fontSize: "80px" }} />
          <br />
          <Title style={{ marginTop: "2%" }} level={4}>
            Invalid Product
          </Title>
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="container breadcrum-product">
          <Breadcrumb>
            <Breadcrumb.Item>
              <a href="/">Home</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a href={`/productview/${this.state.product.category}`}>
                {this.state.product.category}
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{this.state.product.title}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Row style={{ marginTop: "2%" }} justify="space-around">
          <Col xs={24} sm={24} md={12} lg={12}>
            <Carousel>
              {product.imageURL1 !== "" ? (
                <div style={{ marginTop: "65px" }}>
                  <img
                    style={productImageStyle}
                    src={product.imageURL1}
                    alt="Direct Gold"
                  />
                </div>
              ) : (
                <></>
              )}
              {product.imageURL2 !== "" ? (
                <div style={{ marginTop: "65px" }}>
                  <img
                    style={productImageStyle}
                    src={product.imageURL2}
                    alt="Direct Gold"
                  />
                </div>
              ) : (
                <></>
              )}
              {product.imageURL3 !== "" ? (
                <div style={{ marginTop: "65px" }}>
                  <img
                    style={productImageStyle}
                    src={product.imageURL3}
                    alt="Direct Gold"
                  />
                </div>
              ) : (
                <></>
              )}
              {product.imageURL4 !== "" ? (
                <div style={{ marginTop: "65px" }}>
                  <img
                    style={productImageStyle}
                    src={product.imageURL4}
                    alt="Direct Gold"
                  />
                </div>
              ) : (
                <></>
              )}
              {product.imageURL5 !== "" ? (
                <div style={{ marginTop: "65px" }}>
                  <img
                    style={productImageStyle}
                    src={product.imageURL5}
                    alt="Direct Gold"
                  />
                </div>
              ) : (
                <></>
              )}
            </Carousel>
          </Col>
          <Col
            className="product-description"
            style={{ padding: "2%" }}
            xs={24}
            sm={24}
            md={12}
            lg={12}
          >
            <Tabs defaultActiveKey="1" centered>
              <TabPane tab="Description" key="1">
                <div
                  style={{
                    textAlign: "center",
                    paddingLeft: "15%",
                    paddingTop: "4%",
                    fontSize: "30px",
                  }}
                >
                  {wishLists.includes(product._id) ? (
                    <HeartFilled
                      onClick={() => this.removeWishlist(product._id)}
                    />
                  ) : (
                    <HeartOutlined
                      onClick={() => this.handleWishlist(product._id)}
                    />
                  )}
                </div>
                <Title level={2}>{product.title}</Title>
                <Title level={4}>{product.subtitle}</Title>
                <Rate defaultValue={3} />
                <h3>{this.purity(product)}</h3>
                <h3 className="product-card-price">
                  {this.livePricegold(product)
                    ? `€ ${this.replaceCommaAndDecimal(
                        this.numberWithCommas(this.livePricegold(product))
                      )}`
                    : null}
                  <span className="product-tax"> (Inclusive of all taxes)</span>
                </h3>

                {this.state.proudct}

                <div className=" product-cart-icon">
                  <Divider></Divider>
                  <div className="text-center">
                    <img
                      src={require("../../Constant/payment.png")}
                      alt="Payment"
                    />
                  </div>
                  <h3>Secured Payment Methods</h3>
                  <Divider></Divider>
                  <Row>
                    <Col span={2}>
                      <i className="fas fa-shield-alt"></i>
                    </Col>
                    <Col span={6}>
                      <a href="/general-conditions">
                        Life Time <br /> Warranty
                      </a>
                    </Col>

                    <Col span={2}>
                      <i class="fas fa-euro-sign"></i>
                    </Col>
                    <Col span={6}>
                      <a href="/returns-refunds">
                        Easy
                        <br />
                        Returns
                      </a>
                    </Col>
                    <Col span={2}>
                      <i className="fas fa-truck"></i>
                    </Col>
                    <Col span={6}>
                      <a href="/faq">Free Delivery upto 7500€</a>
                    </Col>
                  </Row>
                  <Divider></Divider>
                </div>

                <Row>
                  <Col span={12}>
                    Quantity
                    <InputNumber
                      size="large"
                      min={1}
                      defaultValue={1}
                      onChange={this.updateQuanity}
                    />
                  </Col>
                  <Col span={12}>
                    <div className="stock-card">
                      {product.status ? (
                        <h3>
                          Available:<span className="stock">In Stock</span>
                        </h3>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <div style={{ margin: "50px 0" }}>
                  {this.state.webShopStatus ? (
                    <>
                      {product.status ? (
                        <>
                          <button
                            value={product._id}
                            onClick={() =>
                              message.success(
                                "Please Call Us to Order +32 (0) 486 68 70 68"
                              )
                            }
                            //this.addToCart
                            className="buynow-button"
                          >
                            Buy Now
                          </button>
                          <button
                            value={product._id}
                            className="addtowishlist-button"
                            onClick={this.addToCartButton}
                          >
                            Add to Cart
                          </button>
                        </>
                      ) : (
                        <h2
                          style={{
                            color: "#f00",
                            fontWeight: "900",
                          }}
                        >
                          Due to High Demand the shop is currently unavailable.
                          We will be back soon. Thanks for visiting Direct Gold.
                        </h2>
                      )}
                    </>
                  ) : (
                    <h2
                      style={{
                        color: "#f00",
                        fontWeight: "900",
                      }}
                    >
                      Due to High Demand the shop is currently unavailable. We
                      will be back soon. Thanks for visiting Direct Gold.
                    </h2>
                  )}
                </div>
              </TabPane>
              <TabPane tab="Details" key="2">
                <div
                  className="details-card-product"
                  dangerouslySetInnerHTML={{ __html: product.detail }}
                />
              </TabPane>
              <TabPane tab="Product Document" key="3">
                {this.productDocument(product.category)}
              </TabPane>
            </Tabs>
          </Col>
        </Row>
        <Divider orientation="left">
          <h1>Similar products</h1>
        </Divider>
        <div className="homepage-trending">
          <Row justify="center">
            {this.state.similarProducts.map((product) => (
              <TrendingProduct
                Img={product.imageURL1}
                title={product.title}
                product={product}
                price={this.livePricegold(product)}
              />
            ))}
          </Row>
        </div>

        <Divider orientation="left">
          <h1>Reviews</h1>
        </Divider>
        <div className="container ">
          <div style={{ paddingLeft: "4%", textAlign: "left" }}>
            {product.comments
              ? product.comments.map((comment) => (
                  <>
                    <Comment
                      author={<Title level={4}>{comment.name}</Title>}
                      avatar={<Avatar size="large" icon={<UserOutlined />} />}
                      content={
                        <div>
                          <Title level={4}>{comment.comment}</Title>
                        </div>
                      }
                    ></Comment>
                    {this.state.user ? (
                      <>
                        {this.state.user.nameFirst == comment.name ? (
                          <button
                            className="buynow-button"
                            style={{
                              backgroundColor: "red",
                            }}
                            onClick={() => this.commentDelete(comment._id)}
                          >
                            Delete
                          </button>
                        ) : null}
                      </>
                    ) : null}
                  </>
                ))
              : null}
            {this.state.user ? (
              <Comment
                content={
                  <>
                    <Form.Item>
                      <Input
                        rows={4}
                        onChange={(e) => {
                          this.setState({ commentText: e.target.value });
                        }}
                        value={this.state.commentText}
                      />
                    </Form.Item>
                    <Form.Item>
                      <button
                        onClick={this.submittingComment}
                        className="buynow-button"
                      >
                        Add Review
                      </button>
                    </Form.Item>
                  </>
                }
              />
            ) : null}
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user, isLoggedIn) =>
    dispatch(setCurrentUser(user, isLoggedIn)),
});

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Product));

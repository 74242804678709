import React from "react";
import { Row, Col, Typography, Image } from "antd";

const { Title } = Typography;
class NotFoundPage extends React.Component {
  render() {
    return (
      <div>
        <Row justify="center" align="middle">
          <Col xs={24} sm={24} md={12} lg={12} style={{ textAlign: "center" }}>
            <Title level={1} style={{ fontWeight: "800" }}>
              Page Not Found, <br /> Error Code 404
            </Title>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} style={{ padding: "8%" }}>
            <Image
              width={400}
              height={400}
              src={require("../assets/images/banner/404.png")}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default NotFoundPage;

import React from "react";
import {
  Row,
  Col,
  Typography,
  Divider,
  Button,
  message,
  Skeleton,
  Select,
  Tooltip,
} from "antd";
import axios from "axios";
import ProductCardInvoice from "./ProductCardInvoice";
import Logo from "../../../src/assets/images/Logo.png";
import "./style/Invoice.css";
import { connect } from "react-redux";

const { Option } = Select;

class Invoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.user,
      cart_id: "",
      idCard: "",
      imageURL: null,
      imageURL2: null,
      pinCode: "",
      city: "Antwerpen",
      deliveryCharge: null,
      imgId: null,
      imgStatus: false,
      cart: null,
      products: null,
      totalAmount: 0,
    };
  }

  async componentDidMount() {
    if (this.props.currentUser.img) {
      this.setState({ imgId: this.props.currentUser.img, imgStatus: true });
    }

    this.handleDevliveryCharge();
    if (this.state.user) {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/api/cart/${this.state.user.id}`)
        .then((res) => {
          if (res.data.data) {
            const data = res.data.data;
            // const products = data.productid;
            this.setState({
              cart_id: data._id,
              cart: data,
            });
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/order/invoice-genrator`, {
        email: this.props.currentUser.email,
        deliveryCharge: this.state.deliveryCharge,
        products: this.state.cart,
      })
      .then((res) => {
        var totalAmount = res.data.data;
        var products = res.data.products;
        this.setState({ totalAmount: totalAmount, products: products });
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  handleIdCard = (value) => {
    this.setState({
      idCard: value,
    });
  };

  handleImgUploadSection = () => {
    if (this.state.imgStatus) {
      return (
        <>
          <Typography.Title level={3}>Goverment Id</Typography.Title>
          <img
            src={this.props.currentUser.img}
            style={{ width: "300px" }}
            alt="direct-gold"
          />
          <Button
            type="primary"
            className="form-submit"
            style={{ margin: "10%" }}
            onClick={this.setState((prevState) => ({
              imgStatus: !prevState.imgStatus,
            }))}
          >
            Change Id
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Row>
            <Col span={18}>
              <Typography.Title level={3}>
                Upload Goverment Identification
              </Typography.Title>
            </Col>

            <Select
              defaultValue="-select-"
              style={{ width: 220 }}
              onChange={this.handleIdCard}
            >
              <Option value="ID Card">
                <Tooltip placement="left" title="ID Card issued by goverment">
                  <span>Id Card</span>
                </Tooltip>
              </Option>
              <Option value="passport">
                <Tooltip placement="left" title="ID Card issued by goverment">
                  <span>Passport</span>
                </Tooltip>
              </Option>
            </Select>
            {this.state.idCard !== "" ? (
              <>
                <div style={{ textAlign: "left" }}>
                  <h3>Front Side</h3>
                  <input
                    rules={[{ required: true }]}
                    style={{ margin: "10px" }}
                    type="file"
                    name="img"
                    id="img"
                    onChange={(e) => this.handleFile(e, "front")}
                  />
                  <h3>Back Side</h3>
                  <input
                    rules={[{ required: true }]}
                    style={{ margin: "10px" }}
                    type="file"
                    name="img"
                    id="img"
                    onChange={(e) => this.handleFile(e, "back")}
                  />
                </div>
              </>
            ) : null}
            {this.state.imageURL == null ? null : (
              <img
                style={{ width: "100px" }}
                src={URL.createObjectURL(this.state.imageURL)}
                alt="Identification"
              />
            )}
          </Row>
        </>
      );
    }
  };

  handleFile = (e, type) => {
    let data = e.target.files;
    if (type === "back") {
      this.setState({
        imageURL2: data[0],
      });
    } else {
      this.setState({
        imageURL: data[0],
      });
    }
  };

  handlePayment = (e) => {
    if (this.state.imageURL != null) {
      message.warning("Redirecting To Payment Section");
      console.log(this.state);
      const formData = new FormData();
      const products = this.state.products;
      formData.append("file", this.state.imageURL);
      formData.append("file", this.state.imageURL2);
      let strProducts = JSON.stringify(products);
      // let strProducts = this.state.cart;
      formData.append("products", strProducts);
      formData.append(
        "name",
        this.state.user.nameFirst + " " + this.state.user.nameLast
      );
      formData.append("email", this.state.user.email);
      formData.append("address", this.props.userData.address);
      formData.append("city", this.props.userData.city);
      formData.append("phone", this.props.userData.phone);
      formData.append("deliveryCharge", this.state.deliveryCharge);
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/order/register`, formData)
        .then((res) => {
          let url = res.data.data;
          window.location.href = url;
        })
        .catch(function (error) {
          console.log(error);
          message.error("Error in placing order ");
        });
    } else {
      message.error("Upload Goverment ID");
    }
  };

  handleDevliveryCharge = () => {
    const cityObj = [
      { city: "Antwerpen", price: 66.55 },
      { city: "Vlaams-Brabant", price: 82.28 },
      { city: "Oost-Vlaanderen", price: 82.28 },
      { city: "Limburg_B", price: 82.28 },
      { city: "West-Vlaanderen", price: 99.22 },
      { city: "Henegouwen", price: 99.22 },
      { city: "Waals-Brabant", price: 99.22 },
      { city: "Namen", price: 99.22 },
      { city: "Luik", price: 99.22 },
      { city: "Luxemburg_B", price: 118.58 },
      { city: "Luxembourg_Lux", price: 118.58 },
      { city: "Limburg_NL", price: 82.28 },
      { city: "Zeeland", price: 82.28 },
      { city: "Noord-Brabant", price: 82.28 },
      { city: "Zuid-Holland", price: 99.22 },
      { city: "Utrecht", price: 99.22 },
      { city: "Noord-Holland", price: 118.5 },
      { city: "FlevoLand", price: 118.5 },
      { city: "Gelderland", price: 118.5 },
      { city: "Overijssel", price: 118.5 },
      { city: "Friesland", price: 130.68 },
      { city: "Drenthe", price: 130.68 },
      { city: "Groningen", price: 130.68 },
    ];
    cityObj.map((city) => {
      if (city.city === this.props.userData.city) {
        this.setState({ deliveryCharge: city.price });
      }
    });
  };

  handleDate = () => {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${" "}${date}/${month < 10 ? `0${month}` : `${month}`}/${year}`;
  };

  handleProductQuantiy = (product) => {
    for (var i = 0; i < this.state.cart.productid.length; i++) {
      if (product._id == this.state.cart.productid[i].product) {
        return this.state.cart.productid[i].quantity;
      }
    }
    return 1;
  };

  render() {
    return (
      <>
        <Row className="invoice">
          <Col style={{ textAlign: "left" }} span={12}>
            <img width={150} src={Logo} />
          </Col>
          <Col style={{ textAlign: "right" }} span={12}>
            <Typography.Title level={2}>Invoice</Typography.Title>
          </Col>
          <Divider />
          <Col style={{ textAlign: "Left" }} span={24}>
            <Typography.Title level={4}>
              Date :{this.handleDate()}
            </Typography.Title>
          </Col>
          <Divider />
          <Col style={{ textAlign: "left" }} span={12}>
            <Typography.Title level={2}>Invoice To:</Typography.Title>
          </Col>
          <Col style={{ textAlign: "right" }} span={12}>
            <Typography.Title level={2}>Address :</Typography.Title>
          </Col>

          <Col span={12} style={{ textAlign: "left", paddingLeft: "2%" }}>
            <Typography.Paragraph level={3} strong={true}>
              {this.state.user.nameFirst ? (
                `${this.state.user.nameFirst} ${this.state.user.nameLast}`
              ) : (
                <Skeleton />
              )}
            </Typography.Paragraph>
            <Typography.Paragraph level={3} strong={true}>
              {this.state.user.email ? this.state.user.email : <Skeleton />}
            </Typography.Paragraph>
            <Typography.Paragraph level={3} strong={true}>
              {this.props.userData.phone ? (
                this.props.userData.phone
              ) : (
                <Skeleton />
              )}
            </Typography.Paragraph>
          </Col>

          <Col span={12} style={{ textAlign: "right", paddingRight: "2%" }}>
            <Typography.Paragraph
              style={{
                width: "195px",
                float: "right",
              }}
              level={3}
              strong={true}
            >
              {this.props.userData.address ? (
                `${this.props.userData.address}`
              ) : (
                <Skeleton />
              )}
            </Typography.Paragraph>
          </Col>

          <Col span={24}>
            <Divider />
          </Col>
          <Col style={{ textAlign: "center" }} span={18}>
            <Typography.Title level={3}>Product</Typography.Title>
          </Col>
          <Col span={3}>
            <Typography.Title level={3}>Quantity</Typography.Title>
          </Col>
          <Col span={3}>
            <Typography.Title level={3}>Price</Typography.Title>
          </Col>
          <Col span={24}>
            <Divider />
          </Col>
          {this.state.products != null ? (
            this.state.products.map((product) => {
              return (
                <ProductCardInvoice
                  image={product.product.imageURL1}
                  name={product.product.title}
                  quantity={product.quantity}
                  price={product.price}
                />
              );
            })
          ) : (
            <Skeleton />
          )}

          <Col style={{ textAlign: "right" }} span={16}>
            <Typography.Title level={5}>Delivery Charges</Typography.Title>
          </Col>
          <Col span={5}>
            <Typography.Title level={5}></Typography.Title>
          </Col>
          <Col span={3}>
            <Typography.Title level={4}>
              {" "}
              €{" "}
              {this.state.deliveryCharge ? (
                this.state.deliveryCharge
              ) : (
                <Skeleton />
              )}
            </Typography.Title>
          </Col>

          <Col span={24}>
            <Divider />
          </Col>
          <Col span={8}></Col>
          <Col span={8}></Col>
          <Col style={{ textAlign: "right" }} span={8}>
            {this.state.totalAmount == 0 ? (
              <Skeleton />
            ) : (
              <Typography.Title level={3}>
                Total: € {this.state.totalAmount}
              </Typography.Title>
            )}
          </Col>
          <Divider />
          <Col span={8}>{this.handleImgUploadSection()}</Col>
          <Col span={8}>
            <Button
              style={{ marginTop: "90%" }}
              className="payment-button"
              type="primary"
              onClick={this.handlePayment}
            >
              Proceed To Payment
            </Button>
          </Col>
          <Divider />
          <Col span={24}>
            <p>
              Note: This is a computer generated receipt does not require
              physical signature.
            </p>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});

export default connect(mapStateToProps)(Invoice);

import React, { Component } from 'react'
import banner from './gift_card.png'
import TrendingProduct from "../Component/Home/TrendingProduct";
import { Row, Col, Typography } from 'antd'

const { Title, Paragraph, Text, Link } = Typography;

class GiftCard extends Component {
    state = {
        trendingProduct: []
    }



    render() {
        return (
            <>
                <Row>
                    <Col style={{ textAlign: 'center' }} span={24}>
                        <img style={{
                            borderRadius: '15px',
                            margin: '25px 10px'
                        }} src={banner} alt="gift-card" />
                    </Col>
                    <div className="container">

                        <Row justify="center">
                            {this.state.trendingProduct.map((product) => (
                                <TrendingProduct
                                    Img={product.imageURL1}
                                    title={product.title}
                                    product={product}
                                    price={this.livePricegold(product)}
                                />
                            ))}
                        </Row>

                    </div>

                </Row>

            </>
        )
    }
}
export default GiftCard
import React from 'react'
import {
  Typography,
  Row,
  Col,
  Skeleton,
  Button,
  Modal,
  Form,
  Input,
  message,
  Empty,
  Select,
  Tooltip,
} from 'antd'
import { UserOutlined, DropboxOutlined } from '@ant-design/icons'
import axios from 'axios'
import { setCurrentUser } from '../redux/user/user.actions'
import { connect } from 'react-redux'
import './style/account.css'
const { Title } = Typography

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not validate email!',
    number: '${label} is not a validate number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
  city: {
    range: '${label} must be between ${min} and ${max}',
  },
}
const { Option } = Select

class Account extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      nameFirst: '',
      nameLast: '',
      phone: null,
      email: '',
      setIsModalVisible: false,
      setIsAddAddressVisible: false,
      country: '',
    }
  }
  componentDidMount() {
    if (this.props.currentUser.email) {
      this.setState({
        nameFirst: this.props.currentUser.nameFirst,
        nameLast: this.props.currentUser.nameLast,
        phone: this.props.currentUser.phone,
        email: this.props.currentUser.email,
      })
    }
  }

  handleCountry = (value) => {
    this.setState({
      country: value,
    })
  }

  componentWillReceiveProps() {}

  handleEditProfile = () => {
    const { setCurrentUser } = this.props
    setCurrentUser(null, false)
    localStorage.removeItem('token')
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/auth/edit-profile`, {
        email: this.props.currentUser.email,
        nameFirst: this.state.nameFirst,
        nameLast: this.state.nameLast,
        phone: this.state.phone,
      })
      .then((res) => {
        const user = res.data
        const isLoggedIn = true
        setCurrentUser(user.payload, isLoggedIn)
        localStorage.setItem('token', JSON.stringify(user.payload))
        // Cookies.set('user', user.payload, { expires: 7 }, { path: '/*' });
        this.setState({ user })
        if (res) {
          message.success('Profile Updated')
        }
        this.setState({ setIsModalVisible: false })
      })
      .catch(function (error) {
        console.log('Error In Updating Profile', error)
        message.warning('Error In Updating Profile!!')
      })
  }

  handleCityOptions = (country) => {
    if (country === '') {
      return null
    } else if (country === 'België') {
      return (
        <>
          <Option value="Antwerpen">
            <Tooltip placement="left" title="Antwerpen">
              <span>Antwerpen</span>
            </Tooltip>
          </Option>
          <Option value="Vlaams-Brabant">
            <Tooltip placement="left" title="Vlaams-Brabant">
              <span>Vlaams-Brabant</span>
            </Tooltip>
          </Option>
          <Option value="Oost-Vlaanderen">
            <Tooltip placement="left" title="Oost-Vlaanderen">
              <span>Oost-Vlaanderen</span>
            </Tooltip>
          </Option>
          <Option value="Limburg_B">
            <Tooltip placement="left" title="Limburg_B">
              <span>Limburg_B</span>
            </Tooltip>
          </Option>
          <Option value="West-Vlaanderen">
            <Tooltip placement="left" title="West-Vlaanderen">
              <span>West-Vlaanderen</span>
            </Tooltip>
          </Option>
          <Option value="Henegouwen">
            <Tooltip placement="left" title="Henegouwen">
              <span>Henegouwen</span>
            </Tooltip>
          </Option>
          <Option value="Waals-Brabant">
            <Tooltip placement="left" title="Waals-Brabant">
              <span>Waals-Brabant</span>
            </Tooltip>
          </Option>
          <Option value="Namen">
            <Tooltip placement="left" title="Namen">
              <span>Namen</span>
            </Tooltip>
          </Option>
          <Option value="Luik">
            <Tooltip placement="left" title="Luik">
              <span>Luik</span>
            </Tooltip>
          </Option>
          <Option value="Luxemburg_B">
            <Tooltip placement="left" title="Luxemburg_B">
              <span>Luxemburg_B</span>
            </Tooltip>
          </Option>
        </>
      )
    } else if (country === 'Luxembourg') {
      return (
        <>
          <Option value="Luxembourg_Lux">
            <Tooltip placement="left" title="Luxembourg_Lux">
              <span>Luxembourg_Lux</span>
            </Tooltip>
          </Option>
        </>
      )
    } else if (country === 'Nederland') {
      return (
        <>
          <Option value="Limburg_NL">
            <Tooltip placement="left" title="Limburg_NL">
              <span>Limburg_NL</span>
            </Tooltip>
          </Option>

          <Option value="Zeeland">
            <Tooltip placement="left" title="Zeeland">
              <span>Zeeland</span>
            </Tooltip>
          </Option>

          <Option value="Noord-Brabant">
            <Tooltip placement="left" title="Noord-Brabant">
              <span>Noord-Brabant</span>
            </Tooltip>
          </Option>

          <Option value="Zuid-Holland">
            <Tooltip placement="left" title="Zuid-Holland">
              <span>Zuid-Holland</span>
            </Tooltip>
          </Option>

          <Option value="Utrecht">
            <Tooltip placement="left" title="Utrecht">
              <span>Utrecht</span>
            </Tooltip>
          </Option>
          <Option value="Noord-Holland">
            <Tooltip placement="left" title="Noord-Holland">
              <span>Noord-Holland</span>
            </Tooltip>
          </Option>
          <Option value="FlevoLand">
            <Tooltip placement="left" title="FlevoLand">
              <span>FlevoLand</span>
            </Tooltip>
          </Option>
          <Option value="Overijssel">
            <Tooltip placement="left" title="Overijssel">
              <span>Overijssel</span>
            </Tooltip>
          </Option>

          <Option value="Friesland">
            <Tooltip placement="left" title="Friesland">
              <span>Friesland</span>
            </Tooltip>
          </Option>
          <Option value="Drenthe">
            <Tooltip placement="left" title="Drenthe">
              <span>Drenthe</span>
            </Tooltip>
          </Option>
          <Option value="Groningen">
            <Tooltip placement="left" title="Groningen">
              <span>Groningen</span>
            </Tooltip>
          </Option>
        </>
      )
    }
  }

  handleDelete = (id) => {
    const { setCurrentUser } = this.props
    console.log(this.state)
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/auth/delete-address`, {
        email: this.props.currentUser.email,
        id: id,
      })
      .then((res) => {
        const user = res.data
        const isLoggedIn = true
        setCurrentUser(user.payload, isLoggedIn)
        localStorage.setItem('token', JSON.stringify(user.payload))
        // Cookies.set('user', user.payload, { expires: 7 }, { path: '/*' });
        this.setState({ user })
        if (res) {
          message.success('Address Removed')
        }
      })
      .catch(function (error) {
        console.log('err from forgot password', error)
        // message.warning("Request Failed, Try again using regiestered email !!");
      })
  }

  handleAddAddress = (values) => {
    const { setCurrentUser } = this.props
    console.log(this.state)
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/auth/addaddress`, {
        email: this.props.currentUser.email,
        user: this.props.currentUser,
        phone: values.phone,
        address: values.address + ', ' + values.city + ', ' + values.country,
        postCode: values.pincode,
        city: values.city,
        addressName: values.addressName,
      })
      .then((res) => {
        const user = res.data
        const isLoggedIn = true
        setCurrentUser(user.payload, isLoggedIn)
        localStorage.setItem('token', JSON.stringify(user.payload))
        // Cookies.set('user', user.payload, { expires: 7 }, { path: '/*' });
        this.setState({ user, setIsAddAddressVisible: false })
        if (res) {
          message.success('Address Added')
        }
      })
      .catch(function (error) {
        console.log('err from forgot password', error)
      })
  }

  showModal = () => {
    this.setState({ setIsModalVisible: true })
  }
  handleCancel = () => {
    this.setState({ setIsModalVisible: false })
  }

  render() {
    return (
      <React.Fragment>
        <Row style={{ margin: '8%' }}>
          <Col span={2}></Col>
          <Col
            style={{
              textAlign: 'center',
            }}
            span={4}
          >
            <a href="/account">
              <Title className="profile-cards" level={5}>
                Account Dashboard
              </Title>
            </a>
            <br />
            <a href="/orders">
              <Title className="profile-cards" level={5}>
                My Orders
              </Title>
            </a>
            <br />
            <a href="/your-cart">
              <Title className="profile-cards" level={5}>
                My Cart
              </Title>
            </a>
            <br />
            <a href="/wishlist">
              <Title className="profile-cards" level={5}>
                My Wishlist
              </Title>
            </a>
          </Col>
          <Col span={2}></Col>
          <Col span={16} className="profile-user-card">
            {this.props.currentUser ? (
              <>
                <Title level={5}>
                  {this.props.currentUser.nameFirst}{' '}
                  {this.props.currentUser.nameLast}
                </Title>
                <Title level={5}>{this.props.currentUser.email}</Title>
                <Title level={5}>{this.props.currentUser.phone}</Title>
                <Button onClick={this.showModal} type="link">
                  Edit Your Account Details
                </Button>
                {this.props.currentUser.addresses.length > 0 ? (
                  <Title style={{ marginTop: '50px' }} level={3}>
                    Saved Address{'  '}
                    <Button
                      onClick={() =>
                        this.setState({ setIsAddAddressVisible: true })
                      }
                      type="link"
                    >
                      Add Address
                    </Button>
                  </Title>
                ) : null}
                {this.props.currentUser.addresses ? (
                  this.props.currentUser.addresses.map((address) => (
                    <div className="address-box">
                      <Title level={3}>{address.addressName}</Title>
                      <Title level={5}>Address: {address.address}</Title>
                      <Title level={5}>Province: {address.city}</Title>
                      <Title level={5}>Postal Code: {address.postCode}</Title>
                      <Button
                        type="danger"
                        onClick={() => this.handleDelete(address._id)}
                      >
                        Remove
                      </Button>
                    </div>
                  ))
                ) : (
                  <Empty />
                )}
                {this.state.setIsAddAddressVisible ? (
                  <Form
                    name="nest-messages"
                    onFinish={this.handleAddAddress}
                    validateMessages={validateMessages}
                    layout="vertical"
                    style={{
                      margin: '8% 5%',
                      border: '1.5px solid #000',
                      borderRadius: '10px',
                      padding: '80px',
                      boxShadow: '0px 0px 20px 0px #00000073',
                    }}
                  >
                    <Form.Item
                      style={{ textAlign: 'center' }}
                      label="Address Name :"
                      name="addressName"
                    >
                      <Select
                        defaultValue="-select-"
                        style={{ width: 250, textAlign: 'center' }}
                      >
                        <Option value="Home">Home</Option>
                        <Option value="Office">Office</Option>
                        <Option value="Other">Other</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'center' }} label="Name">
                      <Title level={5}>
                        {' '}
                        {this.props.currentUser.nameFirst}{' '}
                        {this.props.currentUser.nameLast}
                      </Title>
                    </Form.Item>
                    <Form.Item style={{ textAlign: 'center' }} label="Email">
                      <Title level={5}>{this.props.currentUser.email}</Title>
                    </Form.Item>
                    <Form.Item
                      name="phone"
                      label="Phone :"
                      rules={[{ required: true }]}
                      style={{ marginRight: '2%' }}
                    >
                      <Input
                        value={this.state.phone}
                        onChange={this.updatePhone}
                      />
                    </Form.Item>
                    <Form.Item
                      name="address"
                      label="Flat, House No., Building, Company, Apartment :"
                      rules={[{ required: true }]}
                      style={{ marginRight: '2%' }}
                    >
                      <Input.TextArea
                        value={this.state.address}
                        onChange={this.updateAddress}
                      />
                    </Form.Item>

                    <Row style={{ textAlign: 'center' }}>
                      <Col span={12}>
                        <Form.Item
                          label="Country"
                          name="country"
                          value={this.state.country}
                          rules={[{ city: true }]}
                        >
                          <Select
                            defaultValue="-select-"
                            style={{ width: 120 }}
                            onChange={this.handleCountry}
                          >
                            <Option value="België">
                              <Tooltip placement="left" title="België">
                                <span>België</span>
                              </Tooltip>
                            </Option>
                            <Option value="Nederland">
                              <Tooltip placement="left" title="Nederland">
                                <span>Nederland</span>
                              </Tooltip>
                            </Option>
                            <Option value="Luxembourg">
                              <Tooltip placement="left" title="Luxembourg">
                                <span>Luxembourg</span>
                              </Tooltip>
                            </Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          label="Province :"
                          rules={[{ city: true }]}
                          name="city"
                        >
                          <Select
                            defaultValue="-select-"
                            style={{ width: 200 }}
                          >
                            {this.handleCityOptions(this.state.country)}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col style={{ textAlign: 'center' }} span={24}>
                        <Form.Item label="Postal Code :" name="pincode">
                          <Input
                            type="number"
                            value={this.state.pinCode}
                            style={{ width: '150px' }}
                            onChange={(e) =>
                              this.setState({ pinCode: e.target.value })
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item style={{ textAlign: 'center' }}>
                      <Button
                        className="form-submit"
                        type="primary"
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                ) : null}
              </>
            ) : (
              <Skeleton active />
            )}
          </Col>
        </Row>
        {this.props.currentUser ? (
          <Modal
            title="Edit Profile"
            visible={this.state.setIsModalVisible}
            // onOk={handleOk}
            onCancel={this.handleCancel}
            footer={null}
          >
            <Form
              name="Edit Profile"
              initialValues={{
                remember: true,
              }}
              // onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="First Name"
                name="First Name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your First Name!',
                  },
                ]}
              >
                <Input
                  placeholder="First Name"
                  defaultValue={this.props.currentUser.nameFirst}
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  value={this.state.nameFirst}
                  onChange={(e) => this.setState({ nameFirst: e.target.value })}
                />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="Last Name"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Last Name!',
                  },
                ]}
              >
                <Input
                  placeholder="Last Name"
                  defaultValue={this.props.currentUser.nameLast}
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  value={this.state.rNameLast}
                  onChange={(e) => this.setState({ nameLast: e.target.value })}
                />
              </Form.Item>

              <Form.Item
                label="Email"
                name="Email"
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                  },
                ]}
              >
                <Input
                  placeholder={this.props.currentUser.email}
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  disabled
                  value={this.props.currentUser.email}
                />
              </Form.Item>
              <Form.Item
                label="Phone"
                name="Phone"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Phone Number!',
                  },
                ]}
              >
                <Input
                  placeholder="Enter your Phone Number"
                  defaultValue={this.props.currentUser.phone}
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  value={this.state.rPhone}
                  onChange={(e) => this.setState({ phone: e.target.value })}
                />
              </Form.Item>
              <Form.Item className="text-center">
                <Button
                  className="save-change"
                  type="primary"
                  onClick={this.handleEditProfile}
                >
                  Save Changes
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        ) : null}
      </React.Fragment>
    )
  }
}
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user, isLoggedIn) =>
    dispatch(setCurrentUser(user, isLoggedIn)),
})

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
  isLoggedIn: user.isLoggedIn,
})

export default connect(mapStateToProps, mapDispatchToProps)(Account)

import React, { Component } from "react";
import "./style/Footer.css";
import "antd/dist/antd.css";
import { Row, Col } from "antd";

import Footer from "./Footer";

class About extends Component {
  render() {
    return (
      <>
        <Row>
          <Col span={2} className="counter-section">
            <img
              style={{ height: 400 }}
              src={require("../Component/Home/directgold_poster.jpg")}
              alt="offer-poster"
            />
          </Col>
          <Col span={12}>
            <div style={{
              margin: '0 auto',
              maxWidth: '1200px'
            }} className="about-section">
              <h1> About Us </h1>
              <p>
                We are “DirectGold.eu”, an affiliate of Scarpino bv, a Belgium company situated in the Antwerp region.
  Our company number: BE0458.439.618
  Our telephone number: +32 (0)486 68 70 68
  Our e-mail address: info@directgold.store
  <br/><br/><br/>
  We are an official merchant of gold and silver products. We are specialized in delivering the best investment in gold and silver to our customers.
              </p>
            </div>
          </Col>
          <Col span={6}>
            <div className="about-video-section">
              <iframe
                title="Youtube Video"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/nAC38gjetxo"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </Col>
          <Col span={24}>
            <div style={{
              margin: '0 auto',
              maxWidth: '1200px'
            }} className="about-section">
              <p>
              
We are a registered and licensed company by the Royal Mint of Belgium as well as the member of the Warranty Fund. We have agreements with suppliers such as “UMICORE” and we have obtained the best conditions from our suppliers. We can work with low margins since our overhead is very limited. <br/> <br/>
Also, we have our own transport company to bring the goods at your doorstep without any intervention of external handling or subcontracting (so we take care of this in Belgium, Netherlands and Luxembourg). We take all responsibility for your goods from payment till your ‘acceptance of delivery’ (with the signed delivery note). Therefore, we request for ID-card when a purchase is initiated to ensure a legitimate reference of the buyer for smooth and safer delivery of the product to the concerned buyer. <br/> <br/> Buying from DirectGold.eu guarantees you for a secure and reliable purchase. Ir Ludo Janssens <br/> <br/> <br/> CEO <br/>
(Direct Gold)

              </p>
            </div>
          </Col>
        </Row>

        <Footer />
      </>
    );
  }
}

export default About;

import React from "react";
import { Row, Col, Typography, Image } from "antd";

const { Title } = Typography;
class NotLogged extends React.Component {
  render() {
    return (
      <div>
        <Row justify="center" align="middle">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            style={{ padding: "8%", textAlign: "center" }}
          >
            <Image src={require("../assets/images/icons/lock.png")} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} style={{ textAlign: "center" }}>
            <Title level={1} style={{ fontWeight: "800" }}>
              Login Required
            </Title>
          </Col>
        </Row>
      </div>
    );
  }
}

export default NotLogged;

import React, { Component } from "react";
import "./style/Footer.css";
import "antd/dist/antd.css";
import { Row, Col } from "antd";

import Footer from "./Footer";

class Contactus extends Component {
  render() {
    return (
      <>
        <Row>
          <Col span={2} className="counter-section">
            <img
              style={{ height: 400 }}
              src={require("../Component/Home/directgold_poster.jpg")}
              alt="offer-poster"
            />
          </Col>
          <Col span={12}>
            <div className="about-section">
              <h1> Contact Us </h1>
              <p>
                We are DirecrGold.eu, affiliate of Scarpino bv, Belgian Company
                situated in the Antwerp. We are an official Merchant of Gold and
                Silver Products. We are specialized in Investment Gold and
                Silver. We have a License at the Royal Mint of Belgium and
                member of the Warrant Fund. We have agreements with suppliers
                such as UMICORE and have obtained the best conditions. We can
                work with low margins since our overhead is very limited. We
                have our own transport company and we bring the goods to you
                without any subcontracter. We take all responsibility over your
                goods as from your payment until accepted delivery from hand to
                hand.
                <br />
                Buying at DirectGold guarantees you for a SAFE and TRUSTABLE
                purchase.
                <br />
                <b>Ir Ludo Janssens</b>
                <br />
                <br />
                CEO Scarpino bv
              </p>
            </div>
          </Col>
          <Col span={6}>
            <div className="about-video-section">
              <iframe
                title="Youtube Video"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/nAC38gjetxo"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </Col>
        </Row>

        <Footer />
      </>
    );
  }
}

export default Contactus;

import React, { Component } from 'react'
import { Row, Col, Divider, Spin, Button } from 'antd'
import { connect } from 'react-redux'
import logo from '../assets/images/Logo.png'
import moment from 'moment'
import ReactToPrint, { PrintContextConsumer } from 'react-to-print'
import axios from 'axios'
import './style/invoicePrint.css'
import { LoadingOutlined } from '@ant-design/icons'

const antIcon = (
  <LoadingOutlined style={{ fontSize: '30px', marginTop: '300px' }} spin />
)

class InvoicePrint extends Component {
  state = {
    url: '',
    orders: null,
  }

  componentDidMount = () => {
    const url = window.location.href
    const product_id = url.split('/')
    this.setState({
      url: product_id[4],
    })
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/order/${this.props.currentUser.email}`
      )
      .then((res) => {
        let x = res.data.data
        let orders = x.reverse()
        // this.setState({ orders: orders })
        orders.map((product) => {
          if (this.state.url == product.orderId) {
            this.setState({ orders: product })
          }
        })
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  render() {
    return (
      <>
        <ReactToPrint content={() => this.componentRef}>
          <PrintContextConsumer>
            {({ handlePrint }) => (
              <button className="print-btn-invoice" onClick={handlePrint}>
                Print Invoice
              </button>
            )}
          </PrintContextConsumer>
        </ReactToPrint>

        <div id="productInvoice" ref={(el) => (this.componentRef = el)}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '30px 0 50px',
            }}
          >
            {this.state.orders != null ? (
              <div className="Invoice">
                <Row style={{ marginTop: '100px' }}>
                  <Col span={12}></Col>
                  <Col span={12}>
                    <div style={{ marginLeft: '50%' }}>
                      <img
                        style={{ width: '100px' }}
                        src={logo}
                        alt="Direct Gold"
                      />
                      <h2> Scarpino bvba </h2>
                      <p>
                        Keerbaan 13A, 2520 RANST <br />
                        Tel : +32 (0)486 68 70 68
                        <br />
                        E-mail:{' '}
                        <a href="mailto:info@directgold.store">
                          info@directgold.store
                        </a>
                        <br />
                        Website:{' '}
                        <a href="www.directgold.store">www.directgold.store</a>
                        <br />
                        BANK : BNPParibasFortis
                        <br />
                        IBAN: BE97 0012 6720 3249
                        <br />
                        BIC: GEBABEBB
                        <br />
                        BTW/TVA nr: BE0458.439.618
                        <br />
                      </p>
                    </div>
                  </Col>
                  <Col span={24}>
                    <h4>
                      <b>
                        <u>FACTUUR/INVOICE :</u>
                      </b>
                    </h4>
                    <h4>
                      Date:{' '}
                      {moment.utc(this.state.orders.createdAt).format('LLL')}{' '}
                      <br />
                      Order no.: {this.state.orders.orderId}
                      <br />
                    </h4>
                    <br />
                    <br />
                    <h4>
                      {' '}
                      <b>Invoice Address :</b>
                      <br />
                      Name : {this.state.orders.name} <br />
                      {/* Company name 21seconds <br /> */}
                      Address:{this.state.orders.address} <br />
                      E-mail : {this.state.orders.email}
                      <br />
                      Tel : {this.state.orders.phone}
                      <br />
                      TVA / BTW no. : LU999.765.888 <br />{' '}
                    </h4>
                  </Col>
                  <Divider />
                  <Col span={24}>
                    <h4>Omschrijving / Description Product :</h4>
                  </Col>
                  <Divider />
                  <Col span={8}>
                    <h4>Article Description</h4>
                  </Col>
                  <Col span={4}>
                    <h4>Quantity</h4>
                  </Col>
                  <Col span={4}>
                    <h4>Price/Unit</h4>
                  </Col>
                  <Col span={8}>
                    <h4>Total inc.TVA</h4>
                  </Col>
                  <Divider />
                  {this.state.orders != null
                    ? this.state.orders.products.map((product) => (
                        <>
                          <Col span={8}>
                            <h4>{product.product.title}</h4>
                          </Col>
                          <Col span={4}>
                            <h4>{product.quantity}</h4>
                          </Col>
                          <Col span={4}>
                            <h4>€ {product.price}</h4>
                          </Col>
                          <Col span={8}>-</Col>
                        </>
                      ))
                    : null}
                  <Divider />
                  <Col span={12}>
                    <h2>Total</h2>
                  </Col>
                  <Col span={12}>
                    <h2>€ {this.state.orders.totalprice}</h2>
                  </Col>
                  <Divider />
                  <Col span={24}>
                    Bedrag te betalen/Amount to be paid : WEBSHOP{' '}
                    <a target="_blank" href="www.directgold.store">
                      www.directgold.store
                    </a>{' '}
                    – (Mollie Payment module) Via SEPA transfer (overschrijving)
                    or BANCONTACT or IDEAL <br />
                    Leverings-tijdstip / date and time of Delivery : after full
                    payment : you will be contacted !<br />
                    (Levering op bovenstaand address indien niet anders
                    gespecifieerd – delivery on above addsress if not
                    differently specified) Remarks/Opmerkingen :<br />
                    <ul>
                      <li>
                        GOUD is vrijgesteld van btw vlgs artikel 44BIS §1 en §2
                        (GOLD : no TVA)
                        <br />
                      </li>
                      <li>
                        ZILVER &amp; GOUDEN munten : Geen BTW : vallen onder de
                        margeregeling (Indien geen btw vermeld is deze ook niet
                        aftrekbaar). (SILVER MINTS : no TVA)
                        <br />
                      </li>
                      <li>
                        Alle transacties dienen onze algemene voorwaarden te
                        volgen. Deze zijn raadpleegbaar op www.directgold.store
                        of indien gewenst doo u opvraagbaar via
                        info@directgold.store.
                      </li>
                    </ul>
                  </Col>
                  <h4></h4>
                </Row>
              </div>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <Spin indicator={antIcon} />
              </div>
            )}
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
  isLoggedIn: user.isLoggedIn,
})

export default connect(mapStateToProps)(InvoicePrint)

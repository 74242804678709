import React from "react";
import {
  List,
  notification,
  Typography,
  Skeleton,
  Empty,
  Row,
  Col,
  Modal,
  Checkbox,
  message,
} from "antd";
import axios from "axios";
import { connect } from "react-redux";
import Footer from "../Constant/Footer";
import { Redirect } from "react-router";
import "./style/cart.css";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { deepskyblue } from "color-name";

const { Title } = Typography;

class Cart extends React.Component {
  constructor() {
    super();
    this.state = {
      products: [],
      cart_id: "",
      loading: true,
      user: null,
      visible: false,
      agreedTermsAndConditions: false,
      cart: [],
    };
  }

  async componentDidMount() {
    this.setState({
      user: await this.props.currentUser,
    });
    this.getCartData();
  }

  async getCartData() {
    if (this.props.currentUser) {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/cart/${this.props.currentUser.id}`
        )
        .then((res) => {
          this.setState({
            loading: false,
          });
          if (res.data.data) {
            const data = res.data.data;
            const products = data.productid;
            this.setState({
              cart_id: data._id,
              cart: data.productid,
              products: [],
            });
            products.map((product) => {
              axios
                .get(
                  `${process.env.REACT_APP_API_URL}/api/product/${product.product}`
                )
                .then((res) => {
                  const data = res.data;
                  const product = data.data;
                  this.setState((prevState) => ({
                    products: [...prevState.products, product],
                  }));
                })
                .catch(function (error) {
                  // handle error
                  console.log(error);
                });
              return null;
            });
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }
  }

  async updateCartItems(productId, quantity) {
    this.setState({ loading: true });
    if (this.props.currentUser) {
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/cart/register`, {
          userid: `${this.props.currentUser.id}`,
          productid: productId,
          quantity: quantity,
        })
        .then((response) => {
          notification.success({ message: "Cart Updated Successfully" });
          this.getCartData();
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }
  }

  emptyCart = async () => {
    await axios
      .delete(`${process.env.REACT_APP_API_URL}/api/cart/${this.state.cart_id}`)
      .then(() => {
        this.setState({
          products: [],
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
      agreedTermsAndConditions: true,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleAgreeGenCond = () => {
    this.setState((prevState) => ({
      agreeGeneralCondition: !prevState.agreeGeneralCondition,
    }));
  };
  handlePrivacy = () => {
    this.setState((prevState) => ({ agreePrivacy: !prevState.agreePrivacy }));
  };
  handleProductQuantity(id) {
    for (var i = 0; i < this.state.cart.length; i++) {
      if (this.state.cart[i].product == id) {
        return this.state.cart[i].quantity;
      }
    }
  }

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };
  emptyCart = async () => {
    await axios
      .delete(`${process.env.REACT_APP_API_URL}/api/cart/${this.state.cart_id}`)
      .then(() => {
        this.setState({
          products: [],
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  handleAgreeGenCond = () => {
    this.setState((prevState) => ({
      agreeGeneralCondition: !prevState.agreeGeneralCondition,
    }));
  };
  handlePrivacy = () => {
    this.setState((prevState) => ({ agreePrivacy: !prevState.agreePrivacy }));
  };

  render() {
    const products = this.state.products;
    if (this.state.agreedTermsAndConditions) {
      return <Redirect to="/checkout" />;
    }
    return (
      <React.Fragment>
        <div style={{ textAlign: "center", marginTop: "25px" }}>
          <Title level={2}>Cart</Title>
        </div>
        {this.state.loading ? (
          <div style={{ margin: "15%" }}>
            <Skeleton active />
          </div>
        ) : this.state.products.length === 0 ? (
          <div style={{ textAlign: "center", margin: "15%" }}>
            <Empty description={"Your shopping cart is empty."} />
            <a
              style={{ marginTop: "10%", fontSize: "20px" }}
              href={"/productview/gold"}
            >
              Continue Shopping <span>🤩</span>{" "}
            </a>
          </div>
        ) : (
          <List
            style={{
              padding: 12,
              maxWidth: "600px",
              margin: "5% auto",
              border: "1px solid #e5e5e5",
              textAlign: "right",
            }}
          >
            {products.map((product) => {
              return (
                <React.Fragment>
                  <List.Item key={product._id}>
                    <List.Item.Meta
                      avatar={
                        <img
                          className="product-avatar"
                          src={product.imageURL1}
                          alt="item"
                          height="130px"
                          width="auto"
                        />
                      }
                      title={
                        <a href={`/product/${product._id}`}>
                          <Title level={4}>{product.title}</Title>
                        </a>
                      }
                      description={
                        this.handleProductQuantity(product._id) != null ? (
                          <>
                            <Title level={5}>{product.subtitle}</Title>
                            <Title level={4}>
                              Quantity:{" "}
                              <MinusCircleOutlined
                                onClick={() =>
                                  this.updateCartItems(
                                    product._id,
                                    this.handleProductQuantity(product._id) - 1
                                  )
                                }
                              />{" "}
                              {this.handleProductQuantity(product._id)}{" "}
                              <PlusCircleOutlined
                                onClick={() =>
                                  this.updateCartItems(
                                    product._id,
                                    this.handleProductQuantity(product._id) + 1
                                  )
                                }
                              />
                            </Title>{" "}
                          </>
                        ) : (
                          <Title level={5}>{product.subtitle}</Title>
                        )
                      }
                    />
                  </List.Item>
                </React.Fragment>
              );
            })}
          </List>
        )}
        <div style={{ textAlign: "center", marginBottom: "2%" }}>
          {this.state.products.length === 0 ? null : (
            <div>
              <Row>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <button
                    // onClick={this.showModal}
                    onClick={() =>
                      message.success(
                        "Please Call Us to Order +32 (0) 486 68 70 68"
                      )
                    }
                    className="product-view-checkout"
                  >
                    Checkout
                  </button>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <button
                    onClick={() => this.emptyCart}
                    className="product-view-empty"
                  >
                    Empty Cart
                  </button>
                </Col>
              </Row>
            </div>
          )}
        </div>
        <Footer />
        <Modal
          title="Terms & Conditions"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okText="Proceed"
          width="900px"
          okButtonProps={
            this.state.agreeGeneralCondition && this.state.agreePrivacy
              ? { disabled: false }
              : { disabled: true }
          }
        >
          <h2>Check Out, Terms & Conditions </h2>
          <p>
            STEP 1 :<br />
            By checking Out (Click on the OK button below), you abide by our
            ‘terms and conditions’. A timer will be displayed as and when you
            add an item to cart or initiate to purchase (max 30’). During this
            period, we will maintain (in a way freeze) the actual price levels
            for you (the ones appeared on your screen while selecting the
            products). If these 30 minutes are exceeded, the buying process will
            be cancelled automatically. You can restart the process, possibly at
            adapted price conditions of that moment. All products present in
            your cart will be taken into consideration within this purchase
            process.   At any moment within this process, if you will be able to
            step out of this process, it will result in a cancellation of your
            purchase. Your purchase will only become definitive when Direct Gold
            will receive a notification of your payment (from our payment
            gateway provider). Consequently, you will receive a mail from us on
            the successful transaction and expected delivery of your items.
            <br />
            <b>CHECK BOX 1:</b> I agree to Direct Gold’s General Conditions, by
            clicking the Check box next to this text of these General
            Conditions.
            <br />
            <b>CHECK BOX 2:</b> I agree to Direct Gold’s Privacy Policy, by
            clicking the Check box next to this text of this Privacy Policy.
          </p>
          <p>
            STEP 2 :
            <br />
            In this next step, you will be invited to fill in a form as a record
            of your personal information: name, company name and TVA number
            (optional), invoice Address, delivery address (if different from the
            invoice address), your e-mail and your telephone number and also you
            need to upload a copy of your ID-Card. Keep a (clearly scanned)
            image of your ID-card ready (if not you can still upload this ID
            card afterwards). We need this information to make an order form,
            invoice and to complete the delivery process. More details on this
            subject are described in our privacy policy which must be agreed by
            the customer. (Checkbox 2)
          </p>

          <p>
            STEP 3 :
            <br />
            Once your form is uploaded, you will receive an order form
            (Provisional Invoice). This provisional invoice will contain the
            details and total prices of your Purchase. Also, a calculation of
            the delivery cost (if applicable) will be on the invoice. (If you do
            not agree with this invoice, you can skip out the Buying process).
            (Delivery cost: If the total price of goods is equal or higher to
            7.500,00€ (taxes included): then, there will be no delivery charge
            within BENELUX. If the total price is less then 7.500,00€ - then the
            delivery cost will be 45€ within a perimeter of 50km from the place
            “RANST” Belgium) – There will be a supplement of 25€ for each
            distance higher then 50km within the Benelux (Taxes included).
            <br />
            <br />
            If you agree with the invoice, you can now proceed to the next step
            in the ordering process and pay for the goods.
          </p>
          <p>
            {" "}
            STEP 4 : Payment
            <br />
            Supported Payment Methods for Buying Gold, Silver and Diamond
            Products are (Direct Payment via Bank Transfer) :
            <br />
            1) SEPA BANK Transfer (SEPA Overschrijving) (Europe)
            <br />
            2) BAN CONTACT (for Belgium)
            <br />
            3) IDEAL  (For The Netherlands)
          </p>
          <p>
            {" "}
            STEP 5 :
            <br />
            Your order will be completely accepted after we receive a positive
            message from our “Gateway Provider” regarding the successful
            payment. All this must be finalized within 30’ from Clicking the
            “OK” button below on this Page. You will receive a mail and the
            delivery process can be started.  <br />
            <br />
            In case of any query, you can contact us (Scarpino bv)
            <br />
            Tel : 0032 (0)486687068
            <br />
            Mail: Scarpino5@mac.com
            <br />
            Chat (via Chatbox on the Website)   Thank You.
          </p>
          <p>
            <br />
            <Checkbox onChange={(e) => this.handleAgreeGenCond()}>
              I agree to “scarpino’s” General Conditions, by clicking the Check
              box next to this text of these general conditions.
            </Checkbox>
            <br />
            <Checkbox onChange={(e) => this.handlePrivacy()}>
              I agree to “scarpino’s” Privacy Policy, by clicking the Check box
              next to this text of this Privacy Policy.
            </Checkbox>
          </p>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
});

export default connect(mapStateToProps)(Cart);

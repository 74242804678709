import React, { Component } from 'react'

class PaymentSuccess extends Component {
    render() {
        return (
            <div className="text-center">
                <h1>
                    Payment Success
                </h1>
            </div>
        )
    }
}
export default PaymentSuccess
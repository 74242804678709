import React from "react";
import { Typography, Row, Col, Avatar, Divider } from "antd";

class ProductCardInvoice extends React.Component {
  render() {
    return (
      <>
        <Row style={{ width: "100%", textAlign: "center" }}>
          <Col style={{ textAlign: "center" }} span={9}>
            <img
              className="product-avatar"
              height="130px"
              width="auto"
              src={this.props.image}
              alt="checkout"
            />
          </Col>
          <Col style={{ textAlign: "left", marginTop: "40px" }} span={9}>
            <Typography.Title level={4}>{this.props.name}</Typography.Title>
          </Col>
          <Col style={{ marginTop: "40px" }} span={2}>
            <Typography.Title level={4}>{this.props.quantity}</Typography.Title>
          </Col>
          <Col style={{ marginTop: "40px", textAlign: "right" }} span={3}>
            <Typography.Title level={4}>€ {this.props.price}</Typography.Title>
          </Col>
        </Row>
        <Divider />
      </>
    );
  }
}

export default ProductCardInvoice;

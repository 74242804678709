import React, { Component } from 'react'
import TrendingProduct from './TrendingProduct'
// import PopularProduct from './PopularProduct';
import moment from 'moment'
import axios from 'axios'
import {
  Row,
  Divider,
  notification,
  Skeleton,
  Col,
  Card,
  Typography,
} from 'antd'
import CarouselComponent from './CarouselComponent'
// import ShopByGender from './ShopByGender';
import ClientReview from './ClientReview'
import { Container, Link, Button } from 'react-floating-action-button'
import 'antd/dist/antd.css' // or 'antd/dist/antd.less'
import './style/HomePage.css'
import PricesDrawer from './PricesDrawer'
import Footer from '../../Constant/Footer'
import Countdown from 'react-countdown'
import CarouselComponentMobile from './CourouselComponentMobile'

// Random component
const Completionist = () => <h1>Offer Is Over!</h1>
const { Title } = Typography
// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />
  } else {
    // Render a countdown
    return (
      <div className="counter">
        {' '}
        <Row>
          {' '}
          <Col span={8}>
            {' '}
            <h1 className="counter-item">{hours}</h1>
            <span>Hours </span>{' '}
          </Col>{' '}
          <Col span={8}>
            {' '}
            <h1 className="counter-item">{minutes} </h1> <span>Minutes</span>
          </Col>{' '}
          <Col span={8}>
            {' '}
            <h1 className="counter-item">{seconds} </h1>
            <span>Seconds</span>{' '}
          </Col>
        </Row>
      </div>
    )
  }
}

export class HomePage extends Component {
  state = {
    product: [],
    email: '',
    visible_upload: false,
    visible_product: false,
    title: '',
    subtitle: '',
    category: '',
    detail: '',
    weight: 0,
    price: 0,
    quantity: 0,
    purity: '',
    img: '',
    products: [],
    loading: true,
    value: 'Gold',
    other: '',
    latestProduct: [],
    popularProduct: [],
    goldmakingchargedata: {},
    goldmakingcharge: {},
    silvermakingchargedata: {},
    silvermakingcharge: {},
    goldPricecurrent: '',
    silverPricecurrent: '',
    trendingProduct: [],
    blogData: null,
    webShopStatus: true,
  }
  componentDidMount() {
    setInterval(function () {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/making-charge-gold/all-making-gold`
        )
        .then((response) => {
          if (response !== undefined) {
            var goldmakingchargedata = response.data.data
            goldmakingchargedata.reverse()
            const goldmakingcharge = goldmakingchargedata[0]
            const previousGoldMaking = this.state.goldmakingcharge
            if (previousGoldMaking !== goldmakingcharge) {
              notification.info({
                title: 'Refreshing the page',
              })
              this.setState({ goldmakingchargedata, goldmakingcharge })
            }
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error)
        })
      // API for making charges for silver
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/making-charge-silver/all-making-silver`
        )
        .then((response) => {
          if (response !== undefined) {
            var silvermakingchargedata = response.data.data
            silvermakingchargedata.reverse()
            const silvermakingcharge = silvermakingchargedata[0]
            const previousSilverMaking = this.state.silvermakingcharge
            if (previousSilverMaking !== silvermakingcharge) {
              this.setState({ silvermakingchargedata, silvermakingcharge })
            }
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error)
        })
    }, 1800000)

    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/blog/all-blog`)
      .then((res) => {
        const data = res.data.data
        this.setState({
          blogData: data,
        })
      })
      .catch(function (error) {
        console.log(error)
      })

    axios

      .get(`${process.env.REACT_APP_API_URL}/api/web-shop/status`)
      // .get(`${process.env.REACT_APP_BACKEND_URL}/api/web-shop/status`)
      .then((res) => {        
        const data = res.data.data
        this.setState({
          webShopStatus: data,
        })
      })
      .catch(function (error) {
        console.log(error)
      })

    axios
      .get(`${process.env.REACT_APP_API_URL}/api/product/all-product`)
      .then((res) => {
        const product = res.data.data.reverse()
        const trendingProduct = product.splice(2, 4)
        const popularProduct = product.splice(1)
        this.setState({
          product: product,
          trendingProduct: trendingProduct,
          popularProduct: popularProduct,
          loading: false,
        })
      })
      .catch(function (error) {
        console.log(error)
      })

    // for making charges for gold
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/making-charge-gold/all-making-gold`
      )
      .then((response) => {
        var goldmakingchargedata = response.data.data
        goldmakingchargedata.reverse()
        const goldmakingcharge = goldmakingchargedata[0]
        this.setState({ goldmakingchargedata, goldmakingcharge })
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
    // API for making charges for silver
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/making-charge-silver/all-making-silver`
      )
      .then((response) => {
        var silvermakingchargedata = response.data.data
        silvermakingchargedata.reverse()
        const silvermakingcharge = silvermakingchargedata[0]
        this.setState({ silvermakingchargedata, silvermakingcharge })
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })

    //API TO GET GOLD PRICE HISTORY
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/gold/all-gold`)
      .then((response) => {
        const goldPriceHistory = response.data.data.reverse()
        const goldPricecurrent = goldPriceHistory[0].goldprice
        this.setState({
          goldPricecurrent,
        })
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
    //API TO GET SILVER PRICE HISTORY
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/silver/all-silver`)
      .then((response) => {
        const silverPriceHistory = response.data.data.reverse()
        const silver = silverPriceHistory[0]
        const silverPricecurrent = silver.silverprice
        this.setState({
          silverPricecurrent,
        })
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/additional-silver/all`)
      .then((response) => {
        const silverAdditionalPrice = response.data.data.reverse()
        const silverAdditionalCurrent = silverAdditionalPrice[0].additionalprice
        this.setState({
          silverAdditionalPrice1: silverAdditionalCurrent,
        })
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })

    axios
      .get(`${process.env.REACT_APP_API_URL}/api/additional-gold/all`)
      .then((response) => {
        const goldAdditionalPrice = response.data.data.reverse()
        const goldAdditionalCurrent = goldAdditionalPrice[0].additionalprice
        this.setState({
          goldAdditionalPrice1: goldAdditionalCurrent,
        })
      })
      .catch(function (error) {
        // handle error
        console.log(error)
      })
  }

  showDrawer = () => {
    this.setState({ drawer: true })
  }

  livePricegold = (product) => {
    if (product.category === 'Gold') {
      switch (product.weight) {
        case 1:
          return Math.round(
            (this.state.goldPricecurrent * 1 +
              this.state.goldmakingcharge.one) *
              this.state.goldAdditionalPrice1
          )
        case 5:
          return Math.round(
            (this.state.goldPricecurrent * 5 +
              this.state.goldmakingcharge.five) *
              this.state.goldAdditionalPrice1
          )
        case 10:
          return Math.round(
            (this.state.goldPricecurrent * 10 +
              this.state.goldmakingcharge.ten) *
              this.state.goldAdditionalPrice1
          )
        case 20:
          return Math.round(
            (this.state.goldPricecurrent * 20 +
              this.state.goldmakingcharge.twenty) *
              this.state.goldAdditionalPrice1
          )
        case 50:
          return Math.round(
            (this.state.goldPricecurrent * 50 +
              this.state.goldmakingcharge.fifty) *
              this.state.goldAdditionalPrice1
          )
        case 100:
          return Math.round(
            (this.state.goldPricecurrent * 100 +
              this.state.goldmakingcharge.onehundred) *
              this.state.goldAdditionalPrice1
          )
        case 250:
          return Math.round(
            (this.state.goldPricecurrent * 250 +
              this.state.goldmakingcharge.twohundredfifty) *
              this.state.goldAdditionalPrice1
          )
        case 500:
          return Math.round(
            (this.state.goldPricecurrent * 500 +
              this.state.goldmakingcharge.fivehundred) *
              this.state.goldAdditionalPrice1
          )
        case 1000:
          return Math.round(
            (this.state.goldPricecurrent * 1000 +
              this.state.goldmakingcharge.onethousand) *
              this.state.goldAdditionalPrice1
          )
        default:
          return null
      }
    } else if (product.category === 'Silver') {
      switch (product.weight) {
        case 1:
          return (
            (Math.round(
              (this.state.silverPricecurrent * 1 +
                this.state.silvermakingcharge.one) *
                this.state.silverAdditionalPrice1
            ) *
              21) /
            100
          )
        case 5:
          return (
            (Math.round(
              (this.state.silverPricecurrent * 5 +
                this.state.silvermakingcharge.five) *
                this.state.silverAdditionalPrice1
            ) *
              21) /
              100 +
            Math.round(
              (this.state.silverPricecurrent * 5 +
                this.state.silvermakingcharge.five) *
                this.state.silverAdditionalPrice1
            )
          )
        case 10:
          return (
            (Math.round(
              (this.state.silverPricecurrent * 10 +
                this.state.silvermakingcharge.ten) *
                this.state.silverAdditionalPrice1
            ) *
              21) /
              100 +
            Math.round(
              (this.state.silverPricecurrent * 10 +
                this.state.silvermakingcharge.ten) *
                this.state.silverAdditionalPrice1
            )
          )
        case 20:
          return (
            (Math.round(
              (this.state.silverPricecurrent * 20 +
                this.state.silvermakingcharge.twenty) *
                this.state.silverAdditionalPrice1
            ) *
              21) /
              100 +
            Math.round(
              (this.state.silverPricecurrent * 20 +
                this.state.silvermakingcharge.twenty) *
                this.state.silverAdditionalPrice1
            )
          )
        case 50:
          return (
            (Math.round(
              (this.state.silverPricecurrent * 50 +
                this.state.silvermakingcharge.fifty) *
                this.state.silverAdditionalPrice1
            ) *
              21) /
              100 +
            Math.round(
              (this.state.silverPricecurrent * 50 +
                this.state.silvermakingcharge.fifty) *
                this.state.silverAdditionalPrice1
            )
          )
        case 100:
          return (
            (Math.round(
              (this.state.silverPricecurrent * 100 +
                this.state.silvermakingcharge.onehundred) *
                this.state.silverAdditionalPrice1
            ) *
              21) /
              100 +
            Math.round(
              (this.state.silverPricecurrent * 100 +
                this.state.silvermakingcharge.onehundred) *
                this.state.silverAdditionalPrice1
            )
          )
        case 250:
          return (
            (Math.round(
              (this.state.silverPricecurrent * 250 +
                this.state.silvermakingcharge.twohundredfifty) *
                this.state.silverAdditionalPrice1
            ) *
              21) /
              100 +
            Math.round(
              (this.state.silverPricecurrent * 250 +
                this.state.silvermakingcharge.twohundredfifty) *
                this.state.silverAdditionalPrice1
            )
          )
        case 500:
          return (
            (Math.round(
              (this.state.silverPricecurrent * 500 +
                this.state.silvermakingcharge.fivehundred) *
                this.state.silverAdditionalPrice1
            ) *
              21) /
              100 +
            Math.round(
              (this.state.silverPricecurrent * 500 +
                this.state.silvermakingcharge.fivehundred) *
                this.state.silverAdditionalPrice1
            )
          )
        case 1000:
          return (
            (Math.round(
              (this.state.silverPricecurrent * 1000 +
                this.state.silvermakingcharge.onethousand) *
                this.state.silverAdditionalPrice1
            ) *
              21) /
              100 +
            Math.round(
              (this.state.silverPricecurrent * 1000 +
                this.state.silvermakingcharge.onethousand) *
                this.state.silverAdditionalPrice1
            )
          )
        default:
          return null
      }
    } else if (product.category === 'Diamond') {
      return product.price
    } else {
      return product.price
    }
  }

  render() {
    return (
      <>
        <Row>
          <Col xs={0} sm={0} md={24} lg={24}>
            <CarouselComponent />
          </Col>
          <Col xs={24} sm={24} md={0} lg={0}>
            <CarouselComponentMobile />
          </Col>
        </Row>
        <div>
          {/* <ShopByGender /> */}

          {this.state.webShopStatus ? (
            <>
              <Divider orientation="center">
                <Title level={1}>Trending Products</Title>
              </Divider>

              {this.state.loading ? (
                <div style={{ padding: '2%' }}>
                  <Row gutter={16}>
                    <Col xs={24} sm={24} lg={6} md={6}>
                      <Card>
                        <Skeleton active loading />
                      </Card>
                    </Col>

                    <Col xs={24} sm={24} lg={6} md={6}>
                      <Card>
                        <Skeleton active loading />
                      </Card>
                    </Col>

                    <Col xs={24} sm={24} lg={6} md={6}>
                      <Card>
                        <Skeleton active loading />
                      </Card>
                    </Col>

                    <Col xs={24} sm={24} lg={6} md={6}>
                      <Card>
                        <Skeleton active loading />
                      </Card>
                    </Col>
                  </Row>
                </div>
              ) : (
                <div className="homepage-trending">
                  <Row justify="center">
                    {this.state.trendingProduct.map((product) => (
                      <TrendingProduct
                        Img={product.imageURL1}
                        title={product.title}
                        product={product}
                        price={this.livePricegold(product)}
                      />
                    ))}
                  </Row>
                </div>
              )}
            </>
          ) : null}
          <Row justify="center">
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={20}
              className="counter-section"
              style={{ textAlign: 'center', paddingLeft: '4%' }}
            >
              <Title level={3}>
                Direct Gold ensures quality, purity and safety. We have evolved
                as a digital consumer market for gold and silver investments.
              </Title>
              {/* <Title level={2}>Offer Valid Till </Title> */}
              {/* <Countdown date={Date.now() + 1000000000} renderer={renderer} /> */}
            </Col>
            <Col xs={24} sm={24} md={8} lg={4}>
              <img
                style={{ height: 200 }}
                src={require('./counter_img.jpg')}
                alt="offer-poster"
              />
            </Col>
          </Row>

          <Divider orientation="center">
            <Title level={1}>Exclusive</Title>
          </Divider>
          <Row justify="center" style={{ textAlign: 'center' }}>
            <Col xs={24} sm={24} md={12} lg={8}>
              <a href="/productview/gold">
                <img
                  style={{ width: '280px', height: 400, margin: '20px' }}
                  src={require('./gold_offer_poster.png')}
                  alt="offer-poster"
                />
              </a>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <a href="/productview/silver">
                {' '}
                <img
                  style={{ width: '280px', height: 400, margin: '20px' }}
                  src={require('./silver_offer_poster.png')}
                  alt="offer-poster"
                />
              </a>
            </Col>
            <Col xs={24} sm={24} md={12} lg={8}>
              <a href="/productview/other">
                {' '}
                <img
                  style={{ width: '280px', height: 400, margin: '20px' }}
                  src={require('./coin_offer_poster.png')}
                  alt="offer-poster"
                />
              </a>
            </Col>
          </Row>
          {this.state.webShopStatus ? (
            <>
              <Divider orientation="center">
                <Title level={1}>Popular Products</Title>
              </Divider>

              {this.state.loading ? (
                <div style={{ padding: '2%' }}>
                  <Row gutter={16}>
                    <Col xs={24} sm={24} lg={6} md={6}>
                      <Card>
                        <Skeleton active loading />
                      </Card>
                    </Col>

                    <Col xs={24} sm={24} lg={6} md={6}>
                      <Card>
                        <Skeleton active loading />
                      </Card>
                    </Col>

                    <Col xs={24} sm={24} lg={6} md={6}>
                      <Card>
                        <Skeleton active loading />
                      </Card>
                    </Col>

                    <Col xs={24} sm={24} lg={6} md={6}>
                      <Card>
                        <Skeleton active loading />
                      </Card>
                    </Col>
                  </Row>
                </div>
              ) : (
                <div className="homepage-trending">
                  <Row justify="center">
                    {this.state.trendingProduct.map((product) => (
                      <TrendingProduct
                        Img={product.imageURL1}
                        title={product.title}
                        product={product}
                        price={this.livePricegold(product)}
                      />
                    ))}
                  </Row>
                </div>
              )}
            </>
          ) : null}
          <div style={{ marginTop: '20px' }}></div>

          <Divider style={{ margin: '100px 0' }} orientation="center">
            <Title level={1}>Latest Blogs</Title>
          </Divider>
          <Row style={{ textAlign: 'center' }}>
            {this.state.blogData != null
              ? this.state.blogData.map((blog) => (
                  <Col style={{ margin: 'auto' }} span={6}>
                    <div
                      style={{
                        border: '1px solid #e9e9e9',
                        paddingBottom: '10px',
                      }}
                    >
                      <img
                        style={{ marginTop: '-70px' }}
                        width={300}
                        src={blog.featuredImg}
                        alt="blog"
                      />
                      <h4
                        style={{
                          fontSize: '20px',
                          fontWeight: '800',
                          marginTop: '10px',
                          padding: '20px 10px',
                        }}
                      >
                        {blog.title}
                      </h4>
                      {/* <div style={{ marginBottom: '25px', height: '60px', padding: '20px 10px', lineHeight: '20px', overflow: 'hidden' }} dangerouslySetInnerHTML={{ __html: `${blog.data}` }} /> */}
                      <h4
                        style={{
                          fontWeight: '800',
                          margin: '20px',
                          textAlign: 'left',
                        }}
                      >
                        Date: {moment(blog.createdAt).format('YYYY-MM-DD')}
                      </h4>
                      <a
                        style={{
                          margin: '20px 0',
                          background: '#000',
                          border: '#000',
                          color: '#fff',
                          fontWeight: '800',
                          padding: '10px',
                        }}
                        href={`/blog/${blog._id}`}
                      >
                        Read More
                      </a>
                    </div>
                  </Col>
                ))
              : null}
          </Row>

          <div style={{ marginTop: '20px' }}></div>

          <Divider style={{ marginTop: '20px' }} orientation="center">
            <Title level={1}>Client Review</Title>
          </Divider>

          <ClientReview />
        </div>
        <div style={{ marginTop: '20px' }}></div>
        <Row>
          <Col xs={0} sm={0} md={4} lg={2} className="counter-section">
            <img
              style={{ height: 400 }}
              src={require('./directgold_poster.jpg')}
              alt="offer-poster"
            />
          </Col>
          <Col xs={0} sm={0} md={16} lg={14}>
            <div className="about-section">
              <h1> About Us </h1>
              <p>
                We are DirectGold.store (or DirectGold.eu), affiliate of
                Scarpino bv, a Belgian Company situated in the Antwerp region.
                As an official Merchant of Gold and Silver Products we are
                typically specialized in the best Investment Gold and Silver
                products. Consider these products as a full (often better)
                alternative or a perfect complement for Shares, Obligations,
                Real Estate, Art, other value items or pure money on a Bank
                Account. We are in possession of an official License awarded by
                the Royal Mint of Belgium and consequently also a member of the
                Warranty Fund. This License is to be renewed and re-awarded on a
                yearly basis. Our License for 2021 has been obtained already. So
                one can say that we are controlled in a similar way as this is
                the case with a bank institution. We have agreements with
                suppliers such as UMICORE and have obtained their best
                conditions. Further we can work at low margins since our
                overhead is very limited. We possess our own transport company
                we can bring the goods in a personalized and secured way to your
                home or to a mutual agreed place of delivery (without having to
                call on a subcontractor for this delivery in Belgium, Holland or
                Grand Duchy of Luxembourg. We take care of the goods and all
                responsibility over your goods as from the time of the order and
                payment until acceptance and final delivery and physical
                handover of the goods (from hand to hand) Where after a delivery
                not will be signed. We are the only intermediary between
                producer end the customer.
                <br />
                Buying at Direct Gold (Scarpino bv) guarantees you for a SAFE
                and TRUSTABLE purchase at the best conditions.
                <br />
                <b>Ir Ludo Janssens</b>
                <br />
                <br />
                CEO Scarpino bv
              </p>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8}>
            <div className="about-video-section">
              <iframe
                height="300"
                style={{ width: '100%' }}
                src="https://www.youtube.com/embed/nAC38gjetxo"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                title="DirectGold Trailer"
              ></iframe>
            </div>
          </Col>
        </Row>

        <Footer />
        <div className="container">
          <Container>
            <Link
              href="#"
              className="target-link"
              tooltip="Jump to top"
              styles={{ backgroundColor: '#b0a16e', color: '#424242' }}
              icon="fas fa-arrow-up"
            />

            {/* <BackTop
              target={() => document.getElementsByClassName("target-link")}
            >
              <div className="backtop back-to-top"></div>
            </BackTop> */}

            <Button
              tooltip="Check prices"
              icon="fas fa-chart-bar"
              onClick={() => this.showDrawer()}
              styles={{ backgroundColor: '#b0a16e' }}
              // , top: "50px", right: "10px"
            />
          </Container>
          <PricesDrawer data={this.state} />
        </div>
      </>
    )
  }
}

export default HomePage

import React, { Component } from "react";
import {
  Col,
  Row,
  Button,
  Modal,
  Form,
  Input,
  Space,
  message,
  Menu,
  Dropdown,
  Drawer,
  Divider,
  Avatar,
  Typography,
  Badge,
  notification,
} from "antd";

import {
  UserOutlined,
  DownOutlined,
  MenuOutlined,
  ShoppingCartOutlined,
  HeartOutlined,
  CarOutlined,
  QuestionCircleOutlined,
  RiseOutlined,
  GiftOutlined,
} from "@ant-design/icons";
import logo from "../assets/images/Logo.png";
import "./style/Header.css";
import "antd/dist/antd.css";
import axios from "axios";
import { setCurrentUser } from "../redux/user/user.actions";
import { connect } from "react-redux";
import Cookies from "js-cookie";
import { withRouter } from "react-router";

const { Title } = Typography;
const { Search } = Input;

const onFinish = (values) => {
  console.log("Success:", values);
};
const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

class Header extends Component {
  constructor() {
    super();
    this.state = {
      visible_login: false,
      visible_register: false,
      loading: false,
      email: "",
      password: "",
      user: {},
      rEmail: "",
      rPassword: "",
      rPasswordConfirm: "",
      rNameFirst: "",
      rNameLast: "",
      rPhone: "",
      drawer_visible: false,
      visibleSearchDrawer: false,
      searchquery: "",
      showWishlistBadge: false,
      showCartBadge: false,
      forgotPassword: false,
      showForgotPasswordModal: false,
    };
  }

  async componentDidMount() {
    if (Cookies.get("wishlist") !== undefined) {
      if (JSON.parse(Cookies.get("wishlist")).length > 0) {
        this.setState({
          showWishlistBadge: true,
        });
      }
    } else {
      this.setState({
        showWishlistBadge: false,
      });
    }
    if (await this.props.currentUser) {
      await axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/cart/${this.props.currentUser.id}`
        )
        .then((res) => {
          if (res.data.data) {
            this.setState({
              showCartBadge: true,
            });
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }
  }

  loginModal = () => {
    this.setState({
      visible_login: true,
      visible_register: false,
    });
  };
  showForgotPassword = () => {
    this.setState({
      showForgotPasswordModal: true,
      visible_login: false,
      email: "",
    });
  };
  showRegisterModal = () => {
    this.setState({
      visible_register: true,
      visible_login: false,
    });
  };

  handleForgotPassword = (e) => {
    const { email } = this.state;
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/auth/forgot-password`, {
        email: email,
      })
      .then((res) => {
        const data = res.data;
        console.log("Return on request", data);
        this.setState({ showForgotPasswordModal: false });
        notification.open({
          message: `Email Sent to ${this.state.email}`,
          description: "Check e-mail to reset your account password.",
        });
      })
      .catch(function (error) {
        console.log("err from forgot password", error);
        message.warning(
          "Request Failed, Try again using regiestered email to recover password !!"
        );
      });
    this.forgotPasswordDisplay();
  };

  handleLogIn = (e) => {
    const { email, password } = this.state;
    const { setCurrentUser } = this.props;
    console.log("--x---", email);

    axios
      .post(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
        email: email,
        password: password,
      })
      .then((res) => {
        const user = res.data;
        const isLoggedIn = true;
        setCurrentUser(user.payload, isLoggedIn);
        localStorage.setItem("token", JSON.stringify(user.payload));
        // Cookies.set('user', user.payload, { expires: 7 }, { path: '/*' });
        this.setState({ user });
        if (res) {
          message.success("Logged In Successfully");
        }
        this.setState({ visible_login: false, email: "", password: "" });
      })
      .catch(function (error) {
        console.log(error);
        message.warning("Incorrect Username and Password");
      });
    this.forgotPasswordDisplay();
  };

  forgotPasswordDisplay = () => {
    this.setState({ forgotPassword: true });
  };
  logout = () => {
    const { setCurrentUser } = this.props;
    setCurrentUser(null, false);
    localStorage.removeItem("token");
  };

  handleRegistration = (e) => {
    if (this.state.rPassword == this.state.rPasswordConfirm) {
      const { rNameFirst, rNameLast, rEmail, rPhone, rPassword } = this.state;
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/auth/register`, {
          nameFirst: rNameFirst,
          nameLast: rNameLast,
          email: rEmail,
          password: rPassword,
          phone: rPhone,
        })
        .then((res) => {
          const user = res.data;
          const isLoggedIn = true;
          setCurrentUser(user.payload, isLoggedIn);
          Cookies.set("user", user.payload, { expires: 7 });
          this.setState({ user });
          if (res) {
            message.success("Registration Successful");
          }
          this.setState({
            visible_login: false,
            email: "",
            password: "",
            visible_register: false,
          });
        })
        .catch(function (res) {
          message.warning("User Already Exist");
        });
    } else {
      message.error("Password Don't Match");
    }
  };
  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible_login: false,
      visible_register: false,
      showForgotPasswordModal: false,
    });
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.handleLogIn();
    }
  };

  showDrawer = () => {
    this.setState({
      drawer_visible: true,
    });
  };

  onClose = () => {
    this.setState({
      drawer_visible: false,
    });
  };

  onCloseSearchDrawer = () => {
    this.setState({
      visibleSearchDrawer: false,
    });
  };

  showSearchDrawer = () => {
    this.setState({
      visibleSearchDrawer: true,
    });
  };

  updateSearchQuery = (event) => {
    this.setState({
      searchquery: event.target.value,
    });
  };

  updateSearchQueryDesktop = (value) => {
    this.setState({
      searchquery: value,
    });
  };

  searchProductDesktop = () => {
    this.props.history.push(`/productsearch/${this.state.searchquery}`);
  };

  searchProduct = (event) => {
    event.preventDefault();
    this.props.history.push(`/productsearch/${this.state.searchquery}`);
  };

  render() {
    const { loading } = this.state;
    const user = this.props.currentUser;
    const menu = (
      <Menu>
        <Menu.Item>
          <Button href="/account" type="text">
            Profile
          </Button>
        </Menu.Item>
        <Menu.Item>
          <Button href="/orders" type="text">
            My Orders
          </Button>
        </Menu.Item>
        <Menu.Item>
          <Button onClick={this.logout} type="text">
            Logout
          </Button>
        </Menu.Item>
      </Menu>
    );
    return (
      <>
        <div className="primary-header" style={{ background: "#000" }}>
          <Row align="middle" gutter={16}>
            <Col style={{ padding: "0 0" }} lg={3} md={0} xs={0} sm={0}>
              <div></div>
            </Col>
            <Col style={{ padding: "0 0" }} lg={6} md={0} xs={0} sm={0}>
              <div className="header-container">
                {this.props.isLoggedIn ? (
                  <>
                    <span className="header-span">
                      <CarOutlined style={{ margin: "6px" }} />
                      <Typography.Text strong={true}>
                        <a className="track-order" href="/orders">
                          Track your Order
                        </a>
                      </Typography.Text>
                    </span>
                  </>
                ) : null}
              </div>
            </Col>
            <Col
              style={{ textAlign: "center" }}
              className="gutter-row secondary-option"
              lg={6}
              md={0}
              xs={0}
              sm={0}
            >
              <div></div>
            </Col>
            <Col style={{ padding: "0 0" }} lg={6} md={0} xs={0} sm={0}>
              <div style={{ float: "right" }} className="header-container">
                {this.props.isLoggedIn ? (
                  <>
                    <span
                      style={{ marginRight: "120px" }}
                      className="header-span"
                    >
                      <QuestionCircleOutlined style={{ margin: "6px" }} />
                      <Typography.Text strong={true}>
                        <a
                          className="need-help"
                          href="/general-conditions#help&contact"
                        >
                          Need Help
                        </a>
                      </Typography.Text>
                    </span>
                  </>
                ) : null}
              </div>
            </Col>
            <Col style={{ padding: "0 0" }} lg={3} md={0} xs={0} sm={0}>
              <div></div>
            </Col>
          </Row>
        </div>

        <div
          className="primary-header"
          style={{
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 20,
            paddingBottom: 25,
            background: "#000",
          }}
        >
          <Row align="middle" gutter={16}>
            <Col
              style={{ padding: "0 0" }}
              className="gutter-row primary-header-mobile"
              span={3}
            >
              <Button
                type="text"
                className="primary-header-mobile"
                style={{ color: "#fff" }}
              >
                <MenuOutlined
                  className="primary-header-mobile"
                  onClick={this.showDrawer}
                />
              </Button>
            </Col>
            <Col style={{ padding: "0 0" }} md={3} lg={3} sm={0} xs={0}>
              <div></div>
            </Col>
            <Col style={{ padding: "0 0" }} lg={4} md={12} sm={14} xs={14}>
              <a href="/" style={{ textDecoration: "none" }}>
                <Button type="text">
                  <img
                    style={{ width: "150px", marginTop: -15 }}
                    src={logo}
                    alt="Direct Gold"
                  />
                </Button>
              </a>
            </Col>
            <Col
              style={{ textAlign: "center" }}
              className="gutter-row secondary-option"
              lg={8}
              md={0}
              sm={0}
              xs={0}
            >
              <Search
                placeholder="Search"
                onChange={(event) => this.updateSearchQuery(event)}
                onSearch={this.searchProductDesktop}
                style={{ width: "100%", borderRadius: "5px" }}
                bordered={true}
              />
            </Col>
            {this.props.isLoggedIn ? (
              <>
                <Col className="gutter-row" md={0} lg={1} sm={0} xs={0}>
                  <Button
                    href="/your-cart"
                    className="primary-header-button"
                    type="link"
                  >
                    <Badge dot={this.state.showCartBadge}>
                      <ShoppingCartOutlined
                        style={{ fontSize: "20px", color: "#fff" }}
                      />
                    </Badge>
                  </Button>
                </Col>
                <Col
                  className="gutter-row"
                  md={0}
                  lg={1}
                  sm={0}
                  xs={0}
                  style={{ marginRight: "1.7%" }}
                >
                  <Button
                    href="/wishlist"
                    className="primary-header-button"
                    type="link"
                  >
                    <Badge dot={this.state.showWishlistBadge}>
                      <HeartOutlined
                        style={{ fontSize: "20px", color: "#fff" }}
                      />
                    </Badge>
                  </Button>
                </Col>
              </>
            ) : (
              <Col
                className="gutter-row"
                md={0}
                lg={1}
                sm={0}
                xs={0}
                style={{ marginRight: "1.7%", marginLeft: "1.7%" }}
              >
                <Button
                  href="/wishlist"
                  className="primary-header-button"
                  type="link"
                >
                  <Badge dot={this.state.showWishlistBadge}>
                    <HeartOutlined
                      style={{ fontSize: "20px", color: "#fff" }}
                    />
                  </Badge>
                </Button>
              </Col>
            )}

            <Col className="gutter-row" lg={4} md={0} sm={0} xs={0}>
              {this.props.isLoggedIn ? (
                <Dropdown overlay={menu}>
                  <a
                    href="javascriptvoid()"
                    className="ant-dropdown-link user-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    Hi {user.nameFirst} <DownOutlined />
                  </a>
                </Dropdown>
              ) : (
                <Button
                  className="primary-header-button"
                  type="link"
                  onClick={this.loginModal}
                >
                  <Typography.Text strong>Login or Register</Typography.Text>
                </Button>
              )}
            </Col>

            <Col xs={0} sm={0} lg={3} md={0}>
              <div></div>
            </Col>
          </Row>
        </div>

        <div className="secondary-header-desktop secondary-header">
          <Row gutter={16}>
            <Col
              style={{ textAlign: "center", borderRight: "1px solid" }}
              className="gutter-row secondary-option"
              span={6}
            >
              <a href="/productview/gold">
                <Title className="header-select" level={5}>
                  Gold
                </Title>
              </a>
            </Col>
            <Col
              style={{ textAlign: "center" }}
              className="gutter-row secondary-option"
              span={6}
            >
              <a href="/productview/silver">
                <Title className="header-select" level={5}>
                  Silver
                </Title>
              </a>
            </Col>
            <Col
              style={{ textAlign: "center", borderLeft: "1px solid" }}
              className="gutter-row secondary-option"
              span={6}
            >
              <a href="/productview/mints">
                <Title className="header-select" level={5}>
                  Mint
                </Title>
              </a>
            </Col>
            <Col
              style={{ textAlign: "center", borderLeft: "1px solid" }}
              className="gutter-row secondary-option"
              span={6}
            >
              <a href="/productview/others">
                <Title className="header-select" level={5}>
                  Others
                </Title>
              </a>
            </Col>
          </Row>
        </div>

        <div
          className="secondary-header secondary-header-mobile"
          style={{ color: "#fff" }}
        >
          <Divider style={{ margin: "4px 0" }} />
          <Row gutter={16} justify="center" align="middle">
            <Col className="gutter-row secondary-option" span={6}>
              <a href="/productview/gold">
                <Avatar
                  shape="square"
                  size="large"
                  src={require("../assets/images/icons/gold.png")}
                />
                <br />
                <Title level={5}>Gold</Title>
              </a>
            </Col>
            <Col className="gutter-row secondary-option" span={6}>
              <a href="/productview/silver">
                <Avatar
                  shape="square"
                  size="large"
                  src={require("../assets/images/icons/silver.png")}
                />
                <br />
                <Title level={5}>Silver</Title>
              </a>
            </Col>
            <Col className="gutter-row secondary-option" span={6}>
              <a href="/productview/mints">
                <Avatar
                  shape="square"
                  size="large"
                  src={require("../assets/images/icons/coin.png")}
                />
                <br />
                <Title level={5}>Mint</Title>
              </a>
            </Col>

            <Col className="gutter-row secondary-option" span={6}>
              <button
                onClick={this.showSearchDrawer}
                style={{ border: "none", background: "none" }}
              >
                <Avatar
                  shape="square"
                  size="large"
                  src={require("../assets/images/icons/search.png")}
                />
                <br />
                <Title level={5}>Search</Title>
              </button>
            </Col>
          </Row>
        </div>
        <Drawer
          title={
            this.props.isLoggedIn ? (
              <Title level={4}>Hi {user.name}</Title>
            ) : (
              <React.Fragment>
                <UserOutlined />
                <Button type="text" onClick={this.loginModal}>
                  <Title level={4}>Log In/Register</Title>
                </Button>
              </React.Fragment>
            )
          }
          placement="left"
          closable={true}
          onClose={this.onClose}
          visible={this.state.drawer_visible}
        >
          <br />
          <ShoppingCartOutlined />{" "}
          <Button href="/cart" type="text">
            <Title level={5}>Cart</Title>
          </Button>
          <br />
          <HeartOutlined style={{ color: "#fff" }} />{" "}
          <Button href="/wishlist" type="text">
            <Title level={5}>Wishlist</Title>
          </Button>
          <Divider />
          <Button href="/about" type="text">
            <Title level={5}>About Us</Title>
          </Button>
          <br />
          <Button href="/faq" type="text">
            <Title level={5}>Contact Us</Title>
          </Button>
          <br />
        </Drawer>
        <div>
          <Drawer
            placement="left"
            closable={true}
            onClose={this.onCloseSearchDrawer}
            visible={this.state.visibleSearchDrawer}
            key="bottom"
            width={"100%"}
            style={{}}
            mask={false}
          >
            <div style={{ textAlign: "center" }}>
              <Form>
                <Form.Item>
                  <Input
                    onChange={this.updateSearchQuery}
                    value={this.state.searchquery}
                    type="text"
                    allowClear={true}
                    bordered={false}
                    style={{
                      borderBottom: "1px solid #1890ff",
                      width: "70%",
                      marginTop: "6%",
                    }}
                    placeholder="Search"
                  />
                </Form.Item>
                <Form.Item>
                  <Button onClick={this.searchProduct} type="text">
                    Search
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Drawer>
        </div>
        {/* Modal */}
        <Modal
          title="Log In"
          visible={this.state.visible_login}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          <div>
            <Form
              name="basic"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter your username"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  value={this.state.username}
                  onKeyPress={this.handleAnswerChanges}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password
                  value={this.state.password}
                  onKeyPress={this.handleKeyPress}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
              </Form.Item>
              <Form.Item className="text-center">
                <Space>
                  <div className="login-modal-button">
                    <Button
                      key="register"
                      type="primary"
                      loading={loading}
                      onClick={this.handleLogIn}
                      className="login-modal-button"
                    >
                      LogIn
                    </Button>

                    <Button
                      key="register"
                      type="primary"
                      loading={loading}
                      onClick={this.showRegisterModal}
                      className="login-modal-button"
                    >
                      Don't have an account?
                    </Button>
                  </div>
                </Space>
              </Form.Item>
              <Form.Item className="text-center">
                {this.state.forgotPassword ? (
                  <Button
                    key="register"
                    type="link"
                    style={{ color: "#000" }}
                    loading={loading}
                    onClick={this.showForgotPassword}
                    className="login-modal-button"
                  >
                    Forgot Password
                  </Button>
                ) : null}
              </Form.Item>
            </Form>
          </div>
        </Modal>

        <Modal
          title="Register"
          visible={this.state.visible_register}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="First Name"
              name="First Name"
              rules={[
                {
                  required: true,
                  message: "Please input your First Name!",
                },
              ]}
            >
              <Input
                placeholder="First Name"
                prefix={<UserOutlined className="site-form-item-icon" />}
                value={this.state.rNameFirst}
                onChange={(e) => this.setState({ rNameFirst: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="Last Name"
              rules={[
                {
                  required: true,
                  message: "Please input your Last Name!",
                },
              ]}
            >
              <Input
                placeholder="Last Name"
                prefix={<UserOutlined className="site-form-item-icon" />}
                value={this.state.rNameLast}
                onChange={(e) => this.setState({ rNameLast: e.target.value })}
              />
            </Form.Item>

            <Form.Item
              label="Email"
              name="Email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input
                placeholder="Enter your email"
                prefix={<UserOutlined className="site-form-item-icon" />}
                value={this.state.rEmail}
                onChange={(e) => this.setState({ rEmail: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="Phone"
              name="Phone"
              rules={[
                {
                  required: true,
                  message: "Please input your Phone Number!",
                },
              ]}
            >
              <Input
                placeholder="Enter your Phone Number"
                prefix={<UserOutlined className="site-form-item-icon" />}
                value={this.state.rPhone}
                onChange={(e) => this.setState({ rPhone: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              placeholder="Enter your Password"
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                value={this.state.rPassword}
                onChange={(e) => this.setState({ rPassword: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              placeholder="Enter your Password"
              label="Confirm Password"
              name="confirm password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                value={this.state.rPasswordConfirm}
                onChange={(e) =>
                  this.setState({ rPasswordConfirm: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item>
              <Space>
                <div className="login-modal-button">
                  <Button
                    key="register"
                    type="primary"
                    loading={loading}
                    onClick={this.handleRegistration}
                  >
                    Register
                  </Button>

                  <Button
                    key="login"
                    type="primary"
                    loading={loading}
                    onClick={this.loginModal}
                    className="login-modal-button"
                  >
                    Already registered?
                  </Button>
                </div>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Forgot Password"
          visible={this.state.showForgotPasswordModal}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Email"
              name="Email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input
                placeholder="Enter your email"
                prefix={<UserOutlined className="site-form-item-icon" />}
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            </Form.Item>
            <Form.Item className="text-center">
              <Space>
                <div className="login-modal-button">
                  <Button
                    key="register"
                    type="primary"
                    loading={loading}
                    className="login-button"
                    onClick={this.handleForgotPassword}
                  >
                    Update Password
                  </Button>
                </div>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user, isLoggedIn) =>
    dispatch(setCurrentUser(user, isLoggedIn)),
});

const mapStateToProps = ({ user }) => ({
  currentUser: user.currentUser,
  isLoggedIn: user.isLoggedIn,
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));

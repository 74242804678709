import React from "react";
import { Chart } from "react-google-charts";
import { Row, Col, Typography } from "antd";
import axios from "axios";
import "./style/Charts.css";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
class PriceChart extends React.Component {
  state = {
    goldpricedata: [],
    silverpricedata: [],
    metalprice: [],
    allgoldpricedata: [],
    currentgoldprice: "",
    currentpriceofsilver: "",
    chartdata: [],
    previousgoldprice: "",
    previouspriceofsilver: "",
    loading: true,
    goldPrice1: "",
    silverPrice1: "",
  };
  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/gold/all-gold`)
      .then((response) => {
        const goldPriceHistory = response.data.data.reverse();
        const goldPrice1 = goldPriceHistory[0].goldprice;
        const goldPrice2 = goldPriceHistory[1].goldprice;
        const goldPrice3 = goldPriceHistory[2].goldprice;
        const goldDate1 = goldPriceHistory[0].updatedAt;
        this.setState({
          goldPriceHistory,
          goldPrice1,
          goldPrice2,
          goldPrice3,
          goldDate1,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });

    //API TO GET SILVER PRICE HISTORY
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/silver/all-silver`)
      .then((response) => {
        const silverPriceHistory = response.data.data.reverse();
        const silverPrice1 = silverPriceHistory[0].silverprice;
        const silverPrice2 = silverPriceHistory[1].silverprice;
        const silverPrice3 = silverPriceHistory[2].silverprice;
        const silverDate1 = silverPriceHistory[0].updatedAt;

        this.setState({
          silverPriceHistory,
          silverPrice1,
          silverPrice2,
          silverPrice3,
          silverDate1,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
    //API for GOLD Price
    axios
      .get(`${process.env.REACT_APP_API_SPOT_GOLD_PRICE_URL}`)
      .then((response) => {
        if (response.status === 200) {
          const goldpricedata = response.data.dataset;
          const allgoldpricedata = goldpricedata.data;
          const currentgoldprice = goldpricedata.data[0][5];
          const previousgoldprice = goldpricedata.data[1][5];
          this.setState({
            goldpricedata,
            allgoldpricedata,
            currentgoldprice,
            previousgoldprice,
          });
          this.getChartdata();
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
    // API for Silver Price
    axios
      .get(`${process.env.REACT_APP_API_SPOT_SILVER_PRICE_URL}`)
      .then((response) => {
        const silverpricedata = response.data.dataset;
        const allsilverpricedata = silverpricedata.data;
        const currentpriceofsilver = silverpricedata.data[0][3];
        const previouspriceofsilver = silverpricedata.data[1][3];
        this.setState({
          silverpricedata,
          allsilverpricedata,
          currentpriceofsilver,
          previouspriceofsilver,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }
  getChartdata = () => {
    var chartdata = [[{ type: "string", label: "Day" }, "Gold", "Silver"]];
    var chartdataGold = [["Date", "Gold"]];
    var chartdataSilver = [["Date", "Silver"]];
    for (var i = 7; i >= 0; i--) {
      var date = this.state.allgoldpricedata[i][0];
      chartdata.push([
        date.substring(0),
        this.state.allgoldpricedata[i][5],
        this.state.allsilverpricedata[i][3],
      ]);
      chartdataGold.push([
        date.substring(8),
        this.state.allgoldpricedata[i][5],
      ]);
      chartdataSilver.push([
        date.substring(8),
        this.state.allsilverpricedata[i][3],
      ]);
    }
    this.setState({ chartdata, chartdataGold, chartdataSilver });
    this.setState({ loading: false });
  };

  render() {
    // if (this.state.loading)
    //     return (
    //         <div className="drawer align-middle text-center">
    //             <Loader className="align-middle" />
    //         </div>
    //     );
    return (
      <>
        <Row>
          <Col span={24}>
            <Typography.Title level={4}>Current Ask Price</Typography.Title>
            <Typography.Paragraph>
              Belgium Date{" "}
              <b>{this.state.goldpricedata.newest_available_date} </b>
              <b>Price in €(EUR)</b> Per Oz
            </Typography.Paragraph>
          </Col>
        </Row>
        <Row>
          <Col span={12} className="chart-price">
            <Typography.Title level={4}>Gold</Typography.Title>
          </Col>
          <Col span={12} className="live-price">
            <Typography.Title level={4}>
              {Math.round(this.state.goldPrice1)} €
              {this.state.currentgoldprice > this.state.previousgoldprice ? (
                <ArrowUpOutlined className="live-price-green" />
              ) : (
                  <ArrowDownOutlined className="live-price-red" />
                )}
            </Typography.Title>
          </Col>
        </Row>
        <Row>
          <Col span={12} className="chart-price">
            <Typography.Title level={4}>Silver</Typography.Title>
          </Col>
          <Col span={12} className=" live-price">
            <Typography.Title level={4}>
              {Math.round(this.state.silverPrice1)} €
              {this.state.currentpriceofsilver >
                this.state.previouspriceofsilver ? (
                  <ArrowUpOutlined className="live-price-green" />
                ) : (
                  <ArrowDownOutlined className="live-price-red" />
                )}
            </Typography.Title>
          </Col>
        </Row>
        <div className="App">
          {/* <Chart
          width={'400'}
          height={'500'}
          chartType="Line"
          loader={<div>Loading Chart</div>}
          data={this.state.chartdata}
          options={
            {
            chart: {
              title:
                'Average Temperatures and Daylight in Iceland Throughout the Year',
            },
            width: 400,
            height: 500,
            series: {
              // Gives each series an axis name that matches the Y-axis below.
              0: { axis: 'Gold' },
              1: { axis: 'Silver' },
            },
            axes: {
              // Adds labels to each axis; they don't have to match the axis names.
              y: {
                Gold: { label: 'Gold' },
                Silver: { label: 'Silver' },
              },
            },
          }
          }
          rootProps={{ 'data-testid': '4' }}
        /> */}
          <Chart
            width={"100%"}
            className="charts-gold-silver"
            height={"90%"}
            chartType="LineChart"
            loader={<div>Loading Chart</div>}
            data={this.state.chartdataGold}
            options={{
              title: "Prices of Gold",
              hAxis: {
                title: "Date",
              },
              vAxis: {
                title: "Price",
              },
              series: {
                0: { color: "#c0b48b" },
              },
              chart: {
                title: "Prices of Gold",
              },
            }}
            rootProps={{ "data-testid": "1" }}
          />
          <Chart
            width={"100%"}
            height={"90%"}
            chartType="LineChart"
            loader={<div>Loading Chart</div>}
            data={this.state.chartdataSilver}
            options={{
              title: "Prices of Silver",
              hAxis: {
                title: "Date",
              },
              vAxis: {
                title: "Price",
              },
              series: {
                0: { color: "#C0C0C0" },
              },
            }}
            rootProps={{ "data-testid": "1" }}
          />
        </div>
      </>
    );
  }
}

export default PriceChart;

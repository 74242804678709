import React, { Component } from "react";
import { Col, Row, Button, Modal, Input, message } from "antd";
import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";
import axios from "axios";
import "./style/Footer.css";
import "antd/dist/antd.css";

class Footer extends Component {
  constructor() {
    super();
    this.state = {
      discountModalVisible: false,
    };
  }

  componentDidMount() {
    var visitedToday = localStorage.getItem("visitedToday")
      ? localStorage.getItem("visitedToday")
      : null;
    var subscribed = localStorage.getItem("subscribed")
      ? localStorage.getItem("subscribed")
      : null;
    if (visitedToday === null) {
      var object_one = { timestamp: new Date().getDate() };
      localStorage.setItem("visitedToday", JSON.stringify(object_one));
      if (subscribed === null) {
        setTimeout(() => {
          this.discountModal(true);
        }, 60000);
      }
    } else {
      var object_two = JSON.parse(visitedToday);
      var dateString = object_two.timestamp;
      var now = new Date().getDate();
      if (subscribed === null) {
        if (dateString !== now) {
          setTimeout(() => {
            this.discountModal(true);
          }, 5000);
        }
        var object_three = { timestamp: new Date().getDate() };
        localStorage.setItem("visitedToday", JSON.stringify(object_three));
      } else {
        var object = { timestamp: new Date().getDate() };
        localStorage.setItem("visitedToday", JSON.stringify(object));
      }
    }
  }
  discountModal(discountModalVisible) {
    this.setState({ discountModalVisible });
  }
  submitNewsletter = () => {
    const email = this.state.email;
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/newsletter/register`, {
        email: email,
      })
      .then((res) => {
        this.setState({});
        if (res) {
          message.success("Submitted Successfully");
          localStorage.setItem("subscribed", "true");
        }
        window.location.reload(false);
      })
      .catch(function (error) {
        console.log(error);
        message.error("Error");
      });
  };

  render() {
    return (
      <>
        <div className="footer-background">
          <div className="container">
            <Row style={{ marginBottom: "50px" }}>
              <Col xs={24} sm={24} md={8} lg={6}>
                <ul className="footer-list">
                  <li className="footer-list-heading">
                    <h3>Direct Gold</h3>
                  </li>

                  <li className="footer-list-item">
                    Phone: <a href="/#"> +32 (0) 486 68 70 68</a>
                  </li>
                  <li className="footer-list-item">
                    Email: <a href="mailto:info@directgold.store">info@directgold.store</a>
                  </li>
                  {/* <li className="footer-list-item" >Address: <a href="#"> Box 565, Charlestown, Nevis, West Indies, Caribbean</a> </li> */}
                  <li className="footer-list-item">Follow Us</li>
                  <li className="footer-list-social">

                    <a target="_blank" href="https://www.facebook.com/Direct-Gold-101245528527663/">
                      <img style={{ width: '35px' }} src={require('./facebook_icon.png')} />

                    </a>
                    <a target="_blank" href="https://www.linkedin.com/company/direct-gold">
                      <img style={{ width: '35px' }} src={require('./linkdin_icon.png')} />
                    </a>
                    <a target="_blank" href="https://instagram.com/direct_gold?igshid=54frmbmsssq4">
                      <img style={{ width: '35px' }} src={require('./instagram_icon.png')} />
                    </a>
                  </li>
                </ul>
              </Col>
              <Col xs={24} sm={24} md={8} lg={6}>
                <ul className="footer-list">
                  <li className="footer-list-heading">
                    <h3>Help & Information</h3>
                  </li>
                  <li className="footer-list-item">
                    <a href="/partner">Partner With Us </a>
                  </li>
                  <li className="footer-list-item">
                    <a href="/returns-refunds">Returns & Refunds</a>
                  </li>
                  <li className="footer-list-item">
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                  <li className="footer-list-item">
                    <a href="/terms-conditions">Terms & Conditions</a>
                  </li>
                </ul>
              </Col>
              <Col xs={24} sm={24} md={8} lg={6}>
                <ul className="footer-list">
                  <li className="footer-list-heading">
                    <h3>About Us</h3>
                  </li>
                  <li className="footer-list-item">
                    <a href="/about">About Direct Gold</a>
                  </li>
                  <li className="footer-list-item">
                    <a href="/faq">FAQ's Page</a>
                  </li>
                  <li className="footer-list-item">
                    <a href="/general-conditions">General Conditions</a>
                  </li>

                </ul>
              </Col>
              <Col xs={24} sm={24} md={24} lg={6}>
                <ul className="footer-list">
                  <li className="footer-list-heading">
                    <h3>Newsletter</h3>
                  </li>
                  <li className="footer-list-item footer-list-newsletter">

                    Subscribe To Our Newsletter And Get Latest Newsletter Every Month.
                  </li>
                  <li className="footer-list-item footer-list-newsletter">
                    <Input
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      placeholder="Type Your Email"
                    />
                  </li>
                  <li className="footer-list-item footer-list-newsletter">
                    <Button onClick={this.submitNewsletter} size="large">
                      Subscribe
                    </Button>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <p style={{ margin: '15px 0' }} >Copyright © 2020 Direct Gold. All Rights Reserved.</p>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <img alt="Payment Methods" src={require("./payment.png")} />
              </Col>
            </Row>
          </div>
        </div>
        <Modal
          title=""
          centered
          footer={null}
          visible={this.state.discountModalVisible}
          onOk={() => this.discountModal(false)}
          onCancel={() => this.discountModal(false)}
          className="footer-modal-close"
          width={1000}
        >
          <Row>
            <Col
              xs={0}
              sm={0}
              lg={8}
              md={8}
              style={{ backgroundColor: "#f6f7f9" }}
            >
              <img
                className="newsletter-img"
                src={require("./newsletter.jpg")}
                alt="Newsletter"
              />
            </Col>
            <Col
              className="newsletter-section"
              xs={24}
              sm={24}
              lg={16}
              md={16}
              style={{ backgroundColor: "#f6f7f9" }}
            >
              <h1>Newsletter</h1>
              <h2>Exclusive Newsletter & Notification</h2>

              <p>Subscribe to the Direct Gold newsletter to receive timely market updates and additional information on product sales and offers.</p>
              <div style={{ textAlign: "center" }}>
                <Input
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  placeholder="Type Your Email"
                  style={{ width: "60%" }}
                />
                <Button
                  className="newsletter-button"
                  onClick={this.submitNewsletter}
                  size="large"
                >
                  Subscribe Today !
                </Button>
              </div>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

export default Footer;

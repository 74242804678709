import React from "react";
import { StarFilled, RightOutlined, LeftOutlined } from "@ant-design/icons";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './style/ClientReview.css'

class ClientReview extends React.Component {
  render() {
    const setting = {
      slidesToShow: 3,
      slidesToScroll: 1,
      speed: 2000,
      arrows: true,
      nextArrow: <RightOutlined />,
      prevArrow: <LeftOutlined />,
    };
    return (
      <Carousel
        autoplay
        {...setting}
        style={{ height: "400px" }}
        className="content"
      >
        <div>
          <div
            style={{ textAlign: "center" }}
            className="comments playfair-font px-3"
          >
            <h3>Ria F.</h3>
            <span>
              <StarFilled style={{ fontSize: "20px" }}></StarFilled>
              <StarFilled style={{ fontSize: "20px" }}></StarFilled>
              <StarFilled style={{ fontSize: "20px" }}></StarFilled>
              <StarFilled style={{ fontSize: "20px" }}></StarFilled>
              <StarFilled style={{ fontSize: "20px" }}></StarFilled>
            </span>
            <p className="pt-2">
              
                Great system. Perfect Service The Gold Coins that i bought with
                Directgold.eu were nicely packed and perfectly delivered as
                promised.
              
            </p>
          </div>
        </div>
        <div>
          <div
            style={{ textAlign: "center" }}
            className="comments playfair-font px-3"
          >
            <h3>Hugo S.</h3>
            <span>
              <StarFilled style={{ fontSize: "20px" }}></StarFilled>
              <StarFilled style={{ fontSize: "20px" }}></StarFilled>
              <StarFilled style={{ fontSize: "20px" }}></StarFilled>
              <StarFilled style={{ fontSize: "20px" }}></StarFilled>
            </span>
            <p className="pt-2">
              
                I am a Gold Investor for all my life. In the past I bought these
                goods at my bank. I never bought this kind of Product over the
                Internet. It works fine though. I informed me beforehand whom I
                was buying from. Just by giving a short phone call to
                DirectGold.eu. These people are trustable.
              
            </p>
          </div>
        </div>
        <div>
          <div
            style={{ textAlign: "center" }}
            className="comments playfair-font px-3"
          >
            <h3>Patrick S.</h3>
            <span>
              <StarFilled style={{ fontSize: "20px" }}></StarFilled>
              <StarFilled style={{ fontSize: "20px" }}></StarFilled>
              <StarFilled style={{ fontSize: "20px" }}></StarFilled>
              <StarFilled style={{ fontSize: "20px" }}></StarFilled>
              <StarFilled style={{ fontSize: "20px" }}></StarFilled>
            </span>
            <p className="pt-2">
              
                Never I bought my precious Metals at such good conditions
                (Margins on Gold Prices). For my future purchases I will again
                handle trough DirectGold.eu. Easy, fast and securely delivered
                at home.
              
            </p>
          </div>
        </div>
      </Carousel>
    );
  }
}

export default ClientReview;

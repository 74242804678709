import React, { Component } from "react";
import "./style/Footer.css";
import "antd/dist/antd.css";
import { Row, Col } from "antd";

import Footer from "./Footer";

class Return extends Component {
  render() {
    return (
      <>
        <Row>
          <Col span={2} className="counter-section">
            <img
              style={{ height: 400 }}
              src={require("../Component/Home/directgold_poster.jpg")}
              alt="offer-poster"
            />
          </Col>
          <Col span={20}>
            <div className="about-section">
              <h1> Returns and Refunds</h1>
              <h2>
                The Customer has the right of withdrawal for the
  goods that he purchased. So he could decide, within
  fourteen calendar days after the day of delivery, to
  renounce to his purchase and will return these at his
  expense back to Scarpino bv. “Scarpino bv” will
  make within thirty days a refund. The costs of
  returning the goods are at customer’s expense.
  “Scarpino bv” will request that the customer reports
  to us the right of withdrawal to
  “info@directgold.store”. The goods will be returned
  and refunded at the rate of the current value of that
  moment (rate at which “Scarpino bv” is able to resell
  it back to its provider). Value of Gold and Silver and
  other precious metals fluctuate continuously. Also
  there exist a “spread” between Purchase and Selling
  Price. This Spread will be applicable when returning
  the goods.

              </h2>
              <h2>The Customer must return the goods in full, and in
undamaged condition and in its original packaging,
with all documents supplied, and as such perfectly
resalable, also together with a clear “delivery note”
to be signed off.<br /><br /><br /><br /><br /> </h2>
            </div>
          </Col>
        </Row>
        <Footer />
      </>
    );
  }
}

export default Return;
